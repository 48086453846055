function ConvertUrl() {
    return function (string: string) {
        let url = string.split('/');

        for(let i in url){
            url[i] = Helpers.normalizeString(url[i]).replace(/ /g, '-');
        }

        return url.join('/');
    };
}

angular
    .module('app')
    .filter('convertUrl', ConvertUrl);
