class LandFormController {

    public static $inject: string[] = [
        '$rootScope',
		'$stateParams',
		"$scope",
    ];

    settings: any = window['settings'];

    properties: any;


	$rootScope: any;
	$stateParams: any;
	$scope: any;

	name = "ad";
	email = "asddas";
	telefone = "1154481";

    constructor($rootScope, $stateParams, $scope) {
		this.$rootScope = $rootScope;
		this.$stateParams = $stateParams;
		this.$scope = $scope;
    }

	onInit() {

	}
}

angular
    .module('app')
    .controller('LandFormController', LandFormController);
