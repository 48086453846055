
class PropertiesViewController {

    public static $inject: string[] = [
        'property',
        'propertiesService',
        '$state',
        '$filter',
        '$scope',
        'seoManager',
        '$location',
        'ngYoutubeEmbedService',
        '$interval',
        '$timeout',
        '$cookies',
        '$rootScope',
        '$document'
    ];

    settings: any = window['settings'];

    // Posição do Slide
    positionSlide = 0;
    positionSlideFirst: boolean;
    positionSlideLast: boolean;
    positionSlideFullScreen = 0;
    slide_positionFullScreen = {};
    slide_position = {};
    player: any;
    viewInfoFav: any;

    property: Properties;

    $state: any;
    $scope: any;
    seoManager: any;
    $rootScope: any;
    $document: any;
    $filter: any;
    $location: any;
    $interval: any;
    $timeout: any;
    $cookies: any;
    ngYoutubeEmbedService: any;
    propertiesService: any;

    //Events
    eventPopState: any;
    animationSlide: any;

    constructor(
        property,
        propertiesService,
        $state,
        $filter,
        $scope,
        seoManager,
        $location,
        ngYoutubeEmbedService,
        $interval,
        $timeout,
        $cookies,
        $rootScope,
        $document) {

        this.$state = $state;
        this.$scope = $scope;
        this.$filter = $filter;
        this.$location = $location;
        this.$interval = $interval;
        this.$timeout = $timeout;
        this.$cookies = $cookies;
        this.seoManager = seoManager;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.property = property;

        $rootScope.propertyID = this.property.id;
        $rootScope.propertyMessage = 'Olá, tenho interesse neste imóvel: ' + this.property.title + ' - Cód. ' + this.property.cod + '. Aguardo o contato. Obrigado.';
		$rootScope.propertyMessagePartner = 'Olá, tenho interesse neste imóvel: ' + this.property.title + ' - Cód. ' + this.property.cod + '. Sou um corretor da imobiliária ' + $rootScope.realestate.name + ' https://' + window.location.hostname + '/' + this.property.url;

        this.seoManager.setTitle(this.property.title + ' | ' + $rootScope.realestate.name);
        this.seoManager.setDescription(this.property.description);
        this.seoManager.setImage(this.property.images[0].url);
        this.seoManager.setUrl('https://' + window.location.hostname + '/' + this.property.url);

        /**
         * Update Url
         * @type {() => void}
         */
        let off = $scope.$on('$stateChangeStart', (e) => {
            e.preventDefault();
            off();
        });
        $location.path(this.property.url).replace();

        this.$rootScope.breadcrumbs = this.property.breadcrumb;

        let details = [];

        if (this.property.area_total) {
            details.push({
                name: 'Área Total',
                value: this.property.area_total + 'm2',
            })
        }

        if (this.property.area_useful) {
            details.push({
                name: 'Área Útil',
                value: this.property.area_useful + 'm2',
            })
        }

        if (this.property.townhouse_price) {
            details.push({
                name: 'Condominio',
                value: 'R$ ' + this.$filter('price')(this.property.townhouse_price),
            })
        }

        if (this.property.IPTU) {
            details.push({
                name: 'IPTU',
                value: 'R$ ' + this.$filter('price')(this.property.IPTU)
            });
        }

        if (this.property.financing) {
            details.push({
                name: 'Financia?',
                value: 'Sim'
            });
        }
        if (this.property.FGTS) {
            details.push({
                name: 'Aceita FGTS?',
                value: 'Sim'
            });
        }
        if (this.property.credit_card) {
            details.push({
                name: 'Aceita Carta de Crédito?',
                value: 'Sim'
            });
        }
        if (this.property.MCMV) {
            details.push({
                name: 'Minha Casa Minha Vida?',
                value: 'Sim'
            });
        }
        if (this.property.exchange) {
            details.push({
                name: 'Permuta',
                value: 'Sim'
            });
        }

        for (let i in this.property.composition) {
            details.push(this.property.composition[i]);
        }

        this.property.details = details;

        if(!this.property.videoYT){
            this.property.videoYT = undefined;
        }

        this.property.image_count = this.property.images.length;
        this.property.image_countYT = this.property.images.length + (this.property.videoYT ? 1 : 0);

        //Alerta para adicionar imóvel nos favoritos
        this.viewInfoFav = !$cookies.get('__viewInfoFavProp');

        this.$timeout(function() {
            $('.info-tooltip').fadeOut('show');
        }, 4000);

        // Registra $destroy
        $scope.$on('$destroy', this.$destroy);

    }

    $onInit() {
        this.keyToFullScreen();
        this.firstLastSlide();

        this.$scope.$watch(() => {
            return this.$rootScope.startedVideo;
        }, (newVal) => {
            if(!angular.isUndefined(newVal) || newVal !== ''){
                if(this.$rootScope.startedVideo) {
                    if (this.$location.hash() == 'video') {
                        console.log(this.playVideo());
                        this.playVideo();
                    } else {
                        this.$rootScope.startVideo = false;
                    }
                }
            }
        });

    }

    $destroy() {
    }

    /**
     * Compartilhar por Email
     * @returns {any}
     */
    sharedEmail() {
        this.$rootScope.target_email = this.property.id;
        this.$rootScope.type_email = 'property';
        this.$rootScope.shareEmailModal = true;
    }

	openPartners() {
    	if(this.$rootScope.realestate.id == 297 || this.$rootScope.realestate.id == 610 || this.$rootScope.realestate.id == 613 || this.$rootScope.realestate.id == 627 || this.$rootScope.realestate.id == 11) {
			window.open('https://api.whatsapp.com/send?phone=5511977477000&text='+this.$rootScope.propertyMessagePartner, '_blank');
		} else {
			const pass = prompt("Informe a senha para parceria");

			if (pass == "7") {
				window.open('https://api.whatsapp.com/send?phone=5511977477000&text='+this.$rootScope.propertyMessagePartner, '_blank');
			} else {
				alert("Senha incorreta");
			}	
		}
		
	}

    /**
     * Move Slide
     */
    rightImage() {

        this.firstLastSlide();

        if (this.positionSlide >= this.property.image_countYT - 1) {
            this.firstLastSlide();
            return;
        }

        this.pauseVideo();

        this.$interval.cancel(this.animationSlide);

        this.positionSlide++;

        this.firstLastSlide();

        let anim_start = (new Date()).getTime();
        let that = this;

        this.animationSlide = this.$interval(function () {
            let porc = ((new Date()).getTime() - anim_start) / 2;

            let positionSlideTrans = that.positionSlide * 100 - (100 - porc);

            that.slide_position = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };

            if (porc >= 100) {
                positionSlideTrans = that.positionSlide * 100;

                that.slide_position = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);

                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    }

    /**
     * Move Slide
     */
    leftImage() {

        this.$interval.cancel(this.animationSlide);

        this.positionSlide--;

        if (this.positionSlide < 0) {
            this.positionSlide = 0;
            this.firstLastSlide();
            return;
        }

        this.firstLastSlide();

        this.pauseVideo();

        let anim_start = (new Date()).getTime();
        let that = this;

        this.animationSlide = this.$interval(function () {
            let porc = ((new Date()).getTime() - anim_start);

            let positionSlideTrans = that.positionSlide * 100 + (100 - porc);
            console.log(positionSlideTrans);

            that.slide_position = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };

            if (porc >= 100) {
                positionSlideTrans = that.positionSlide * 100;

                that.slide_position = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);

                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    }

    /**
     *
     */
    firstLastSlide() {

        if (this.positionSlide == 0) {
            this.positionSlideFirst = true;
        }else{
            this.positionSlideFirst = false;
        }

        if (this.positionSlide >= this.property.image_countYT - 1) {
            this.positionSlideLast = true;
        }else{
            this.positionSlideLast = false;
        }
    }

    /**
     * Move Slide
     */
    rightImageFullScreen() {

        if (this.positionSlideFullScreen >= this.property.image_count - 1)
            return;

        this.pauseVideo();

        this.$interval.cancel(this.animationSlide);

        this.positionSlideFullScreen++;

        let anim_start = (new Date()).getTime();
        let that = this;

        this.animationSlide = this.$interval(function () {
            let porc = ((new Date()).getTime() - anim_start) / 2;

            let positionSlideTrans = that.positionSlideFullScreen * 100 - (100 - porc);

            that.slide_positionFullScreen = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };

            if (porc >= 100) {
                positionSlideTrans = that.positionSlideFullScreen * 100;

                that.slide_positionFullScreen = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);

                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    }

    /**
     * Move Slide
     */
    leftImageFullScreen() {

        this.$interval.cancel(this.animationSlide);

        this.positionSlideFullScreen--;

        if (this.positionSlideFullScreen < 0) {
            this.positionSlideFullScreen = 0;
            return;
        }

        this.pauseVideo();

        let anim_start = (new Date()).getTime();
        let that = this;

        this.animationSlide = this.$interval(function () {
            let porc = ((new Date()).getTime() - anim_start);

            let positionSlideTrans = that.positionSlideFullScreen * 100 + (100 - porc);
            console.log(positionSlideTrans);

            that.slide_positionFullScreen = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };

            if (porc >= 100) {
                positionSlideTrans = that.positionSlideFullScreen * 100;

                that.slide_positionFullScreen = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);

                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    }

    keyToFullScreen() {
        let that = this;

        let event = function (e) {

            if(that.$rootScope.slideshow) {
                if (e.which == 39) {
                    that.rightImageFullScreen();
                }

                if (e.which == 37) {
                    that.leftImageFullScreen();
                }

                if (e.which == 27) {
                    that.$rootScope.slideshow = false;
                }

                that.$scope.$apply();
            }
        };

        this.$scope.$on('$destroy', function (){
            angular.element(that.$document).off('keydown', event);
        });

        this.$document.on('keydown', event);
    }

    pauseVideo() {
        if(this.$rootScope.startVideo){

            // if(!this.player){
            //     this.player = this.ngYoutubeEmbedService.getPlayerById('myvideo');
            // }

            this.player.pauseVideo();
        }
    }

    playVideo(){

        if(!this.player){
            this.$rootScope.startVideo = true;

            this.player = new YT.Player('player', {
                videoId: this.property.videoYT,
                events: {
                    'onReady': function (event) {
                        console.log('start');
                        event.target.playVideo();
                    },
                    onError: function (event) {
                        console.log(event);
                    }
                }
            });
        }
    }

    viewInfoFavOk() {
        $('.info-tooltip').fadeOut('show');
        this.$cookies.put('__viewInfoFavProp', false);
    }
}

angular
    .module('app')
    .controller('PropertiesViewController', PropertiesViewController);

/**
 * Iniciar Video de Youtube
 */
function startYoutube() {
    let tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onYouTubeIframeAPIReady(){
    angular.element(document).ready(function() {
        // Chama Angularjs
        let $body = angular.element(document);
        let $rootScope: any = $body.scope().$root;
        $rootScope.$apply(function () {
            $rootScope.startedVideo = true;
        });
    });
}

startYoutube();
