function InterceptorRequest($q, $rootScope, $state, $location) {

    this.$inject = [
        '$q',
        '$rootScope',
        '$state',
        '$location'
    ];

    this.settings = window['settings'];

    this.request = function(config) {

        config.headers['Authorization'] = this.settings['TOKEN'];

        return config;
    };

    this.responseError = function (error) {

        if (error && error.data && error.data.code) {
            // $state.go('500', {}, {location: 'replace'})
        }

        if (error.status === 401 || error.status === 403) {
            //
        }

        // $.ajax({
        //     type: "POST",
        //     url: this.settings['API_LOGS'] + '/logs',
        //     contentType: "application/json",
        //     dataType: 'json',
        //     data: JSON.stringify({
        //         level: 400,
        //         target: 'realestate-' + this.settings['TOKEN'],
        //         message: 'HTTP error partners',
        //         channel: 'partners-website-http',
        //         context: {
        //             location: window.location.href,
        //             response: error.data,
        //             page: error.status,
        //             status: error.status,
        //             statusText: error.statusText,
        //             xhrStatus: error.xhrStatus,
        //             url: error.config.url,
        //             headers: error.config.headers,
        //             method: error.config.method,
        //             data_request: error.config.data,
        //         },
        //     })
        // });

        return $q.reject(error);
    };

    return this;
}

angular.module('app')
    .service('InterceptorRequest', InterceptorRequest);
