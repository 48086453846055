var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var module = angular.module("app", [
    'ui.router',
    'ngMeta',
    'ngSanitize',
    'ngMap',
    'ui.utils.masks',
    'ngYoutubeEmbed',
    'ngCookies',
    'cfp.loadingBar',
    'ngAnimate',
    'ngFileUpload',
    'isteven-omni-bar',
]);
angular.element(document).ready(function () {
    var settings = document.querySelector('#set');
    var home = document.querySelector('#set-home');
    window['settings'] = JSON.parse(settings.attributes['value']['value']);
    window['home'] = JSON.parse(atob(home.attributes['value']['value']));
    console.log(window['home']);
    angular.bootstrap(document, ["app"]);
});
var Config = /** @class */ (function () {
    function Config(ngMetaProvider, $httpProvider, $compileProvider) {
        this.settings = window['settings'];
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|whatsapp|messenger|tel):/);
        /**
         * MetaTags
         */
        // ngMetaProvider.useTitleSuffix(true);
        // ngMetaProvider.setDefaultTitle('Alto Pedroso Imóveis');
        // ngMetaProvider.setDefaultTag('author', 'John Smith');
        // ngMetaProvider.setDefaultTag('description', 'description');
        // ngMetaProvider.setDefaultTag('image', 'asdasd');
        // ngMetaProvider.setDefaultTag('url', 'asdasd');
        // ngMetaProvider.setDefaultTag('name', 'Alto Pedroso');
        $httpProvider.interceptors.push('InterceptorRequest');
    }
    Config.$inject = ['ngMetaProvider', '$httpProvider', "$compileProvider"];
    return Config;
}());
angular.module("app")
    .config(Config);
var Routes = /** @class */ (function () {
    function Routes($stateProvider, $urlProvider, $locationProvider) {
        this.routes = [
            {
                name: 'root',
                abstract: true,
                resolve: {
                // realestate: [
                //     'helpersService',
                //     function (helpersService) {
                //         return helpersService.realestate();
                //     }],
                // info: [
                //     'helpersService',
                //     function (helpersService) {
                //         return helpersService.info();
                //     }],
                },
                controller: 'RootController',
                data: {
                    meta: {
                        disableUpdate: true
                    }
                }
            },
            // index.php
            {
                name: 'root.index',
                url: '/index.php',
                template: '',
                redirectTo: 'root.home',
            },
            // Home
            {
                name: 'root.home',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/home.php',
                url: '/',
                controller: 'HomeController',
                controllerAs: 'vm',
                resolve: {
                    homeData: [
                        'pagesService',
                        function (pagesService) {
                            return pagesService.home();
                        }
                    ],
                }
            },
            {
                name: 'root.zona-leste',
                url: '/imoveis-na-zona-leste',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/zona-leste.php',
            },
            {
                name: 'root.home2',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/home.php',
                url: '/casas-sobrados-apartamentos',
                controller: 'HomeController',
                controllerAs: 'vm',
                data: {
                    finality: 'sale',
                    meta: {
                        'title': 'Imóveis em São Miguel Paulista, casas, sobrados, apartamentos e comércios. Página especializada no bairro.',
                        'image': 'themes/altopedroso/images/shared-image2.png',
                    }
                },
                resolve: {
                    homeData: [
                        'pagesService',
                        function (pagesService) {
                            return pagesService.home();
                        }
                    ],
                }
            },
            // PropertyView
            {
                name: 'root.propertyView',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-view.php',
                url: '/imoveis-{all:.+}-id-{cod:.+}',
                controller: 'PropertiesViewController',
                controllerAs: 'vm',
                resolve: {
                    property: [
                        'propertiesService',
                        '$stateParams',
                        function (propertiesService, $stateParams) {
                            return propertiesService.get($stateParams.cod);
                        }
                    ],
                }
            },
            {
                name: 'root.propertyViewOld',
                url: '/imovel/{all:.+}id-{cod:.+}',
                controller: function ($stateParams, $scope, $location, $injector) {
                    var state = $injector.get('$state');
                    state.go('root.propertyView', {
                        all: '/',
                        cod: $stateParams.cod
                    });
                    return $location.path();
                },
            },
            {
                name: 'root.propertyViewOld2',
                url: '/imovel/id-{cod:.+}',
                controller: function ($stateParams, $scope, $location, $injector) {
                    var state = $injector.get('$state');
                    state.go('root.propertyView', {
                        all: '/',
                        cod: $stateParams.cod
                    });
                    return $location.path();
                },
            },
            // Search
            {
                name: 'root.searchProperties',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
                url: '/{finality:venda|locacao}{all:.*}',
                controller: 'PropertiesSearchController',
                controllerAs: 'vm',
                data: {
                    finality: 'sale'
                },
                resolve: {
                    properties: [
                        'propertiesService',
                        '$stateParams',
                        function (propertiesService, $stateParams) {
                            var params = propertiesService.getParams($stateParams.finality, $stateParams.all);
                            return propertiesService.search(params);
                        }
                    ],
                }
            },
            // Casa
            {
                name: 'root.searchPropertiesCasa',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
                url: '/casas-em-sao-miguel-paulista',
                controller: 'PropertiesSearchController',
                controllerAs: 'vm',
                data: {
                    finality: 'sale',
                    title: 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP.',
                    description: 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP. Casas com Piscina, do Popular ao Alto Padrão, é na Alto Pedroso Imóveis. Acesse:',
                    meta: {
                        'title': 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP.',
                        'description': 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP. Casas com Piscina, do Popular ao Alto Padrão, é na Alto Pedroso Imóveis. Acesse:',
                        'keywords': 'Comprar,Casas,São Miguel Paulista,Sobrado em Condomínio para Venda no bairro Vila Nova Curuçá, 3 dorm, 1 suíte, 2 vagas, 90 m,Sobrado com 2 dormitórios à venda, 65 m² por R$ 230.000,00 - São Miguel Paulista - São Paulo/SP,Alto Pedroso,Propriedades,Imóveis'
                    }
                },
                resolve: {
                    properties: [
                        'propertiesService',
                        '$stateParams',
                        function (propertiesService, $stateParams) {
                            var params = propertiesService.getParams('venda', '/t-casa/l-sp,sao-paulo,sao-miguel-paulista/');
                            return propertiesService.search(params);
                        }
                    ],
                }
            },
            // Apartamento
            {
                name: 'root.searchPropertiesApartamento',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
                url: '/apartamentos-em-sao-miguel-paulista',
                controller: 'PropertiesSearchController',
                controllerAs: 'vm',
                data: {
                    finality: 'sale',
                    title: 'Apartamentos à venda em São Miguel Paulista, os melhores Apartamentos em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                    meta: {
                        'title': 'Apartamentos à venda em São Miguel Paulista, São Paulo - Alto Pedroso',
                        'description': 'Apartamentos à venda em São Miguel Paulista, os melhores Apartamentos em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                        'keywords': 'Comprar,Apartamentos,São Miguel Paulista,Apartamento para venda com 47 m², 2 quartos, 1 vaga,Apartamento 40mts a Venda Com 2 Dormitórios Com Vaga de Garagem,Alto Pedroso,Propriedades,Imóveis',
                    }
                },
                resolve: {
                    properties: [
                        'propertiesService',
                        '$stateParams',
                        function (propertiesService, $stateParams) {
                            var params = propertiesService.getParams('venda', '/t-apartamento/l-sp,sao-paulo,sao-miguel-paulista/');
                            return propertiesService.search(params);
                        }
                    ],
                }
            },
            // comercios
            {
                name: 'root.searchPropertiesComercio',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
                url: '/comercios-em-sao-miguel-paulista',
                controller: 'PropertiesSearchController',
                controllerAs: 'vm',
                data: {
                    finality: 'sale',
                    title: 'Comerciais,Loja de Shopping/Centro Comercial à venda em São Miguel Paulista, é na Alto Pedroso Imóveis, imobiliária especializada, use seu FGTS, financiamento rápido.',
                    meta: {
                        'title': 'Comércios à venda em São Miguel Paulista, os melhores Comércios em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                        'description': 'Comerciais,Loja de Shopping/Centro Comercial à venda em São Miguel Paulista, é na Alto Pedroso Imóveis, imobiliária especializada, use seu FGTS, financiamento rápido.',
                        'keywords': 'Temos 23 anúncios de loja de shopping/centro comercial em São Miguel Paulista , São Paulo na Alto Pedroso. Também temos outros tipos de imóveis que podem interessá-lo. Utilize nossos filtros de pesquisa e encontre o imóvel do seus sonhos!',
                    }
                },
                resolve: {
                    properties: [
                        'propertiesService',
                        '$stateParams',
                        function (propertiesService, $stateParams) {
                            var params = propertiesService.getParams('venda', '/t-comercial/l-sp,sao-paulo,sao-miguel-paulista/');
                            return propertiesService.search(params);
                        }
                    ],
                }
            },
            // sobrados
            {
                name: 'root.searchPropertiesSobrado',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
                url: '/sobrados-em-sao-miguel-paulista',
                controller: 'PropertiesSearchController',
                controllerAs: 'vm',
                data: {
                    finality: 'sale',
                    title: 'Sobrados à venda em São Miguel Paulista, os melhores Sobrados em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                    meta: {
                        'title': 'Sobrados à venda em São Miguel Paulista, São Paulo - Alto Pedroso',
                        'description': 'Sobrados à venda em São Miguel Paulista, os melhores Sobrados em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                        'keywords': 'Na Alto Pedroso temos 1300 sobrado : venda em São Miguel Paulista , São Paulo . Utilize nossos filtros de pesquisa e encontre os melhores imóveis de São Miguel Paulista!',
                    }
                },
                resolve: {
                    properties: [
                        'propertiesService',
                        '$stateParams',
                        function (propertiesService, $stateParams) {
                            var params = propertiesService.getParams('venda', '/t-sobrado/l-sp,sao-paulo,sao-miguel-paulista/');
                            return propertiesService.search(params);
                        }
                    ],
                }
            },
            // Search
            {
                name: 'root.searchAdvanced',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/advanced-search.php',
                url: '/avancado',
                controller: 'SearchAdvancedController',
                controllerAs: 'vm'
            },
            // Favorites
            {
                name: 'root.favorite',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/favorite.php',
                url: '/favoritos',
                controller: 'FavoriteController',
                controllerAs: 'vm',
                resolve: {
                    properties: [
                        'propertiesService',
                        '$cookies',
                        function (propertiesService, $cookies) {
                            var fv = $cookies.getObject('fav_' + this.settings['TOKEN']);
                            if (!fv) {
                                fv = [];
                            }
                            var params = {
                                id_in: fv
                            };
                            console.log(params);
                            return propertiesService.search(params);
                        }
                    ],
                }
            },
            // Financiamento
            {
                name: 'root.financing',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/financing.php',
                url: '/financiamentos',
                controller: function (seoManager, $rootScope) {
                    seoManager.setTitle('Financiamento | ' + $rootScope.realestate.name);
                    seoManager.setTitleH1('Financiamento');
                }
            },
            {
                name: 'root.partnerss',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/partnership.php',
                url: '/partnership-link-bulding-real-state-bank-big-data-infinity',
                controller: function (seoManager, $rootScope) {
                    seoManager.setTitle('Financiamento | ' + $rootScope.realestate.name);
                    seoManager.setTitleH1('Financiamento');
                }
            },
            // Neighborhood
            {
                name: 'root.neighborhoodPage',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/neighborhood-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g, ' ');
                },
                url: '/imoveis-{neighborhood:[a-zA-Z0-9-]+}',
                controller: 'NeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: $stateParams.neighborhood.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodApartamentosPage',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/apartamento-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g, ' ');
                },
                url: '/apartamentos-{neighborhood:[a-zA-Z0-9-]+}',
                controller: 'NeighborhoodApartamentosPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: $stateParams.neighborhood.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodCasasPage23',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/casa-singular-page.php?estate=sp&city=sao paulo&neighborhood=sao miguel paulista';
                },
                url: '/casa-a-venda-em-sao-miguel-paulista',
                controller: 'NeighborhoodCasasPageController2',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'sao miguel paulista',
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodCasasPage',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/casa-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g, ' ');
                },
                url: '/casas-{neighborhood:[a-zA-Z0-9-]+}',
                controller: 'NeighborhoodCasasPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: $stateParams.neighborhood.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodSobradosPage',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/sobrado-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g, ' ');
                },
                url: '/sobrados-{neighborhood:[a-zA-Z0-9-]+}',
                controller: 'NeighborhoodSobradosPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: $stateParams.neighborhood.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodJardimHelena',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/jardim-helena-page.php?estate=sp&city=sao paulo&neighborhood=' + 'jardim-helena'.replace(/-/g, ' ');
                },
                url: '/imobiliaria-jardim-helena',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'jardim-helena'.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodErmelinoMatarazzo',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/ermelino-matarazzo-page.php?estate=sp&city=sao paulo&neighborhood=' + 'ermelino-matarazzo'.replace(/-/g, ' ');
                },
                url: '/imobiliaria-ermelino-matarazzo',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'ermelino-matarazzo'.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodSaoMiguelPaulista',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/sao-miguel-paulista-page.php?estate=sp&city=sao paulo&neighborhood=' + 'sao-miguel-paulista'.replace(/-/g, ' ');
                },
                url: '/imobiliaria-sao-miguel-paulista',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'sao-miguel-paulista'.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodSaoMiguelPaulista2',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/sao-miguel-paulista-page.php?estate=sp&city=sao paulo&neighborhood=' + 'sao-miguel-paulista'.replace(/-/g, ' ');
                },
                url: '/imobiliaria-saomiguel',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'sao-miguel-paulista'.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodItaquera',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/itaquera-page.php?estate=sp&city=sao paulo&neighborhood=' + 'itaquera'.replace(/-/g, ' ');
                },
                url: '/imobiliaria-itaquera',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'itaquera'.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodVilaCuruca',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/vila-curuca-page.php?estate=sp&city=sao paulo&neighborhood=' + 'vila-curuca'.replace(/-/g, ' ');
                },
                url: '/imobiliaria-vila-curuca',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: 'vila-jacui'.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodPage3',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/realestate-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g, ' ');
                },
                url: '/imobiliaria-{neighborhood:[a-zA-Z0-9-]+}',
                controller: 'RealEstateNeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: $stateParams.neighborhood.replace(/-/g, ' '),
                                city: 'sao paulo',
                                estate: 'sp',
                            };
                            return [];
                        }
                    ],
                }
            },
            {
                name: 'root.neighborhoodPage2',
                templateUrl: function ($stateParams) {
                    return /*!*/ '/themes/' + window['settings']['THEME'] + '/neighborhood-page.php?estate=' + $stateParams.estate + '&city=' + $stateParams.city + '&neighborhood=' + $stateParams.neighborhood;
                },
                url: '/imoveis/{estate}/{city}/{neighborhood}',
                controller: 'NeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                neighborhood: $stateParams.neighborhood.replace(/-/g, ' '),
                                city: $stateParams.city.replace(/-/g, ' '),
                                estate: $stateParams.estate,
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            {
                name: 'root.cityPage',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/neighborhood-page.php',
                url: '/imoveis/{estate}/{city}',
                controller: 'NeighborhoodPageController',
                controllerAs: 'vm',
                resolve: {
                    data: [
                        'pagesService',
                        '$stateParams',
                        function (pagesService, $stateParams) {
                            var params = {
                                city: $stateParams.city.replace(/-/g, ' '),
                                estate: $stateParams.estate,
                            };
                            return pagesService.neighborhoodPage(params);
                        }
                    ],
                }
            },
            // Property Register
            {
                name: 'root.propertyRegister',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-register.php',
                url: '/{page:anunciarimovel|anunciarimoveis|anunciar-imovel|cadastrar-imovel}?step',
                reloadOnSearch: false,
                controller: 'PropertyRegisterController',
                controllerAs: 'vm',
            },
            // Property Register
            {
                name: 'root.propertyRequest',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-request.php',
                url: '/encomende-seu-imovel',
                reloadOnSearch: false,
                controller: 'PropertyRequestController',
                controllerAs: 'vm',
            },
            // Contact
            {
                name: 'root.contact',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/contact.php',
                url: '/{page:contato|faleconosco}',
                controller: 'ContactController',
                controllerAs: 'vm',
            },
            // Contact
            {
                name: 'root.imobiLeste',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/imobiliaria-na-zona-leste.php',
                url: '/imobiliaria-na-zona-leste',
                controller: 'ZonalesteController',
                controllerAs: 'vm',
            },
            // Contact
            {
                name: 'root.policy',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/policy.php',
                url: '/politica-de-privacidade',
                controller: function (seoManager, $rootScope) {
                    seoManager.setTitle('Política de Privacidade e Termos de Uso | ' + $rootScope.realestate.name);
                    seoManager.setTitleH1('Política de Privacidade e Termos de Uso');
                },
            },
            // SiteMap
            {
                name: 'root.sitemap',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/sitemap.php',
                url: '/mapa-do-site',
                controller: 'SiteMapController',
                controllerAs: 'vm',
                resolve: {
                    locationData: [
                        'pagesService',
                        function (pagesService) {
                            return pagesService.siteMap();
                        }
                    ],
                }
            },
            // Pages
            {
                name: 'root.pages',
                templateProvider: function ($templateFactory, $stateParams) {
                    return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/' + $stateParams.page + '.php');
                },
                url: '/page/{page}',
                controller: 'PageController',
                controllerAs: 'vm',
            },
            // Compartilhar Lista
            {
                name: 'root.listsShared',
                url: '/lista/share/{target}/{properties}',
                controller: function (list, $stateParams) {
                    switch ($stateParams.target) {
                        case 'facebook':
                            window.location.href = 'https://www.facebook.com/share.php?u=' + 'https://' + window.location.hostname + '/lista/' + list;
                            break;
                        case 'twitter':
                            window.location.href = 'https://twitter.com/intent/tweet?status=' + 'https://' + window.location.hostname + '/lista/' + list;
                            break;
                        case 'googleplus':
                            window.location.href = 'https://plus.google.com/share?url=' + 'https://' + window.location.hostname + '/lista/' + list;
                            break;
                        case 'whatsapp_mobile':
                            window.location.href = 'whatsapp://send?text=' + 'https://' + window.location.hostname + '/lista/' + list;
                            break;
                        case 'whatsapp':
                            window.location.href = 'https://api.whatsapp.com/send?text=' + 'https://' + window.location.hostname + '/lista/' + list;
                            break;
                        case 'messenger':
                            window.location.href = 'fb-messenger://share/?link=' + 'https://' + window.location.hostname + '/lista/' + list;
                            break;
                    }
                },
                resolve: {
                    list: [
                        'propertiesListsService',
                        '$stateParams',
                        function (propertiesListsService, $stateParams) {
                            // $stateParams.target
                            var properties = $stateParams.properties.split(',');
                            return propertiesListsService
                                .create(properties);
                        }
                    ],
                }
            },
            // Lista
            {
                name: 'root.listsGet',
                url: '/lista/{hash}',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/lists.php',
                controller: 'ListPageController',
                controllerAs: 'vm',
                resolve: {
                    list: [
                        'propertiesListsService',
                        '$stateParams',
                        function (propertiesListsService, $stateParams) {
                            return propertiesListsService.get($stateParams.hash);
                        }
                    ],
                }
            },
            // NotFound
            {
                parent: 'root',
                name: '404',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/404.php',
                controller: function (seoManager, $rootScope) {
                    seoManager.setTitle('Página não encontrada | ' + $rootScope.realestate.name);
                    seoManager.setTitleH1('Página não encontrada');
                }
            },
            // Vantagens
            {
                name: 'root.vantagens',
                templateProvider: function ($templateFactory, $stateParams) {
                    return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/vantagens.php');
                },
                url: '/vantagens',
                controller: 'PageController',
                controllerAs: 'vm',
            },
            // Vantagens
            {
                name: 'root.parcerias',
                templateProvider: function ($templateFactory, $stateParams) {
                    return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/parcerias.php');
                },
                url: '/parcerias',
                controller: 'PageController',
                controllerAs: 'vm',
            },
            // Vantagens
            {
                name: 'root.comofunciona',
                templateProvider: function ($templateFactory, $stateParams) {
                    return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/comofunciona.php');
                },
                url: '/comofunciona',
                controller: 'PageController',
                controllerAs: 'vm',
            },
            // Vantagens
            {
                name: 'root.seuimovelemvarioslugares',
                templateProvider: function ($templateFactory, $stateParams) {
                    return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/seuimovelemvarioslugares.php');
                },
                url: '/seuimovelemvarioslugares',
                controller: 'PageController',
                controllerAs: 'vm',
            },
            // NotFound
            {
                name: '500',
                templateUrl: /*!*/ '/error-500.php',
                controller: function (seoManager, $rootScope) {
                    seoManager.setTitle('Página não encontrada | ' + $rootScope.realestate.name);
                    seoManager.setTitleH1('Página não encontrada');
                }
            },
        ];
        if (window['settings']['realestate']['id'] == 319) {
            $stateProvider.state(this.routes[0]);
            $stateProvider.state({
                name: 'root.propertyRegister',
                templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-register.php',
                url: '/',
                reloadOnSearch: false,
                controller: 'PropertyRegisterController',
                controllerAs: 'vm',
            });
            $urlProvider.otherwise(function ($injector, $location) {
                var state = $injector.get('$state');
                state.go('root.propertyRegister');
                return $location.path();
            });
        }
        else {
            for (var i in this.routes) {
                $stateProvider.state(this.routes[i]);
            }
            $urlProvider.otherwise(function ($injector, $location) {
                var state = $injector.get('$state');
                state.go('404');
                return $location.path();
            });
        }
        // $urlProvider.otherwise('/');
        $locationProvider.html5Mode(true);
    }
    Routes.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];
    return Routes;
}());
angular.module("app").config(Routes);
var Run = /** @class */ (function () {
    function Run(ngMeta, $rootScope, $transitions, $injector, $location, cfpLoadingBar, seoManager) {
        ngMeta.init();
        // Variaveis Globais
        $rootScope.THEME = window['settings'].THEME;
        $rootScope.URL_THEME = window['settings'].URL_THEME;
        $rootScope.URL = window['settings'].URL;
        $rootScope.API = window['settings'].API;
        $rootScope.API_LOGS = window['settings'].API_LOGS;
        $rootScope.TOKEN = window['settings'].TOKEN;
        $rootScope.GOOGLE_MAPS_KEY = window['settings'].GOOGLE_MAPS_KEY;
        $rootScope.realestate = window['settings'].realestate;
        $rootScope.info = window['settings'].info;
        $rootScope.googleMapsUrl = 'https://maps.googleapis.com/maps/api/js?key=' + $rootScope.GOOGLE_MAPS_KEY + '&libraries=places';
        /**
         * transitions
         */
        $transitions.onSuccess({}, function (transition) {
            $rootScope.route_name = transition.to().name;
            // theme_test
        });
        $transitions.onStart({}, function (transition) {
            // $rootScope.loadingPage = true;
            cfpLoadingBar.start();
        });
        $transitions.onFinish({}, function (transition) {
            $rootScope.text_search = '';
            $('html,body').scrollTop(0);
            cfpLoadingBar.complete();
            $rootScope.localizacoes = [];
            $rootScope.openMenuSide = false;
            $rootScope.loadingPage = false;
            $rootScope.openContact = false;
            $rootScope.openSimiliarPropertiesSuccess = false;
            $rootScope.shareEmailModal = false;
            $rootScope.openModalFailed = {
                open: false
            };
        });
        $transitions.onError({}, function (transition) {
            $rootScope.loadingPage = false;
            cfpLoadingBar.complete();
            var rejection = transition.error();
            if (rejection.type !== 5 && rejection.type !== 2) { // === 6
                var state = $injector.get('$state');
                state.go('404');
                return $location.path();
            }
        });
    }
    Run.$inject = [
        'ngMeta',
        '$rootScope',
        '$transitions',
        '$injector',
        '$location',
        'cfpLoadingBar',
        'seoManager',
    ];
    return Run;
}());
angular.module("app")
    .run(Run);
var ContactController = /** @class */ (function () {
    function ContactController($rootScope, seoManager) {
        this.settings = window['settings'];
        seoManager.setTitle('Fale Conosco | ' + $rootScope.realestate.name);
        seoManager.setTitleH1('Fale Conosco');
        seoManager.setUrl('/contato', true);
    }
    ContactController.$inject = [
        '$rootScope',
        'seoManager',
    ];
    return ContactController;
}());
angular
    .module('app')
    .controller('ContactController', ContactController);
var FavoriteController = /** @class */ (function () {
    function FavoriteController(properties, $cookies, seoManager, propertiesListsService, $rootScope) {
        this.settings = window['settings'];
        this.selected = [];
        this.properties = properties.results;
        this.$cookies = $cookies;
        this.seoManager = seoManager;
        this.$rootScope = $rootScope;
        this.propertiesListsService = propertiesListsService;
        this.seoManager.setTitle('Meus favoritos | ' + $rootScope.realestate.name);
        this.seoManager.setTitleH1('Meus favoritos');
        seoManager.setUrl('/favoritos', true);
        this.deselectAll();
    }
    FavoriteController.prototype.sharedEmail = function () {
        var _this = this;
        this.$rootScope.loading = true;
        return this.propertiesListsService
            .create(this.list.split(','))
            .then(function (list) {
            _this.$rootScope.loading = false;
            _this.$rootScope.target_email = list;
            _this.$rootScope.type_email = 'properties_lists';
            _this.$rootScope.shareEmailModal = true;
        }, function () {
            _this.$rootScope.loading = false;
        });
    };
    FavoriteController.prototype.change = function (id, checked) {
        if (this.selected.indexOf(id) === -1) {
            this.selected.push(id);
        }
        else {
            this.selected.splice(this.selected.indexOf(id), 1);
        }
        this.list = this.selected.join(',');
        if (this.selected.length == 0) {
            var selected_t = [];
            for (var i in this.properties) {
                selected_t.push(this.properties[i].id);
            }
            this.list = selected_t.join(',');
        }
    };
    FavoriteController.prototype.selectAll = function () {
        this.selected = [];
        for (var i in this.properties) {
            this.selected.push(this.properties[i].id);
            this.properties[i].checked = true;
        }
        this.list = this.selected.join(',');
    };
    FavoriteController.prototype.deselectAll = function () {
        this.selected = [];
        var selected_t = [];
        for (var i in this.properties) {
            selected_t.push(this.properties[i].id);
            this.properties[i].checked = false;
        }
        this.list = selected_t.join(',');
    };
    FavoriteController.prototype.removeAll = function () {
        this.$cookies.putObject('fav_' + this.settings['TOKEN'], []);
        this.properties = [];
    };
    FavoriteController.$inject = [
        'properties',
        '$cookies',
        'seoManager',
        'propertiesListsService',
        '$rootScope',
    ];
    return FavoriteController;
}());
angular
    .module('app')
    .controller('FavoriteController', FavoriteController);
var GoogleMapController = /** @class */ (function () {
    function GoogleMapController($scope, $rootScope, $interval, $document) {
        this.settings = window['settings'];
        this.place = {};
        this.showMap = false;
        this.path = [];
        this.map = false;
        this.markers = [];
        this.argument_start = false;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$interval = $interval;
        this.$document = $document;
    }
    GoogleMapController.prototype.$onInit = function () {
        var that = this;
    };
    GoogleMapController.prototype.init = function (map) {
        this.map = map;
        this.SetMapAddress(this.location);
    };
    GoogleMapController.prototype.getLocation = function (address) {
        var geocoder = new google.maps.Geocoder();
        var that = this;
        if (geocoder) {
            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    that.currentLoc = results[0].geometry.location;
                    console.log("that.currentLoc", that.currentLoc);
                }
                that.location_finish = true;
                that.$scope.$apply();
            });
        }
    };
    GoogleMapController.prototype.SetMapAddress = function (address) {
        var geocoder = new google.maps.Geocoder();
        var that = this;
        if (geocoder) {
            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    that.currentLoc = results[0].geometry.location;
                    that.map.fitBounds(results[0].geometry.viewport);
                    that.fitBounds = results[0].geometry.viewport;
                    that.boundsPlace = new google.maps.LatLngBounds(results[0].geometry.viewport.getSouthWest(), results[0].geometry.viewport.getNorthEast());
                    that.center = [that.boundsPlace.getCenter().lat(), that.boundsPlace.getCenter().lng()];
                    that.centerL = that.boundsPlace.getCenter();
                    that.raioCircle = that.calcDistance(results[0].geometry.viewport.getSouthWest(), results[0].geometry.viewport.getNorthEast()) / 2.0 * 1000;
                    var lat0 = results[0].geometry.viewport.getNorthEast().lat();
                    var lng0 = results[0].geometry.viewport.getNorthEast().lng();
                    var lat1 = results[0].geometry.viewport.getSouthWest().lat();
                    var lng1 = results[0].geometry.viewport.getSouthWest().lng();
                    that.path = [
                        [lat0, lng0],
                        [lat0, lng1],
                        [lat1, lng1],
                        [lat1, lng0]
                    ];
                }
                if (!!that.argument_start) {
                    that.searchPlaces(that.argument_start);
                    that.argument_start = false;
                }
                that.location_finish = true;
                that.$scope.$apply();
            });
        }
    };
    GoogleMapController.prototype.searchPlaces = function (argument) {
        console.log("argument", argument);
        var that = this;
        if (!this.map) {
            this.showMap = true;
            this.argument_start = argument;
            return false;
        }
        console.log("this.center", this.center);
        var request = {
            location: this.centerL,
            radius: this.raioCircle,
            types: [
                argument
            ]
        };
        var img = '';
        if (argument == 'shopping_mall') {
            img = 'shop';
        }
        else if (argument == 'bakery') {
            img = 'padaria';
        }
        else if (argument == 'grocery_or_supermarket') {
            img = 'mercados';
        }
        else if (argument == 'pharmacy') {
            img = 'farmacia';
        }
        else if (argument == 'hospital') {
            img = 'hospital';
        }
        else if (argument == 'school') {
            img = 'escola';
        }
        else if (argument == 'gym') {
            img = 'academia';
        }
        else if (argument == 'bank') {
            img = 'banco';
        }
        else {
            return false;
        }
        if (!!this.place[argument]) {
            var _markers = [];
            for (var i in this.markers) {
                if (this.markers[i].type != argument)
                    _markers.push(this.markers[i]);
            }
            this.markers = _markers;
            this.place[argument] = false;
        }
        else {
            this.place[argument] = true;
            var service = new google.maps.places.PlacesService(this.map);
            service.nearbySearch(request, function (results, status) {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (var i = 0; i < results.length; i++) {
                        var place = results[i];
                        // return;
                        that.markers.push({
                            type: argument,
                            img: img,
                            location: place.vicinity,
                            name: place.name,
                            position: [place.geometry.location.lat(), place.geometry.location.lng()]
                        });
                        // this..push(new google.maps.Marker({
                        // 	position: results[i].geometry.location,
                        // 	map: map,
                        // 	icon: icons,
                        // 	title: 'Hello World!'
                        // }));
                    }
                    // 'shopping_mall',
                    // 	'bakery',
                    // 	'grocery_or_supermarket',
                    // 	'pharmacy',
                    // 	'hospital',
                    // 	'school',
                    // 	'gym',
                    // 	'bank'
                    that.$scope.$apply();
                }
            });
        }
        $('html,body').animate({
            scrollTop: $(".google-maps").offset().top
        }, 'slow');
    };
    //calculates distance between two points in km's
    GoogleMapController.prototype.calcDistance = function (p1, p2) {
        return Number((google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2));
    };
    GoogleMapController.$inject = [
        '$scope',
        '$rootScope',
        '$interval',
        '$document'
    ];
    return GoogleMapController;
}());
angular
    .module('app')
    .controller('GoogleMapController', GoogleMapController);
var HomeController = /** @class */ (function () {
    function HomeController(homeData, seoManager, $rootScope) {
        this.settings = window['settings'];
        this.featuredProperties = homeData.data.body.featured_properties.results;
        seoManager.setTitle($rootScope.realestate.settings.SEO_TITLE || $rootScope.realestate.name);
        seoManager.setUrl('/', true);
        if ($rootScope.realestate.id === 1) {
            seoManager.setImage('https://bancodosimoveisfiles.s3.amazonaws.com/shared/altopedroso.png');
        }
        else if ($rootScope.realestate.id === -1) {
            seoManager.setImage('https://bancodosimoveisfiles.s3.amazonaws.com/shared/bancodosimoveis.png');
        }
        else {
            seoManager.setImage($rootScope.realestate.website + '/themes/' + window['settings']['THEME'] + '/images/shared-image.jpg');
        }
        // Bairros
        this.neigborhood();
    }
    /**
     * Bairros da página inicial
     */
    HomeController.prototype.neigborhood = function () {
        this.neigborhoods = [
            {
                url: this.settings['URL'] + "/imoveis/sp/sao-paulo/tatuape",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-tatuape.jpg'
                ],
                title: "Imóveis no Tatuapé, <br> São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis-sao-miguel-paulista",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-sao-miguel-paulista.jpg'
                ],
                title: "Imóveis em São Miguel Paulista, São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis/sp/sao-paulo/ermelino-matarazzo",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-ermelino-matarazzo.jpg'
                ],
                title: "Imóveis em Ermelino Matarazzo, São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis/sp/sao-paulo/itaim-paulista",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-itaim-paulista.jpg'
                ],
                title: "Imóveis no Itaim Paulista, São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis-parque-cruzeiro-do-sul",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-parque-cruzeiro-do-sul.jpg'
                ],
                title: "Imóveis em São Miguel Paulista - Parque Cruzeiro, São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis-vila-curuca-velha",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-curuca-velha.jpg'
                ],
                title: "Imóveis em São Miguel Paulista - Curuçá Velha, São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis-penha",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-penha.jpg'
                ],
                title: "Imóveis na Penha,<br>São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis-vila-jacui",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-vila-jacui.jpg'
                ],
                title: "Imóveis em São Miguel Paulista - Vila Jacui, São Paulo - SP"
            },
        ];
    };
    HomeController.$inject = [
        'homeData',
        'seoManager',
        '$rootScope',
    ];
    return HomeController;
}());
angular
    .module('app')
    .controller('HomeController', HomeController);
(function () {
    angular.module("app")
        .controller('LGPDController', [
        '$scope',
        '$rootScope',
        '$cookies',
        function ($scope, $rootScope, $cookies) {
            var self = this;
            self.view = true;
            console.log($cookies.get('LGDP'), $cookies.get('LGDP') == "1");
            if ($cookies.get('LGDP') == "1") {
                self.view = false;
            }
            var expiresValue = new Date();
            expiresValue.setFullYear(expiresValue.getFullYear() + 2);
            self.accept = function () {
                $cookies.putObject('LGDP', 1, { 'expires': expiresValue });
                self.view = false;
            };
        }
    ]);
})();
var LandFormController = /** @class */ (function () {
    function LandFormController($rootScope, $stateParams, $scope) {
        this.settings = window['settings'];
        this.name = "ad";
        this.email = "asddas";
        this.telefone = "1154481";
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
    }
    LandFormController.prototype.onInit = function () {
    };
    LandFormController.$inject = [
        '$rootScope',
        '$stateParams',
        "$scope",
    ];
    return LandFormController;
}());
angular
    .module('app')
    .controller('LandFormController', LandFormController);
var ListPageController = /** @class */ (function () {
    function ListPageController(list, seoManager, $rootScope, $stateParams) {
        this.settings = window['settings'];
        this.properties = list.properties.results;
        this.hash = $stateParams.hash;
        seoManager.setTitle('Lista de Imóveis | ' + $rootScope.realestate.name);
        seoManager.setUrl('/lista/' + $stateParams.hash, true);
        seoManager.setTitleH1('Lista de Imóveis');
    }
    ListPageController.$inject = [
        'list',
        'seoManager',
        '$rootScope',
        '$stateParams',
    ];
    return ListPageController;
}());
angular
    .module('app')
    .controller('ListPageController', ListPageController);
var NeighborhoodApartamentosPageController = /** @class */ (function () {
    function NeighborhoodApartamentosPageController(data, $filter, $rootScope, seoManager, $stateParams) {
        this.settings = window['settings'];
        this.$rootScope = $rootScope;
        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);
        var description = '';
        if (this.pageCity) {
            this.title = 'Apartamentos ' + this.location.city + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
            this.$rootScope.title_page = 'Apartamentos ' + this.location.city;
            description = "Procurando apartamentos " + this.location.city + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
        }
        else {
            this.title = 'Apartamentos ' + this.location.neighborhood + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
            this.$rootScope.title_page = 'Apartamentos ' + this.location.neighborhood;
            description = "Procurando apartamentos " + this.location.neighborhood + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
        }
        var url = $rootScope.URL + '/' + data.url;
        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    NeighborhoodApartamentosPageController.prototype.sharedEmail = function () {
        var location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);
        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    };
    NeighborhoodApartamentosPageController.$inject = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];
    return NeighborhoodApartamentosPageController;
}());
angular
    .module('app')
    .controller('NeighborhoodApartamentosPageController', NeighborhoodApartamentosPageController);
var NeighborhoodCasasPageController = /** @class */ (function () {
    function NeighborhoodCasasPageController(data, $filter, $rootScope, seoManager, $stateParams) {
        this.settings = window['settings'];
        this.$rootScope = $rootScope;
        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);
        var description = '';
        if (this.pageCity) {
            this.title = 'Casas ' + this.location.city + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
            this.$rootScope.title_page = 'Casas ' + this.location.city;
            description = "Procurando casas " + this.location.city + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
        }
        else {
            this.title = 'Casas ' + this.location.neighborhood + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
            this.$rootScope.title_page = 'Casas ' + this.location.neighborhood;
            description = "Procurando casas " + this.location.neighborhood + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
        }
        var url = $rootScope.URL + '/' + data.url;
        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    NeighborhoodCasasPageController.prototype.sharedEmail = function () {
        var location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);
        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    };
    NeighborhoodCasasPageController.$inject = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];
    return NeighborhoodCasasPageController;
}());
angular
    .module('app')
    .controller('NeighborhoodCasasPageController', NeighborhoodCasasPageController);
var NeighborhoodCasasPageController2 = /** @class */ (function () {
    function NeighborhoodCasasPageController2(data, $filter, $rootScope, seoManager, $stateParams) {
        this.settings = window['settings'];
        this.$rootScope = $rootScope;
        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);
        var description = '';
        this.title = 'Casa a Venda em São Miguel Paulista. ZL, São Paulo - SP, Alto Pedroso.';
        this.$rootScope.title_page = 'Casa ' + this.location.city;
        description = "Casa a venda em São Miguel Paulista. ZL, São Paulo - SP, compre da imobiliária melhor avaliada em São Miguel Paulista e da Zona Leste, SP. Alto Pedroso.";
        var url = $rootScope.URL + '/' + data.url;
        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    NeighborhoodCasasPageController2.prototype.sharedEmail = function () {
        var location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);
        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    };
    NeighborhoodCasasPageController2.$inject = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];
    return NeighborhoodCasasPageController2;
}());
angular
    .module('app')
    .controller('NeighborhoodCasasPageController2', NeighborhoodCasasPageController2);
var NeighborhoodPageController = /** @class */ (function () {
    function NeighborhoodPageController(data, $filter, $rootScope, seoManager, $stateParams) {
        this.settings = window['settings'];
        this.isCustom = false;
        this.$rootScope = $rootScope;
        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);
        var description = '';
        if (this.pageCity) {
            this.title = 'Imóveis ' + this.location.city + ', Apartamentos, Casas e Sobrados.';
            this.$rootScope.title_page = 'Imóveis ' + this.location.city;
            description = "Imóveis " + this.location.city + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.city + ".";
        }
        else {
            this.title = 'Imóveis ' + this.location.neighborhood + ', Apartamentos, Casas e Sobrados.';
            this.$rootScope.title_page = 'Imóveis ' + this.location.neighborhood;
            description = "Imóveis " + this.location.neighborhood + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.neighborhood + ".";
        }
        if (this.location.neighborhood === 'Itaim Paulista') {
            this.title = 'Imobiliária no Itaim Paulista, ZL, SP, Imóveis em Toda Zona Leste de SP';
            description = 'Imobiliária no Itaim Paulista | Alto Pedroso, ZL, SP, Contamos com Milhares de Imóveis em Toda Zona Leste Apartamentos, Casas, Sobrados. Use o seu FGTS.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Guaianases') {
            this.title = 'Imobiliária em Guaianases, Lista de Imóveis em Guaianases.';
            description = 'Imobiliária em Guaianases - Guia de Imóveis em Guaianases, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis?';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Vila Jacuí') {
            this.title = 'Imobiliária na Vila Jacuí, Lista de Imóveis na Vila Jacuí.';
            description = 'Imobiliária na Vila Jacuí - Guia de Imóveis na Vila Jacuí, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis?';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Parque Paulistano ') {
            this.title = 'Imóveis no Parque Paulistano, São Miguel Paulista ZL - Alto Pedroso';
            description = 'Procurando Imóveis no Parque Paulistano, São Miguel Paulista ZL? Vem para a Alto Pedroso Imóveis, empresa melhor avaliada do bairro, sede própria.·.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Ponte Rasa') {
            this.title = 'Imobiliária na Ponte Rasa, São Paulo, Zona Leste, SP - Alto Pedroso.·.';
            description = 'Imobiliária na Ponte Rasa, São Paulo, Zona Leste, SP - Apartamentos, Casas, Sobrados e Imóveis Comerciais. Alto Pedroso Imóveis, Especialista no Bairro.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Ermelino Matarazzo') {
            this.title = 'Imobiliária em Ermelino Matarazzo Guia Imóveis em Ermelino Matarazzo.';
            description = 'Imobiliária em Ermelino Matarazzo - Lista de Imóveis em Ermelino Matarazzo, ofertas de imóveis de corretores e Imobiliárias em Ermelino Matarazzo - SP';
            this.isCustom = true;
        }
        this.description = description;
        var url = $rootScope.URL + '/' + data.url;
        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    NeighborhoodPageController.prototype.sharedEmail = function () {
        var location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);
        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    };
    NeighborhoodPageController.$inject = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];
    return NeighborhoodPageController;
}());
angular
    .module('app')
    .controller('NeighborhoodPageController', NeighborhoodPageController);
var NeighborhoodSobradosPageController = /** @class */ (function () {
    function NeighborhoodSobradosPageController(data, $filter, $rootScope, seoManager, $stateParams) {
        this.settings = window['settings'];
        this.$rootScope = $rootScope;
        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);
        var description = '';
        if (this.pageCity) {
            this.title = 'Sobrados ' + this.location.city + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
            this.$rootScope.title_page = 'Sobrados ' + this.location.city;
            description = "Procurando sobrados " + this.location.city + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
        }
        else {
            this.title = 'Sobrados ' + this.location.neighborhood + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
            this.$rootScope.title_page = 'Sobrados ' + this.location.neighborhood;
            description = "Procurando sobrados " + this.location.neighborhood + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
        }
        var url = $rootScope.URL + '/' + data.url;
        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    NeighborhoodSobradosPageController.prototype.sharedEmail = function () {
        var location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);
        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    };
    NeighborhoodSobradosPageController.$inject = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];
    return NeighborhoodSobradosPageController;
}());
angular
    .module('app')
    .controller('NeighborhoodSobradosPageController', NeighborhoodSobradosPageController);
var PageController = /** @class */ (function () {
    function PageController(seoManager, $rootScope, $stateParams, $anchorScroll) {
        this.settings = window['settings'];
        this.seoManager = seoManager;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$anchorScroll = $anchorScroll;
    }
    PageController.prototype.init = function () {
        var _this = this;
        this.seoManager.setTitle(this.title + ' | ' + this.$rootScope.realestate.name);
        this.seoManager.setTitleH1(this.title_h1);
        this.seoManager.setUrl('/page/' + this.$stateParams.page, true);
        this.$anchorScroll();
        setTimeout(function () {
            _this.$anchorScroll();
        }, 2000);
    };
    PageController.$inject = [
        'seoManager',
        '$rootScope',
        '$stateParams',
        '$anchorScroll',
    ];
    return PageController;
}());
angular
    .module('app')
    .controller('PageController', PageController);
var PropertiesSearchController = /** @class */ (function () {
    function PropertiesSearchController(properties, propertiesService, $state, $scope, $rootScope, $document, $window, seoManager) {
        this.settings = window['settings'];
        this.openFilters = false;
        this.finality = {};
        this.filters = {};
        this.last_filters = {};
        this.loading = false;
        this.result_count = 0;
        this.maxSize = 10;
        this.numPages = 10;
        this.pageDisabled = false;
        this.mobile = false;
        this.error = false;
        this.correct_url = '';
        this.subtypes = [];
        this.types = [];
        this.title_search = '';
        //
        this.currentPage = 1;
        this.limit = 20;
        this.sort = '';
        this.offset = 0;
        this.description = '';
        this.description_seo = '';
        this.title_seo = '';
        this.sortPrice = '';
        if ($state.current.data.description) {
            this.description = $state.current.data.description;
            this.description_seo = $state.current.data.description;
        }
        if ($state.current.data.title) {
            this.title_seo = $state.current.data.title;
        }
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.$window = $window;
        this.seoManager = seoManager;
        this.propertiesService = propertiesService;
        this.properties = this.processProperties(properties.results);
        this.title_search = properties.title_search;
        this.result_count = properties.count;
        this.finality = $state.current.data.finality;
        this.$rootScope.localizacoes = properties.locations;
        this.getParams();
        seoManager.setTitle(this.title_search + ' | ' + $rootScope.realestate.name);
        seoManager.setUrl(this.generateURL());
        seoManager.setTitleH1(properties.title_search);
    }
    PropertiesSearchController.prototype.$onInit = function () {
        var _this = this;
        this.correct_url = this.generateURL();
        var that = this;
        this.eventPopState = function () {
            that.lock();
            that.$scope.$apply();
        };
        this.eventMobileCheck = function () {
            if ($(window).width() > 1000) {
                that.mobile = false;
            }
            else {
                that.mobile = true;
            }
            console.log(that.mobile);
            that.$scope.$apply();
        };
        // Evento de modificação de URL
        window.addEventListener('popstate', this.eventPopState, true);
        window.addEventListener('resize', this.eventMobileCheck, true);
        /**
         * Se não for mobile, mover scroll
         */
        $('html,body').animate({
            scrollTop: $(".properties-search").offset().top
        }, 'slow');
        if ($(window).width() > 1000) {
            that.mobile = false;
        }
        else {
            that.mobile = true;
        }
        //Localizações
        this.$scope.$watch(function () { return that.$rootScope.localizacoes.length; }, function (newvalue, oldvalue) {
            if (newvalue != oldvalue) {
                var location = [];
                for (var i in that.$rootScope.localizacoes) {
                    if (that.$rootScope.localizacoes[i].neighborhood) {
                        location.push(that.$rootScope.localizacoes[i].estate + ',' + that.$rootScope.localizacoes[i].city + ',' + that.$rootScope.localizacoes[i].neighborhood);
                    }
                    if (!that.$rootScope.localizacoes[i].neighborhood) {
                        location.push(that.$rootScope.localizacoes[i].estate + ',' + that.$rootScope.localizacoes[i].city);
                    }
                }
                that.filters.location = Helpers.convertStringUrl(location.join('_'));
                that.reset();
                that.loadProperties();
            }
        });
        this.$scope.$on("$destroy", function () {
            _this.destroy();
        });
    };
    PropertiesSearchController.prototype.destroy = function () {
        var that = this;
        // Evento de modificação de URL
        window.removeEventListener('popstate', this.eventPopState, true);
        window.removeEventListener('resize', this.eventMobileCheck, true);
    };
    /**
     * Resgata parametros da URL
     */
    PropertiesSearchController.prototype.getParams = function () {
        if (window.location.pathname) {
            this.filters = {};
            var explode = window.location.pathname.split("/");
            if (window.location.pathname === '/casas-em-sao-miguel-paulista') {
                explode = '/t-casa/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }
            if (window.location.pathname === '/apartamentos-em-sao-miguel-paulista') {
                explode = '/t-apartamento/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }
            if (window.location.pathname === '/comercios-em-sao-miguel-paulista') {
                explode = '/t-comercial/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }
            if (window.location.pathname === '/sobrados-em-sao-miguel-paulista') {
                explode = '/t-sobrado/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }
            // Reseta Ordem
            this.sort = '';
            // /venda
            if (explode[1] == 'venda') {
                this.finality = 'sale';
                this.filters["finality"] = 'sale';
            }
            else {
                if (!this.finality || explode[1] == 'locacao') {
                    this.finality = 'rent';
                    this.filters["finality"] = 'rent';
                }
            }
            for (var i in explode) {
                var data = explode[i];
                if (data.indexOf("l-") !== -1) {
                    //LIMPA l- da variavel
                    var array = data.split("-");
                    array.splice(0, 1);
                    var locations = array.join("-").split("_");
                    var location_correct = [];
                    for (var x in locations) {
                        location_correct.push(locations[x].replace(/-/g, ' '));
                    }
                    this.filters["location"] = location_correct.join('_');
                    //Corrigir localizacoes
                }
                else if (data.indexOf("t-") !== -1) {
                    var array = data.split("-");
                    array.splice(0, 1);
                    var element = array.join("-").split(',');
                    this.filters["type_name"] = [];
                    for (var xx in element) {
                        this.filters["type_name"].push(element[xx]);
                        // AltoPedroso e BDI
                        this[element[xx].toLocaleLowerCase()] = true;
                        this.types.push(element[xx].toLocaleLowerCase());
                    }
                    this.filters["type_name"] = this.filters["type_name"].join(',');
                }
                else if (data.indexOf("sub-") !== -1) {
                    this.filters["subtype"] = data.split("-")[1];
                    // AltoPedroso e BDI
                    this.subtypes = this.filters["subtype"].split(',').map(function (s) { return Number(s); });
                    for (var xx_1 in this.subtypes) {
                        this['subtype' + this.subtypes[xx_1]] = true;
                    }
                }
                else if (data.indexOf("dorms-") !== -1) {
                    this.filters["bedrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("suites-") !== -1) {
                    this.filters["suites"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("vagas-") !== -1) {
                    this.filters["vacancies"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("banheiros-") !== -1) {
                    this.filters["bathrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("area-min-") !== -1) {
                    this.filters["area_gte"] = parseInt(data.split("-")[2]);
                }
                else if (data.indexOf("area-max-") !== -1) {
                    this.filters["area_lte"] = parseInt(data.split("-")[2]);
                }
                else if (data.indexOf("preco-max-") !== -1) {
                    this.filters["price_lte"] = parseInt(data.split("-")[2]);
                    // this.sort = "-price";
                }
                else if (data.indexOf("preco-min-") !== -1) {
                    this.filters["price_gte"] = parseInt(data.split("-")[2]);
                    // this.sort = "price";
                }
                else if (data.indexOf("cond-valor") !== -1) {
                    this.filters["cond_valor"] = true;
                }
                else if (data.indexOf("ordem-") !== -1) {
                    var _sort = data.split("-")[1];
                    console.log(_sort);
                    if (_sort == 'price_min') {
                        this.sort = "price";
                    }
                    else if (_sort == 'price_max') {
                        this.sort = "-price";
                    }
                    console.log(this.sort);
                }
                else if (data.indexOf("page-") !== -1) {
                    this.currentPage = parseInt(data.split("-")[1]);
                }
            }
            // if(this.filters["price_lte"]) {
            //     this.sort = "-price";
            // }else if(this.filters["price_gte"]) {
            //     this.sort = "price";
            // }
        }
        console.log(this.sort);
    };
    /**
     * Carrega mais imóveis
     * Mobile não deve fazer uma pesquisa a cada click
     */
    PropertiesSearchController.prototype.loadProperties = function () {
        if (!this.mobile) {
            this.lock();
            this.updateURL();
        }
    };
    /**
     * Carrega mais imóveis
     * Apenas quando apertar pesquisar
     */
    PropertiesSearchController.prototype.loadPropertiesMobile = function () {
        this.openFilters = false;
        this.lock();
        this.updateURL();
    };
    /**
     * Gerar Url
     */
    PropertiesSearchController.prototype.generateURL = function () {
        this.filters['finality'] = this.finality;
        var correct_url = "";
        correct_url += (this.filters["finality"] == 'sale' ? 'venda' : 'locacao') + "/";
        correct_url += (this.filters["location"]) ? "l-" + this.filters["location"] + "/" : "";
        correct_url += (this.filters["type_name"]) ? "t-" + this.filters["type_name"] + "/" : "";
        correct_url += (this.filters["subtype"]) ? "sub-" + this.filters["subtype"] + "/" : "";
        correct_url += (this.filters["bedrooms"]) ? "dorms-" + this.filters["bedrooms"] + "/" : "";
        correct_url += (this.filters["suites"]) ? "suites-" + this.filters["suites"] + "/" : "";
        correct_url += (this.filters["vacancies"]) ? "vagas-" + this.filters["vacancies"] + "/" : "";
        correct_url += (this.filters["bathrooms"]) ? "banheiros-" + this.filters["bathrooms"] + "/" : "";
        correct_url += (this.filters["area_gte"]) ? "area-min-" + this.filters["area_gte"] + "/" : "";
        correct_url += (this.filters["area_lte"]) ? "area-max-" + this.filters["area_lte"] + "/" : "";
        correct_url += (this.filters["price_gte"]) ? "preco-min-" + this.filters["price_gte"] + "/" : "";
        correct_url += (this.filters["price_lte"]) ? "preco-max-" + this.filters["price_lte"] + "/" : "";
        correct_url += (this.filters["cond_valor"]) ? "cond-valor" : "";
        if (this.sort) {
            if (this.sort == 'price') {
                var _sort = 'price_min';
                correct_url += "ordem-" + _sort + "/";
            }
            else if (this.sort == '-price') {
                var _sort = 'price_max';
                correct_url += "ordem-" + _sort + "/";
            }
        }
        correct_url += (this.currentPage && this.currentPage !== 1) ? "page-" + this.currentPage + "/" : "";
        correct_url = this.settings.URL + '/' + correct_url;
        correct_url = correct_url.replace(/ /g, '-');
        if (correct_url.substring(correct_url.length - 1) == '/') {
            correct_url = correct_url.substring(0, correct_url.length - 1);
        }
        return correct_url;
    };
    /**
     * Atualiza URL
     */
    PropertiesSearchController.prototype.updateURL = function () {
        if (this.sortPrice !== '') {
            if (this.sortPrice == 'price_gte') {
                this.sort = 'price';
            }
            else if (this.sortPrice == 'price_lte') {
                this.sort = '-price';
            }
            this.sortPrice = '';
        }
        var correct_url = this.generateURL();
        if (this.correct_url !== correct_url) {
            this.correct_url = correct_url;
            history.pushState(null, "", correct_url);
        }
        else {
            this.unlock();
        }
    };
    /**
     * Tratamento dos imóveis
     * @param properties
     * @returns {any}
     */
    PropertiesSearchController.prototype.processProperties = function (properties) {
        for (var i in properties) {
            properties[i].image = properties[i].images[0];
            properties[i].positionSlide = properties[i].videoYT ? -1 : 0;
        }
        return properties;
    };
    /**
     * Verifica se existe modificações nos formulários
     */
    PropertiesSearchController.prototype.updateProperties = function (input) {
        if (this.last_filters['area_gte'] !== this.filters['area_gte'] ||
            this.last_filters['area_lte'] !== this.filters['area_lte'] ||
            this.last_filters['price_gte'] !== this.filters['price_gte'] ||
            this.last_filters['price_lte'] !== this.filters['price_lte']) {
            this.last_filters = angular.copy(this.filters);
            if (input === 'price_gte') {
                this.sortPrice = 'price_gte';
            }
            else if (input === 'price_lte') {
                if (this.sortPrice !== 'price_gte') {
                    this.sortPrice = 'price_lte';
                }
            }
            console.log(this.sortPrice);
            this.reset();
            this.loadProperties();
        }
    };
    PropertiesSearchController.prototype.updatePage = function (page) {
        this.currentPage = page;
        this.loadPropertiesMobile();
    };
    /**
     * Reseta filtros para primeira página
     */
    PropertiesSearchController.prototype.reset = function () {
        this.limit = 20;
        this.offset = 0;
        this.currentPage = 1;
    };
    /**
     * Set Composition
     * @param comp
     * @param value
     */
    PropertiesSearchController.prototype.setComp = function (comp, value) {
        if (this.loading)
            return;
        if (this.filters[comp] == value) {
            this.filters[comp] = null;
        }
        else {
            this.filters[comp] = value;
        }
        this.reset();
        this.loadProperties();
    };
    PropertiesSearchController.prototype.addType = function (type) {
        type = type.name.toLowerCase();
        if (!this.types) {
            this.types = [];
        }
        if (this.types.indexOf(type) == -1) {
            this.types.push(type);
        }
        else {
            this.types.splice(this.types.indexOf(type), 1);
        }
        this.filters["type_name"] = this.types.join(",");
        this.loadProperties();
    };
    PropertiesSearchController.prototype.addSubType = function (subtype) {
        subtype = subtype.id;
        if (!this.subtypes) {
            this.subtypes = [];
        }
        if (this.subtypes.indexOf(subtype) == -1) {
            this.subtypes.push(subtype);
        }
        else {
            this.subtypes.splice(this.subtypes.indexOf(subtype), 1);
        }
        this.filters["subtype"] = this.subtypes.join(",");
        this.loadProperties();
    };
    /**
     * Trava Busca
     */
    PropertiesSearchController.prototype.lock = function () {
        // $('input').prop('disabled', true);
        // $('select').prop('disabled', true);
        this.pageDisabled = true;
        this.loading = true;
    };
    /**
     * Destrava Busca
     */
    PropertiesSearchController.prototype.unlock = function () {
        // $('input').prop('disabled', false);
        // $('select').prop('disabled', false);
        this.pageDisabled = false;
        this.loading = false;
    };
    PropertiesSearchController.prototype.nextPhoto = function (id, e) {
        console.log('nextPhoto');
        e.preventDefault(); // prevent button default behavior
        e.stopPropagation(); // prevent propagation of your event to the link
        var index = -1;
        for (var i in this.properties) {
            if (this.properties[i].id == id)
                index = i;
        }
        if (this.properties[index].loadingimage)
            return;
        this.properties[index].loadingimage = false;
        this.properties[index].positionSlide++;
        if (this.properties[index].images.length <= this.properties[index].positionSlide) {
            // this.properties[index].loadingimage = true;
            this.properties[index].positionSlide = this.properties[index].images.length - 1;
        }
        console.log(this.properties[index].images[this.properties[index].positionSlide].image);
        this.properties[index].image = this.properties[index].images[this.properties[index].positionSlide];
    };
    PropertiesSearchController.prototype.lastPhoto = function (id, e) {
        console.log('lastPhoto');
        // console.log('ss');
        e.preventDefault(); // prevent button default behavior
        e.stopPropagation(); // prevent propagation of your event to the link
        var index = -1;
        for (var i in this.properties) {
            if (this.properties[i].id == id)
                index = i;
        }
        if (this.properties[index].loadingimage)
            return;
        this.properties[index].loadingimage = false;
        this.properties[index].positionSlide--;
        if (this.properties[index].videoYT) {
            if (this.properties[index].positionSlide < -1) {
                // this.properties[index].loadingimage = true;
                this.properties[index].positionSlide = -1;
            }
        }
        else {
            if (this.properties[index].positionSlide < 0) {
                // this.properties[index].loadingimage = true;
                this.properties[index].positionSlide = 0;
            }
        }
        this.properties[index].image = this.properties[index].images[this.properties[index].positionSlide];
    };
    ;
    PropertiesSearchController.$inject = [
        'properties',
        'propertiesService',
        '$state',
        '$scope',
        '$rootScope',
        '$document',
        '$window',
        'seoManager',
    ];
    return PropertiesSearchController;
}());
angular
    .module('app')
    .controller('PropertiesSearchController', PropertiesSearchController);
var PropertiesViewController = /** @class */ (function () {
    function PropertiesViewController(property, propertiesService, $state, $filter, $scope, seoManager, $location, ngYoutubeEmbedService, $interval, $timeout, $cookies, $rootScope, $document) {
        this.settings = window['settings'];
        // Posição do Slide
        this.positionSlide = 0;
        this.positionSlideFullScreen = 0;
        this.slide_positionFullScreen = {};
        this.slide_position = {};
        this.$state = $state;
        this.$scope = $scope;
        this.$filter = $filter;
        this.$location = $location;
        this.$interval = $interval;
        this.$timeout = $timeout;
        this.$cookies = $cookies;
        this.seoManager = seoManager;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.property = property;
        $rootScope.propertyID = this.property.id;
        $rootScope.propertyMessage = 'Olá, tenho interesse neste imóvel: ' + this.property.title + ' - Cód. ' + this.property.cod + '. Aguardo o contato. Obrigado.';
        $rootScope.propertyMessagePartner = 'Olá, tenho interesse neste imóvel: ' + this.property.title + ' - Cód. ' + this.property.cod + '. Sou um corretor da imobiliária ' + $rootScope.realestate.name + ' https://' + window.location.hostname + '/' + this.property.url;
        this.seoManager.setTitle(this.property.title + ' | ' + $rootScope.realestate.name);
        this.seoManager.setDescription(this.property.description);
        this.seoManager.setImage(this.property.images[0].url);
        this.seoManager.setUrl('https://' + window.location.hostname + '/' + this.property.url);
        /**
         * Update Url
         * @type {() => void}
         */
        var off = $scope.$on('$stateChangeStart', function (e) {
            e.preventDefault();
            off();
        });
        $location.path(this.property.url).replace();
        this.$rootScope.breadcrumbs = this.property.breadcrumb;
        var details = [];
        if (this.property.area_total) {
            details.push({
                name: 'Área Total',
                value: this.property.area_total + 'm2',
            });
        }
        if (this.property.area_useful) {
            details.push({
                name: 'Área Útil',
                value: this.property.area_useful + 'm2',
            });
        }
        if (this.property.townhouse_price) {
            details.push({
                name: 'Condominio',
                value: 'R$ ' + this.$filter('price')(this.property.townhouse_price),
            });
        }
        if (this.property.IPTU) {
            details.push({
                name: 'IPTU',
                value: 'R$ ' + this.$filter('price')(this.property.IPTU)
            });
        }
        if (this.property.financing) {
            details.push({
                name: 'Financia?',
                value: 'Sim'
            });
        }
        if (this.property.FGTS) {
            details.push({
                name: 'Aceita FGTS?',
                value: 'Sim'
            });
        }
        if (this.property.credit_card) {
            details.push({
                name: 'Aceita Carta de Crédito?',
                value: 'Sim'
            });
        }
        if (this.property.MCMV) {
            details.push({
                name: 'Minha Casa Minha Vida?',
                value: 'Sim'
            });
        }
        if (this.property.exchange) {
            details.push({
                name: 'Permuta',
                value: 'Sim'
            });
        }
        for (var i in this.property.composition) {
            details.push(this.property.composition[i]);
        }
        this.property.details = details;
        if (!this.property.videoYT) {
            this.property.videoYT = undefined;
        }
        this.property.image_count = this.property.images.length;
        this.property.image_countYT = this.property.images.length + (this.property.videoYT ? 1 : 0);
        //Alerta para adicionar imóvel nos favoritos
        this.viewInfoFav = !$cookies.get('__viewInfoFavProp');
        this.$timeout(function () {
            $('.info-tooltip').fadeOut('show');
        }, 4000);
        // Registra $destroy
        $scope.$on('$destroy', this.$destroy);
    }
    PropertiesViewController.prototype.$onInit = function () {
        var _this = this;
        this.keyToFullScreen();
        this.firstLastSlide();
        this.$scope.$watch(function () {
            return _this.$rootScope.startedVideo;
        }, function (newVal) {
            if (!angular.isUndefined(newVal) || newVal !== '') {
                if (_this.$rootScope.startedVideo) {
                    if (_this.$location.hash() == 'video') {
                        console.log(_this.playVideo());
                        _this.playVideo();
                    }
                    else {
                        _this.$rootScope.startVideo = false;
                    }
                }
            }
        });
    };
    PropertiesViewController.prototype.$destroy = function () {
    };
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    PropertiesViewController.prototype.sharedEmail = function () {
        this.$rootScope.target_email = this.property.id;
        this.$rootScope.type_email = 'property';
        this.$rootScope.shareEmailModal = true;
    };
    PropertiesViewController.prototype.openPartners = function () {
        if (this.$rootScope.realestate.id == 297 || this.$rootScope.realestate.id == 610 || this.$rootScope.realestate.id == 613 || this.$rootScope.realestate.id == 627 || this.$rootScope.realestate.id == 11) {
            window.open('https://api.whatsapp.com/send?phone=5511977477000&text=' + this.$rootScope.propertyMessagePartner, '_blank');
        }
        else {
            var pass = prompt("Informe a senha para parceria");
            if (pass == "7") {
                window.open('https://api.whatsapp.com/send?phone=5511977477000&text=' + this.$rootScope.propertyMessagePartner, '_blank');
            }
            else {
                alert("Senha incorreta");
            }
        }
    };
    /**
     * Move Slide
     */
    PropertiesViewController.prototype.rightImage = function () {
        this.firstLastSlide();
        if (this.positionSlide >= this.property.image_countYT - 1) {
            this.firstLastSlide();
            return;
        }
        this.pauseVideo();
        this.$interval.cancel(this.animationSlide);
        this.positionSlide++;
        this.firstLastSlide();
        var anim_start = (new Date()).getTime();
        var that = this;
        this.animationSlide = this.$interval(function () {
            var porc = ((new Date()).getTime() - anim_start) / 2;
            var positionSlideTrans = that.positionSlide * 100 - (100 - porc);
            that.slide_position = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };
            if (porc >= 100) {
                positionSlideTrans = that.positionSlide * 100;
                that.slide_position = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);
                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    };
    /**
     * Move Slide
     */
    PropertiesViewController.prototype.leftImage = function () {
        this.$interval.cancel(this.animationSlide);
        this.positionSlide--;
        if (this.positionSlide < 0) {
            this.positionSlide = 0;
            this.firstLastSlide();
            return;
        }
        this.firstLastSlide();
        this.pauseVideo();
        var anim_start = (new Date()).getTime();
        var that = this;
        this.animationSlide = this.$interval(function () {
            var porc = ((new Date()).getTime() - anim_start);
            var positionSlideTrans = that.positionSlide * 100 + (100 - porc);
            console.log(positionSlideTrans);
            that.slide_position = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };
            if (porc >= 100) {
                positionSlideTrans = that.positionSlide * 100;
                that.slide_position = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);
                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    };
    /**
     *
     */
    PropertiesViewController.prototype.firstLastSlide = function () {
        if (this.positionSlide == 0) {
            this.positionSlideFirst = true;
        }
        else {
            this.positionSlideFirst = false;
        }
        if (this.positionSlide >= this.property.image_countYT - 1) {
            this.positionSlideLast = true;
        }
        else {
            this.positionSlideLast = false;
        }
    };
    /**
     * Move Slide
     */
    PropertiesViewController.prototype.rightImageFullScreen = function () {
        if (this.positionSlideFullScreen >= this.property.image_count - 1)
            return;
        this.pauseVideo();
        this.$interval.cancel(this.animationSlide);
        this.positionSlideFullScreen++;
        var anim_start = (new Date()).getTime();
        var that = this;
        this.animationSlide = this.$interval(function () {
            var porc = ((new Date()).getTime() - anim_start) / 2;
            var positionSlideTrans = that.positionSlideFullScreen * 100 - (100 - porc);
            that.slide_positionFullScreen = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };
            if (porc >= 100) {
                positionSlideTrans = that.positionSlideFullScreen * 100;
                that.slide_positionFullScreen = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);
                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    };
    /**
     * Move Slide
     */
    PropertiesViewController.prototype.leftImageFullScreen = function () {
        this.$interval.cancel(this.animationSlide);
        this.positionSlideFullScreen--;
        if (this.positionSlideFullScreen < 0) {
            this.positionSlideFullScreen = 0;
            return;
        }
        this.pauseVideo();
        var anim_start = (new Date()).getTime();
        var that = this;
        this.animationSlide = this.$interval(function () {
            var porc = ((new Date()).getTime() - anim_start);
            var positionSlideTrans = that.positionSlideFullScreen * 100 + (100 - porc);
            console.log(positionSlideTrans);
            that.slide_positionFullScreen = {
                transform: 'translateX(-' + positionSlideTrans + '%)'
            };
            if (porc >= 100) {
                positionSlideTrans = that.positionSlideFullScreen * 100;
                that.slide_positionFullScreen = {
                    transform: 'translateX(-' + positionSlideTrans + '%)'
                };
                console.log(positionSlideTrans);
                that.$interval.cancel(that.animationSlide);
            }
        }, 10);
    };
    PropertiesViewController.prototype.keyToFullScreen = function () {
        var that = this;
        var event = function (e) {
            if (that.$rootScope.slideshow) {
                if (e.which == 39) {
                    that.rightImageFullScreen();
                }
                if (e.which == 37) {
                    that.leftImageFullScreen();
                }
                if (e.which == 27) {
                    that.$rootScope.slideshow = false;
                }
                that.$scope.$apply();
            }
        };
        this.$scope.$on('$destroy', function () {
            angular.element(that.$document).off('keydown', event);
        });
        this.$document.on('keydown', event);
    };
    PropertiesViewController.prototype.pauseVideo = function () {
        if (this.$rootScope.startVideo) {
            // if(!this.player){
            //     this.player = this.ngYoutubeEmbedService.getPlayerById('myvideo');
            // }
            this.player.pauseVideo();
        }
    };
    PropertiesViewController.prototype.playVideo = function () {
        if (!this.player) {
            this.$rootScope.startVideo = true;
            this.player = new YT.Player('player', {
                videoId: this.property.videoYT,
                events: {
                    'onReady': function (event) {
                        console.log('start');
                        event.target.playVideo();
                    },
                    onError: function (event) {
                        console.log(event);
                    }
                }
            });
        }
    };
    PropertiesViewController.prototype.viewInfoFavOk = function () {
        $('.info-tooltip').fadeOut('show');
        this.$cookies.put('__viewInfoFavProp', false);
    };
    PropertiesViewController.$inject = [
        'property',
        'propertiesService',
        '$state',
        '$filter',
        '$scope',
        'seoManager',
        '$location',
        'ngYoutubeEmbedService',
        '$interval',
        '$timeout',
        '$cookies',
        '$rootScope',
        '$document'
    ];
    return PropertiesViewController;
}());
angular
    .module('app')
    .controller('PropertiesViewController', PropertiesViewController);
/**
 * Iniciar Video de Youtube
 */
function startYoutube() {
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}
function onYouTubeIframeAPIReady() {
    angular.element(document).ready(function () {
        // Chama Angularjs
        var $body = angular.element(document);
        var $rootScope = $body.scope().$root;
        $rootScope.$apply(function () {
            $rootScope.startedVideo = true;
        });
    });
}
startYoutube();
var PropertyRegisterController = /** @class */ (function () {
    function PropertyRegisterController($rootScope, $scope, $q, $state, seoManager, $location, $timeout, Upload, propertiesService) {
        var _this = this;
        this.settings = window['settings'];
        this.d = {};
        this.validate = false;
        this.tab = 1;
        this.allTabs = false;
        //tmps
        this.finality = 0;
        this.subtype = [];
        this.benefits = [];
        this.photo_ids = 0;
        this.photo_uploading = 0;
        this.photos_count = 0;
        this.photos = [];
        this.uploading = false;
        this.saving = false;
        this.loadimages = '';
        this.loadingAddress = false;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.Upload = Upload;
        this.$state = $state;
        this.$location = $location;
        this.$timeout = $timeout;
        this.seoManager = seoManager;
        this.propertiesService = propertiesService;
        this.seoManager.setTitle('Cadastrar Imóvel | ' + $rootScope.realestate.name);
        this.seoManager.setTitleH1('Cadastrar Imóvel');
        this.seoManager.setUrl('/anunciarimovel', true);
        // Atualiza url
        var off = $scope.$on('$stateChangeStart', function (e) {
            e.preventDefault();
            off();
        });
        this.$location.path('/anunciarimovel').replace();
        this.d = {
            contact_phones: [],
            composition: {}
        };
        var that = this;
        this.$rootScope.info.properties.compositions.map(function (i) {
            that.d.composition[i.id] = {
                id: i.id,
                value: 0,
            };
        });
        this.addPhone();
        this.$scope.$watch('vm.d.CEP', function (newValue, oldValue) {
            if (newValue != oldValue) {
                if (!!newValue) {
                    // this.loadingAddress = true;
                    // console.log(this.loadingAddress);
                    // this.viaCep.get(newValue).then((response) => {
                    //     this.loadingAddress = false;
                    //     this.d.address = response.logradouro;
                    //     this.d.city = response.localidade;
                    //     this.d.neighborhood = response.bairro;
                    //     this.d.estate = response.uf.toUpperCase();
                    //     console.log(response);
                    // }).catch(() => {
                    //     this.loadingAddress = false;
                    //     console.log(this.loadingAddress);
                    // });
                }
            }
        });
        // this.initForm();
        window.addEventListener('popstate', function () {
            var step = parseInt(window.location.search.substr(1).replace('step=', ''));
            if (!step)
                step = 1;
            var _step_validate = 7;
            for (var i = 1; i <= 7; i++) {
                if ($scope['step' + i].$invalid) {
                    _step_validate = i;
                    break;
                }
            }
            console.log("_step_validate", _step_validate);
            //Se a etapa que o usuário quiser ir não estiver valida,
            //vai para a valida mais proxima
            if (step > _step_validate) {
                _this.tab = _step_validate;
                _this.validate = true;
            }
            else {
                _this.tab = step;
            }
            $('html,body').animate({ scrollTop: $(".top").offset().top }, 'slow');
            $scope.$apply();
        });
    }
    PropertyRegisterController.prototype.generateDescription = function () {
        'Casa a venda no bairro de São Miguel Paulista em São Paulo por R$200.000 com 3 banheiros, 1 sala, 3 cozinhas, Cozinha, Ar condicionado, Sistema de Alarme. Com 22m de área util e 22m de área total. Otima localização';
    };
    PropertyRegisterController.prototype.addPhone = function () {
        this.d.contact_phones.push({});
    };
    PropertyRegisterController.prototype.removePhone = function (index) {
        this.d.contact_phones.splice(index, 1);
    };
    PropertyRegisterController.prototype.indexOfComposition = function (id) {
        if (!!this.d.type) {
            for (var i in this.$rootScope.info.properties.types) {
                var item = this.$rootScope.info.properties.types[i];
                if (item.id == this.d.type) {
                    return item.properties_composition_id.indexOf(Number(id)) !== -1;
                }
            }
        }
        return false;
    };
    /**
     * Volta etapa
     * @returns {boolean}
     */
    PropertyRegisterController.prototype.lastTab = function () {
        this.tab -= 1;
    };
    /**
     * Avança para proxima etapa
     * @returns {boolean}
     */
    PropertyRegisterController.prototype.nextTab = function () {
        if (this.$scope['step' + this.tab].$invalid) {
            this.validate = true;
            setTimeout(function () {
                $('html,body').scrollTop($(".error").offset().top);
            }, 200);
            return false;
        }
        this.validate = false;
        this.tab += 1;
        $('html,body').animate({ scrollTop: $(".top").offset().top }, 'slow');
        this.$state.go('root.propertyRegister', {
            step: this.tab
        }, { notify: false });
    };
    /**
     * @param type
     */
    PropertyRegisterController.prototype.selectSubType = function (type) {
        if (!this.d.subtype) {
            this.d.subtype = [];
        }
        if (this.d.subtype.indexOf(type) == -1) {
            this.d.subtype.push(type);
            this.subtype[type] = true;
        }
        else {
            this.d.subtype.splice(this.d.subtype.indexOf(type), 1);
            this.subtype[type] = false;
        }
    };
    /**
     * @param value
     */
    PropertyRegisterController.prototype.selectBenefits = function (value) {
        if (!this.d.benefits) {
            this.d.benefits = [];
        }
        if (this.d.benefits.indexOf(value) == -1) {
            this.d.benefits.push(value);
            this.benefits[value] = true;
        }
        else {
            this.d.benefits.splice(this.d.benefits.indexOf(value), 1);
            this.benefits[value] = false;
        }
    };
    PropertyRegisterController.prototype.uploadFiles = function (files) {
        if (files && files.length) {
            for (var i = 0; i < files.length; i++) {
                //Adiciona Card
                var current_image = {
                    id: this.photo_ids++,
                    file: files[i],
                    remove: false,
                    error: false,
                    load: false,
                    loading: true,
                    currentValue: 0,
                    maxValue: 100 // Valor Máximo da porcentagem
                };
                this.photos.push(current_image);
                this.photo_uploading++;
            }
            this.uploadImages();
        }
    };
    PropertyRegisterController.prototype.uploadImages = function (id) {
        var photo_load_id = -1;
        if (typeof id == 'undefined') {
            for (var i in this.photos) {
                if (!this.photos[i].load && !this.photos[i].remove && !this.photos[i].error) {
                    photo_load_id = Number(i);
                    break;
                }
            }
            if (photo_load_id == -1)
                return false;
        }
        else {
            photo_load_id = id;
        }
        this.prepareUploadFile(photo_load_id);
    };
    /**
     * Preparar foto para Upload
     * @param id
     */
    PropertyRegisterController.prototype.prepareUploadFile = function (id) {
        this.photos[id].loading = true;
        this.photos[id].error = false;
        this.photos[id].currentValue = 0;
        if (this.uploading == true) {
            return;
        }
        this.photos[id].currentValue = 5;
        this.uploading = true;
        var fileReader = new FileReader();
        var that = this;
        fileReader.onload = function (e) {
            that.resizeImage(e.target['result'])
                .then(function (data) {
                that.photos[id].src = data.base64;
                that.uploadFile(data.blob, id);
            });
        };
        fileReader.readAsDataURL(this.photos[id].file);
    };
    /**
     * Upar Arquivo
     * @param file
     * @param photo_load_id
     */
    PropertyRegisterController.prototype.uploadFile = function (file, photo_load_id) {
        var that = this;
        this.Upload.upload({
            url: this.settings['API'] + '/realestate/uploads/realestate_image_property?token=' + this.settings['TOKEN'],
            data: {
                file: file,
                id: photo_load_id
            }
        }).then(function (resp) {
            var data = resp.data;
            var id = resp.config.data.id;
            if (data.success) {
                var body = data.body;
                //Em caso de sucesso remove arquivo na memoria
                that.photos[id].file = null;
                that.photos[id].load = true;
                that.photos[id].currentValue = 100;
                //Adiciona a quantidade de fotos enviadas
                that.photos_count++;
                //Remove erro
                that.photos[id].error = false;
                that.photos[id].image = body[0].key_name;
                that.photos[id].loading = false;
                that.photo_uploading--;
                // Se tiver mais itens na fila, faz upload novamente
                that.uploading = false;
                that.uploadImages();
            }
            else {
                that.photos[id].loading = false;
                that.photos[id].error = true;
                that.photo_uploading--;
                // Se tiver mais itens na fila, faz upload novamente
                that.uploading = false;
                that.uploadImages();
            }
        }, function (resp) {
            var id = resp.config.data.id;
            that.photos[id].loading = false;
            that.photos[id].error = true;
            that.photo_uploading--;
            // Se tiver mais itens na fila, faz upload novamente
            that.uploading = false;
            that.uploadImages();
        }, function (evt) {
            var id = evt.config.data.id;
            var progressPercentage = Math.floor(100.0 * evt.loaded / evt.total);
            that.photos[id].currentValue = progressPercentage;
            console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        });
    };
    /**
     * Refazer o Upload da foto
     * @param id
     */
    PropertyRegisterController.prototype.reUpload = function (id) {
        var photo_load_id = -1;
        for (var i in this.photos) {
            if (this.photos[i].id == id) {
                photo_load_id = Number(i);
                break;
            }
        }
        if (photo_load_id != -1) {
            this.uploadImages(photo_load_id);
        }
    };
    /**
     * Remover foto
     * @param id
     */
    PropertyRegisterController.prototype.removePhoto = function (id) {
        var index = -1;
        for (var i in this.photos) {
            if (id == this.photos[i].id) {
                index = Number(i);
            }
        }
        if (index == -1)
            return;
        // Remove quantidade de fotos enviadas
        // Apenas se tiver erro
        if (!this.photos[index].error) {
            this.photos_count--;
        }
        //Remove Foto
        // this.photos.splice(index, 1);
        this.photos[index].remove = true;
    };
    /**
     * Quantidade de imagens upadas
     * @returns {number}
     */
    PropertyRegisterController.prototype.countImages = function () {
        var count_images = 0;
        for (var i in this.photos) {
            if (this.photos[i].load && !this.photos[i].remove && !this.photos[i].error) {
                count_images++;
            }
        }
        return count_images;
    };
    /**
     * Se existe imagens sendo feitas upload
     * @returns {number}
     */
    PropertyRegisterController.prototype.imagesUploading = function () {
        var count_images = 0;
        for (var i in this.photos) {
            if (!this.photos[i].load && !this.photos[i].remove && !this.photos[i].error) {
                count_images++;
            }
        }
        return count_images;
    };
    /**
     * Rotacionar imagem
     * @param id
     */
    PropertyRegisterController.prototype.rotDec = function (id) {
        if (!this.photos[id].rot)
            this.photos[id].rot = 0;
        this.photos[id].rot -= 90;
        this.photos[id].rot += 360;
        this.photos[id].rot = this.photos[id].rot % 360;
    };
    /**
     * Rotacionar imagem
     * @param id
     */
    PropertyRegisterController.prototype.rotIn = function (id) {
        if (!this.photos[id].rot)
            this.photos[id].rot = 0;
        this.photos[id].rot += 90;
        this.photos[id].rot = this.photos[id].rot % 360;
    };
    /**
     * Redimensiona a imagem para enviar poucos dados
     * @param base64
     * @returns {any}
     */
    PropertyRegisterController.prototype.resizeImage = function (base64) {
        var that = this;
        return this.$q(function (resolve, reject) {
            var canvas = document.createElement('canvas'), context = null;
            context = canvas.getContext('2d');
            var imageObj = new Image();
            imageObj.onload = function () {
                // Check for empty images
                if (this['width'] === 0 || this['height'] === 0) {
                }
                else {
                    if (this['width'] > this['height']) {
                        canvas.width = 900;
                        canvas.height = this['height'] * (900 / this['width']);
                    }
                    else {
                        canvas.height = 900;
                        canvas.width = this['width'] * (900 / this['height']);
                    }
                    // context.clearRect(0, 0, canvas.width, canvas.height);
                    context.drawImage(imageObj, 0, 0, this['width'], this['height'], 0, 0, canvas.width, canvas.height);
                    var blob = that.dataURLtoBlob(canvas.toDataURL('image/png'));
                    var data = {
                        blob: blob,
                        base64: canvas.toDataURL('image/png'),
                    };
                    resolve(data);
                }
            };
            imageObj.onerror = function () {
                reject();
            };
            imageObj.src = base64;
        });
    };
    /**
     * Converte base64 em Blob
     * @param dataurl
     * @returns {Blob}
     */
    PropertyRegisterController.prototype.dataURLtoBlob = function (dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {
            type: mime
        });
    };
    PropertyRegisterController.prototype.resetForm = function () {
        window.location.reload();
    };
    /**
     * Salva Imóvel
     * @returns {any}
     */
    PropertyRegisterController.prototype.save = function () {
        var _this = this;
        if (this.saving)
            return false;
        this.saving = true;
        if (!this.countImages()) {
            this.loadimages = "É preciso enviar ao menos 1 foto.";
            this.saving = false;
            return false;
        }
        if (this.imagesUploading()) {
            this.loadimages = "Aguarde um momento. Suas imagens estão sendo enviadas para o servidor.";
            this.saving = false;
            return false;
        }
        var data = angular.copy(this.d);
        data.status = -1;
        data.finality = [];
        /**
         * Finalidade
         */
        if (this.finality === 0) {
            data.finality.push({
                id: 0,
                value: Number(this.sale_price) * 100
            });
        }
        else if (this.finality === 1) {
            data.finality.push({
                id: 1,
                value: Number(this.rent_price) * 100
            });
        }
        else if (this.finality === 2) {
            data.finality.push({
                id: 0,
                value: Number(this.sale_price) * 100
            });
            data.finality.push({
                id: 1,
                value: Number(this.rent_price) * 100
            });
        }
        if (!!data.IPTU) {
            data.IPTU = data.IPTU * 100;
        }
        if (!!data.townhouse_price) {
            data.townhouse_price = data.townhouse_price * 100;
        }
        /**
         * Composição
         */
        var composition = [];
        for (var i in data.composition) {
            if (data.composition[i].value != 0) {
                composition.push(data.composition[i]);
            }
        }
        data.composition = composition;
        /**
         * Images
         */
        data.images = [];
        for (var i in this.photos) {
            if (!this.photos[i].error && !this.photos[i].remove) {
                if (!this.photos[i].rot)
                    this.photos[i].rot = 0;
                var image = {
                    image: this.photos[i].image,
                    rotate: this.photos[i].rot
                };
                data.images.push(image);
            }
        }
        this.$rootScope.loadingPage = true;
        this.propertiesService
            .create(data)
            .then(function (response) {
            _this.$rootScope.loadingPage = false;
            _this.saving = false;
            _this.success = true;
        }, function (err) {
            _this.$rootScope.loadingPage = false;
            _this.saving = false;
            alert('Houve um erro ao adicionar seu imóvel! Entre em contato conosco.');
        });
    };
    /**
     * Only Dev
     */
    PropertyRegisterController.prototype.initForm = function () {
        this.d.address = "";
        this.d.contact_phones = [{
                "type": "",
                "number": "",
                "operator": ""
            }
        ];
        this.d.contact_name = "";
        this.d.contact_email = "";
        this.d.IPTU = 100;
        this.d.townhouse_price = 120;
        this.d.type = 4;
        this.d.subtype = [1, 5, 3, 8];
        this.d.benefits = [1, 2, 8, 9, 12, 13, 21, 22];
        this.d.townhouse_name = "Condominio";
        this.d.financing = 1;
        this.d.area_useful = 2133;
        this.d.area_total = 12323;
        this.d.description = "asdassda";
        this.d.CEP = "";
        this.d.city = "";
        this.d.neighborhood = "";
        this.d.estate = "SP";
        this.d.number = "21";
        this.d.zone = "leste";
        this.d.finality = [
            {
                "id": 0,
                "value": 1211200
            }
        ];
        this.sale_price = '200000';
        this.photos = [
            {
                "id": 0,
                "file": null,
                "remove": false,
                "error": false,
                "load": true,
                "loading": false,
                "currentValue": 100,
                "maxValue": 100,
                "loading_web": true,
                "src": "https://s3-sa-east-1.amazonaws.com/bdi-image-dev/properties/1/f3faa03015406506609245.small.jpg",
                "image": "https://s3-sa-east-1.amazonaws.com/bdi-image-dev/properties/1/f3faa03015406506609245.small.jpg"
            }
        ];
    };
    ;
    PropertyRegisterController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        'seoManager',
        '$location',
        '$timeout',
        'Upload',
        'propertiesService',
    ];
    return PropertyRegisterController;
}());
angular
    .module('app')
    .controller('PropertyRegisterController', PropertyRegisterController);
var PropertyRequestController = /** @class */ (function () {
    function PropertyRequestController($rootScope, $scope, $q, $state, $location, $timeout, Upload, propertiesService, seoManager) {
        this.settings = window['settings'];
        this.d = {};
        this.validate = false;
        this.tab = 1;
        this.allTabs = false;
        //tmps
        this.finality = 0;
        this.subtype = [];
        this.benefits = [];
        this.photo_ids = 0;
        this.photo_uploading = 0;
        this.photos_count = 0;
        this.photos = [];
        this.uploading = false;
        this.saving = false;
        this.loadimages = '';
        this.loadingAddress = false;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.Upload = Upload;
        this.$state = $state;
        this.$location = $location;
        this.$timeout = $timeout;
        this.propertiesService = propertiesService;
        seoManager.setTitle('Encomende seu Imóvel | ' + $rootScope.realestate.name);
        seoManager.setUrl('/encomende-seu-imovel', true);
    }
    PropertyRequestController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        '$location',
        '$timeout',
        'Upload',
        'propertiesService',
        'seoManager',
    ];
    return PropertyRequestController;
}());
angular
    .module('app')
    .controller('PropertyRequestController', PropertyRequestController);
var RealEstateNeighborhoodPageController = /** @class */ (function () {
    function RealEstateNeighborhoodPageController(data, $filter, $rootScope, seoManager, $stateParams) {
        // this.$rootScope = $rootScope;
        //
        // this.data = data;
        // this.location = data.location;
        // this.$stateParams = $stateParams;
        // this.$filter = $filter;
        // this.properties = data.properties.results;
        // this.aggrs = data.properties.aggrs;
        // this.pageCity = !this.location.neighborhood;
        // this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        // this.url_sale = $filter('convertUrl')(this.url_sale);
        // this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        // this.url_rent = $filter('convertUrl')(this.url_rent);
        //
        // let description = '';
        //
        // if(this.pageCity){
        //     this.title = 'Imobiliária ' + this.location.city + ', Apartamentos, Casas e Sobrados.';
        //     this.$rootScope.title_page = 'Imobiliárias ' + this.location.city;
        //     description = "Imobiliária " + this.location.city + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.city + ".";
        // }else{
        // 	this.title = 'Imobiliária ' + this.location.neighborhood + ', Apartamentos, Casas e Sobrados.';
        //     this.$rootScope.title_page = 'Imobiliárias ' + this.location.neighborhood;
        // 	description = "Imobiliária " + this.location.neighborhood + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.neighborhood + ".";
        // }
        //
        // let url = $rootScope.URL + '/' + data.url;
        //
        // this.seoManager = seoManager;
        this.settings = window['settings'];
        this.description = '';
        this.started = false;
        this.isCustom = false;
        this.location = {
            city: "",
            estate: "",
            neighborhood: "",
            original_neighborhood: "",
            original_subneighborhood: "",
        };
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.seoManager = seoManager;
    }
    RealEstateNeighborhoodPageController.prototype.myFunction = function () {
        var settings = document.querySelector('#realestate-location');
        console.log(settings.attributes['value']['value']);
        var data = JSON.parse(settings.attributes['value']['value']).body;
        this.data = data;
        this.location = data.location;
        this.properties = [];
        this.aggrs = [];
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = this.$filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = this.$filter('convertUrl')(this.url_rent);
        var description = '';
        if (this.pageCity) {
            this.title = 'Imobiliária ' + this.location.city + ', Apartamentos, Casas e Sobrados.';
            this.$rootScope.title_page = 'Imobiliárias ' + this.location.city;
            description = "Imobiliária " + this.location.city + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.city + ".";
        }
        else {
            this.title = 'Imobiliária ' + this.location.neighborhood + ', Apartamentos, Casas e Sobrados.';
            this.$rootScope.title_page = 'Imobiliárias ' + this.location.neighborhood;
            description = "Imobiliária " + this.location.neighborhood + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.neighborhood + ".";
        }
        if (this.location.neighborhood === 'Ermelino Matarazzo') {
            this.title = 'Imobiliária em Ermelino Matarazzo, Zona Leste, São Paulo - Alto Pedroso';
            description = 'Imobiliária em Ermelino Matarazzo | Alto Pedroso. Milhares de Imóveis, na Zona Leste de São Paulo. Casas, Sobrados, Apartamentos, Departamento Jurídico.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Vila Matilde') {
            this.title = 'Imobiliária na Vila Matilde - Guia de Imóveis na Vila Matilde, ZL - SP.';
            description = 'Imobiliária na Vila Matilde - Zona Leste - SP.  apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar? Alto Pedroso Imóveis.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Penha') {
            this.title = 'Imobiliária na Penha, São Paulo - Zona Leste Alto Pedroso Imóveis ZL.·.';
            description = 'Imobiliária na Penha, São Paulo - Zona Leste Alto Pedroso Imóveis na ZL, Apartamentos, Casas, Sobrados, Imóveis Comerciais os Melhores Imóveis na Penha.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Mooca') {
            this.title = 'Imobiliária na Mooca - Lista de Imóveis na Mooca Zona Leste - São Paulo';
            description = 'Imobiliária na Mooca - Lista de Imóveis na Mooca, Apartamentos, Casas, Sobrados Imóveis Comerciais. Compra, Venda e Locação, Anuncie seu Imóvel Conosco.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Vila Curuça') {
            this.title = 'Imobiliária na Vila Curuçá - Lista de Imóveis na Vila Curuçá - ZL - SP.';
            description = 'Imobiliária na Vila Curuçá - Guia de Imóveis na Vila Curuçá, apartamentos, casas, sobrados, Quer Comprar, Alugar, ou Anunciar Imóveis na Vila Curuçá.·.?';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Parque Paulistano' || this.location.neighborhood === 'Parque Paulistano ') {
            this.title = 'Imobiliária no Parque Paulistano, São Miguel Paulista ZL - Alto Pedroso';
            description = 'Procurando Imobiliária no Parque Paulistano, São Miguel Paulista ZL? Vem para a Alto Pedroso Imóveis, empresa melhor avaliada do bairro, sede própria.·.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Jardim Helena') {
            this.title = 'Imobiliária no Jardim Helena Guia Imóveis no Jardim Helena Alto Pedroso';
            description = 'Imobiliária no Jardim Helena - Guia de Imóveis no Jardim Helena, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis ZL.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Jardim Paulista') {
            this.title = 'Imobiliária no Jardim Paulista Guia Imóveis no Jardim Paulista.';
            description = 'Imobiliária no Jardim Paulista - Lista de Imóveis no Jardim Paulista, ofertas de imóveis de corretores e Imobiliárias no Jardim Paulista - SP';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Santa Cecilia') {
            this.title = 'Imobiliária na Santa Cecilia Guia Imóveis na Santa Cecilia.';
            description = 'Imobiliária na Santa Cecilia - Lista de Imóveis na Santa Cecilia, ofertas de imóveis de corretores e Imobiliárias na Santa Cecilia - SP';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Cidade Nova São Miguel') {
            this.title = 'Imobiliária na Cidade Nova São Miguel, Lista de Imóveis na Cidade Nova São Miguel.';
            description = 'Imobiliária na Cidade Nova São Miguel - Guia de Imóveis na Cidade Nova São Miguel, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis?';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Guaianases') {
            this.title = 'Imobiliária em Guaianases - Lista de Imóveis em Guaianases Zona Leste.';
            description = 'Imobiliária em Guaianases - Listagem de Imóveis em Guaianases, apartamentos, casas, sobrados, imóveis comerciais, é na Alto Pedroso, entrada facilitada.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Vila Rosária ') {
            this.title = 'Imobiliária na Vila Rosária Guia Imóveis na Vila Rosária.';
            description = 'Imobiliária na Vila Rosária - Lista de Imóveis na Vila Rosária, ofertas de imóveis de corretores e Imobiliárias na Vila Rosária - SP';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Tatuapé') {
            this.title = 'Imobiliária no Tatuapé Guia Imóveis no Tatuapé.';
            description = 'Imobiliária no Tatuapé - Lista de Imóveis no Tatuapé, ofertas de imóveis de corretores e Imobiliárias no Tatuapé - SP';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'Itaquera') {
            this.title = 'Imobiliária em Itaquera, na Zona Leste de, SP - Alto Pedroso Imóveis.·.';
            description = 'Imobiliária em Itaquera, na Zona Leste de, SP - Alto Pedroso Imóveis - Apartamentos, Casas, Sobrados e Comércio. Quer Comprar, Alugar ou Anunciar?.·.';
            this.isCustom = true;
        }
        if (this.location.neighborhood === 'São Miguel Paulista') {
            this.title = 'Imobiliária em São Miguel Paulista, São Paulo, Zona Leste, SP - Acesse:';
            description = 'Imobiliária em São Miguel Paulista, São Paulo, Zona Leste, SP - Apartamentos, Casas, Sobrados e Imóveis Comerciais. Empresa melhor avaliada do bairro.·.';
            this.isCustom = true;
        }
        this.description = description;
        var url = this.$rootScope.URL + '/' + data.url;
        this.seoManager.setTitle(this.title);
        this.seoManager.setUrl(url);
        this.seoManager.setDescription(description);
        this.started = true;
    };
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    RealEstateNeighborhoodPageController.prototype.sharedEmail = function () {
        var location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);
        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    };
    RealEstateNeighborhoodPageController.$inject = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];
    return RealEstateNeighborhoodPageController;
}());
angular
    .module('app')
    .controller('RealEstateNeighborhoodPageController', RealEstateNeighborhoodPageController);
var RootController = /** @class */ (function () {
    function RootController($rootScope, $window, 
    // realestate,
    // info,
    seoManager) {
        // $rootScope.realestate = realestate.data.body;
        // $rootScope.info = info.data.body;
        this.settings = window['settings'];
        $rootScope.realestate.full_address = '';
        if ($rootScope.realestate.address)
            $rootScope.realestate.full_address += $rootScope.realestate.address + ' ';
        if ($rootScope.realestate.number)
            $rootScope.realestate.full_address += $rootScope.realestate.number + ' - ';
        if ($rootScope.realestate.neighborhood)
            $rootScope.realestate.full_address += $rootScope.realestate.neighborhood + ',';
        if ($rootScope.realestate.city)
            $rootScope.realestate.full_address += $rootScope.realestate.city + ' - ';
        if ($rootScope.realestate.estate)
            $rootScope.realestate.full_address += $rootScope.realestate.estate + ' - ';
        if ($rootScope.realestate.cep)
            $rootScope.realestate.full_address += $rootScope.realestate.cep + '';
        if ($rootScope.realestate.id == 319) {
            $rootScope.anunciar = true;
        }
        if ($rootScope.realestate.id == 73) {
            $rootScope.realestate.manyUnits = [
                {
                    name: 'Unidade 1',
                    full_address: 'Rua Severina Leopoldina de Souza, 220 - CEP 08010-310 (Atrás do Mercado Municipal de São Miguel Paulista)'
                },
                {
                    name: 'Unidade 2',
                    full_address: 'Rua Serra de Botucatú, 1940 - CEP 03317-001 (Próx. Quinta do Espeto)'
                }
            ];
        }
        $rootScope.realestate.settings.COLOR_PRIMARY = '';
        $rootScope.realestate.settings.COLOR_SECUNDARY = '';
        if ($rootScope.realestate.settings.COLOR_PRIMARY == '' || !$rootScope.realestate.settings.COLOR_PRIMARY) {
            $rootScope.realestate.settings.COLOR_PRIMARY = '#000000';
        }
        if ($rootScope.realestate.settings.COLOR_SECUNDARY == '' || !$rootScope.realestate.settings.COLOR_SECUNDARY) {
            $rootScope.realestate.settings.COLOR_SECUNDARY = '#074a96';
        }
        if ($rootScope.realestate.id == '893') {
            $rootScope.realestate.settings.COLOR_PRIMARY = '#eeeeee';
            $rootScope.realestate.settings.COLOR_SECUNDARY = '#F12858';
        }
        $rootScope.realestate.settings.FONT_COLOR_PRIMARY = Helpers.colorTextOverColor(Helpers.hexToRgb($rootScope.realestate.settings.COLOR_PRIMARY));
        $rootScope.realestate.settings.FONT_COLOR_SECUNDARY = Helpers.colorTextOverColor(Helpers.hexToRgb($rootScope.realestate.settings.COLOR_SECUNDARY));
        if ($rootScope.realestate.settings.COLOR_PRIMARY.indexOf('#') === -1) {
            $rootScope.realestate.settings.COLOR_PRIMARY = '#' + $rootScope.realestate.settings.COLOR_PRIMARY;
        }
        if ($rootScope.realestate.settings.COLOR_SECUNDARY.indexOf('#') === -1) {
            $rootScope.realestate.settings.COLOR_SECUNDARY = '#' + $rootScope.realestate.settings.COLOR_SECUNDARY;
        }
        var typesSearch = [];
        for (var i in $rootScope.info.properties.types) {
            var item = $rootScope.info.properties.types[i];
            typesSearch.push({
                id: item.id,
                value: item.name.toLowerCase(),
                name: item.name
            });
            if (item.name == 'Sobrado') {
                typesSearch.push({
                    id: item.id,
                    value: 'casa,sobrado',
                    name: 'Casa/Sobrado'
                });
            }
        }
        $rootScope.info.properties.typesSearch = typesSearch;
        seoManager.setTitle($rootScope.realestate.settings.SEO_TITLE);
        seoManager.setDescription($rootScope.realestate.settings.SEO_DESCRIPTION);
        seoManager.setKeywords($rootScope.realestate.settings.SEO_KEYWORDS);
        seoManager.setUrl(window.location.href);
        var link = angular.element('<link>');
        link.attr('rel', 'canonical');
        link.attr('href', window.location.href.replace('www.', ''));
        angular.element('head').append(link);
    }
    RootController.$inject = [
        '$rootScope',
        '$window',
        /*'realestate',
        'info',*/
        'seoManager'
    ];
    return RootController;
}());
angular
    .module('app')
    .controller('RootController', RootController);
var SearchAdvancedController = /** @class */ (function () {
    function SearchAdvancedController($state, $scope, $rootScope, $document, seoManager) {
        var _this = this;
        this.settings = window['settings'];
        this.finality = {};
        this.filters = {};
        this.last_filters = {};
        this.loading = false;
        this.result_count = 0;
        this.maxSize = 10;
        this.numPages = 10;
        this.pageDisabled = false;
        this.mobile = false;
        this.error = false;
        this.correct_url = '';
        this.subtypes = [];
        this.types = [];
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.seoManager = seoManager;
        seoManager.setTitle('Busca Avançado | ' + $rootScope.realestate.name);
        seoManager.setTitleH1('Busca Avançado');
        seoManager.setUrl('/avancado', true);
        this.finality = 'sale';
        $rootScope.localizacoes = [];
        //Localizações
        $scope.$watch(function () { return $rootScope.localizacoes.length; }, function (newvalue, oldvalue) {
            if (newvalue != oldvalue) {
                var location_1 = [];
                for (var i in $rootScope.localizacoes) {
                    if ($rootScope.localizacoes[i].neighborhood) {
                        location_1.push($rootScope.localizacoes[i].estate + ',' + $rootScope.localizacoes[i].city + ',' + $rootScope.localizacoes[i].neighborhood);
                    }
                    if (!$rootScope.localizacoes[i].neighborhood) {
                        location_1.push($rootScope.localizacoes[i].estate + ',' + $rootScope.localizacoes[i].city);
                    }
                }
                _this.filters.location = Helpers.convertStringUrl(location_1.join('_'));
            }
        });
    }
    SearchAdvancedController.prototype.addType = function (type) {
        type = type.name.toLowerCase();
        if (!this.types) {
            this.types = [];
        }
        if (this.types.indexOf(type) == -1) {
            this.types.push(type);
        }
        else {
            this.types.splice(this.types.indexOf(type), 1);
        }
        this.filters["type_name"] = this.types.join(",");
    };
    SearchAdvancedController.prototype.setComp = function (comp, value) {
        console.log(comp);
        if (this.filters[comp] == value) {
            this.filters[comp] = null;
        }
        else {
            this.filters[comp] = value;
        }
    };
    SearchAdvancedController.prototype.createURL = function () {
        this.filters['finality'] = this.finality;
        var correct_url = "";
        correct_url += (this.finality == 'sale' ? 'venda' : 'locacao') + "/";
        correct_url += (this.filters["location"]) ? "l-" + this.filters["location"] + "/" : "";
        correct_url += (this.filters["type_name"]) ? "t-" + this.filters["type_name"] + "/" : "";
        correct_url += (this.filters["subtype"]) ? "sub-" + this.filters["subtype"] + "/" : "";
        correct_url += (this.filters["bedrooms"]) ? "dorms-" + this.filters["bedrooms"] + "/" : "";
        correct_url += (this.filters["suites"]) ? "suites-" + this.filters["suites"] + "/" : "";
        correct_url += (this.filters["vacancies"]) ? "vagas-" + this.filters["vacancies"] + "/" : "";
        correct_url += (this.filters["bathrooms"]) ? "banheiros-" + this.filters["bathrooms"] + "/" : "";
        correct_url += (this.filters["area_gte"]) ? "area-min-" + this.filters["area_gte"] + "/" : "";
        correct_url += (this.filters["area_lte"]) ? "area-max-" + this.filters["area_lte"] + "/" : "";
        correct_url += (this.filters["price_gte"]) ? "preco-min-" + this.filters["price_gte"] + "/" : "";
        correct_url += (this.filters["price_lte"]) ? "preco-max-" + this.filters["price_lte"] + "/" : "";
        correct_url = this.settings.URL + '/' + correct_url;
        correct_url = correct_url.replace(/ /g, '-');
        if (correct_url.substring(correct_url.length - 1) == '/') {
            correct_url = correct_url.substring(0, correct_url.length - 1);
        }
        history.pushState(null, "", correct_url);
    };
    SearchAdvancedController.$inject = [
        '$state',
        '$scope',
        '$rootScope',
        '$document',
        'seoManager',
    ];
    return SearchAdvancedController;
}());
angular
    .module('app')
    .controller('SearchAdvancedController', SearchAdvancedController);
var SearchLocationController = /** @class */ (function () {
    function SearchLocationController($scope, $timeout, $document, $state, $rootScope, locationService) {
        this.settings = window['settings'];
        this.processing = false;
        this.finality = 'venda';
        this.type = 'apartamento';
        this.cod_search = false;
        this.neighborhood = [];
        this.city = [];
        this.callSearch = null;
        this.open = false;
        this.stay = false;
        this.selectSearch = 0;
        this.locationNotFound = false;
        this.focus = false;
        this.locationService = locationService;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$document = $document;
        this.$state = $state;
    }
    SearchLocationController.prototype.$onInit = function () {
        var that = this;
        this.$scope.$watch('$root.text_search', function (newvalue, oldvalue) {
            if (oldvalue == newvalue || !oldvalue || !newvalue)
                return;
            that.$timeout.cancel(that.callSearch);
            that.callSearch = null;
            that.callSearch = that.$timeout(function () {
                that.locationNotFound = false;
                that.locationService
                    .create({
                    search: newvalue
                })
                    .then(function (body) {
                    that.open = true;
                    if (that.$rootScope.text_search == '') {
                        that.open = false;
                    }
                    that.neighborhood = [];
                    that.city = [];
                    that.selectSearch = 0;
                    that.maxvalues = body.neighborhood.length + body.city.length;
                    var count = 0;
                    for (var i in body.neighborhood) {
                        var location = body.neighborhood[i];
                        // Se localização já estiver sido adicionado, não adiciona novamente
                        for (var x in that.$rootScope.localizacoes) {
                            if (that.$rootScope.localizacoes[x].estate === location.estate &&
                                that.$rootScope.localizacoes[x].city === location.city &&
                                that.$rootScope.localizacoes[x].neighborhood === location.neighborhood) {
                                continue;
                            }
                        }
                        location.index = count++;
                        var pos_neighborhood = Helpers.normalizeString(location.neighborhood).indexOf(Helpers.normalizeString(that.$rootScope.text_search));
                        location.neighborhood_html = location.neighborhood.substring(0, pos_neighborhood) +
                            '<b>' + location.neighborhood.substring(pos_neighborhood, pos_neighborhood + Helpers.normalizeString(that.$rootScope.text_search).length) + '</b>'
                            + location.neighborhood.substring(Helpers.normalizeString(that.$rootScope.text_search).length + pos_neighborhood);
                        that.neighborhood.push(location);
                    }
                    for (var i in body.city) {
                        var location = body.city[i];
                        // Se localização já estiver sido adicionado, não adiciona novamente
                        for (var x in that.$rootScope.localizacoes) {
                            if (that.$rootScope.localizacoes[x].estate === location.estate &&
                                that.$rootScope.localizacoes[x].city === location.city) {
                                continue;
                            }
                        }
                        location.index = count++;
                        var pos_city = Helpers.normalizeString(location.city).indexOf(Helpers.normalizeString(that.$rootScope.text_search));
                        location.city_html = location.city.substring(0, pos_city) +
                            '<b>' + location.city.substring(pos_city, pos_city + Helpers.normalizeString(that.$rootScope.text_search).length) + '</b>'
                            + location.city.substring(Helpers.normalizeString(that.$rootScope.text_search).length + pos_city);
                        that.city.push(location);
                    }
                    if (!that.neighborhood.length && !that.city.length) {
                        that.locationNotFound = true;
                    }
                });
            }, 200);
        });
        that.$document.on('keydown', function (e) {
            if (e.which == 40) { //DOWN
                that.selectSearch++;
                if (that.selectSearch >= that.maxvalues)
                    that.selectSearch = 0;
            }
            if (e.which == 38) { //UP
                that.selectSearch--;
                if (0 > that.selectSearch)
                    that.selectSearch = that.maxvalues - 1;
            }
            if (e.which == 13) { //Enter
                // that.search();
            }
            that.$scope.$apply();
        });
    };
    /**
     * Define localização
     * @param location
     */
    SearchLocationController.prototype.setLocation = function (location) {
        this.location = location;
        this.addLocation(location);
        this.open = false;
        if (this.stay)
            this.$rootScope.text_search = '';
        this.createUrl();
    };
    /**
     * Remover localização das tags
     * @param index
     */
    SearchLocationController.prototype.removeLocation = function (index) {
        this.$rootScope.localizacoes.splice(index, 1);
        console.log("this.$rootScope.localizacoes", this.$rootScope.localizacoes);
    };
    /**
     * Buscar Localização
     */
    SearchLocationController.prototype.search = function () {
        for (var i in this.neighborhood) {
            if (this.neighborhood[i].index == this.selectSearch) {
                this.setLocation(this.neighborhood[i]);
                return false;
            }
        }
        for (var i in this.city) {
            if (this.city[i].index == this.selectSearch) {
                this.setLocation(this.city[i]);
                return false;
            }
        }
        this.createUrl();
    };
    SearchLocationController.prototype.delayFocus = function () {
        var _this = this;
        this.$timeout(function () {
            _this.focus = false;
        }, 100);
    };
    /**
     * Adicionar localização nas tags
     * @param location
     * @returns {boolean}
     */
    SearchLocationController.prototype.addLocation = function (location) {
        if (!this.$rootScope.localizacoes)
            this.$rootScope.localizacoes = [];
        for (var i in this.$rootScope.localizacoes) {
            if (this.$rootScope.localizacoes[i].estate === location.estate &&
                this.$rootScope.localizacoes[i].city === location.city &&
                this.$rootScope.localizacoes[i].neighborhood === location.neighborhood) {
                return false;
            }
        }
        this.$rootScope.localizacoes.push(location);
    };
    /**
     * Cria URL para busca
     */
    SearchLocationController.prototype.createUrl = function () {
        var url = this.settings['URL'];
        url += "/" + this.finality;
        url += "/t-" + this.type + "";
        if (this.location) {
            if (!this.location.neighborhood) {
                url += "/l-" + this.location.estate + "," + this.location.city + "/";
            }
            else {
                url += "/l-" + this.location.estate + "," + this.location.city + "," + this.location.neighborhood + "/";
            }
        }
        else {
            if (this.$rootScope.text_search) {
                url += "/x-" + this.$rootScope.text_search;
            }
        }
        if (!this.stay) {
            // window.location = Helpers.convertStringUrl(url);
            history.pushState(null, "", Helpers.convertStringUrl(url));
        }
        console.log('asd');
    };
    /**
     * Muda tipo de busca
     * Por código ou textual
     */
    SearchLocationController.prototype.changeSearch = function () {
        this.cod_search = !this.cod_search;
    };
    /**
     * Buscar pelo Código
     */
    SearchLocationController.prototype.searchCod = function () {
        this.$state.go('root.propertyViewOld2', {
            cod: this.$rootScope.cod
        });
    };
    SearchLocationController.$inject = [
        '$scope',
        '$timeout',
        '$document',
        '$state',
        '$rootScope',
        'locationService'
    ];
    return SearchLocationController;
}());
angular
    .module('app')
    .controller('SearchLocationController', SearchLocationController);
var ShareEmailModalController = /** @class */ (function () {
    function ShareEmailModalController($rootScope, formsService) {
        this.settings = window['settings'];
        this.validate = true;
        this.success = false;
        this.failed = false;
        this.processing = false;
        this.d = {};
        this.formsService = formsService;
        this.$rootScope = $rootScope;
    }
    ShareEmailModalController.prototype.save = function () {
        var that = this;
        that.$rootScope.loadingPage = true;
        this.d.target = that.$rootScope.target_email;
        this.d.type = that.$rootScope.type_email;
        return this.formsService
            .update('shared_email', this.d)
            .then(function (response) {
            that.$rootScope.loadingPage = false;
            that.processing = false;
            that.success = true;
        }, function (err) {
            that.processing = false;
            that.failed = true;
            that.$rootScope.loadingPage = false;
        });
    };
    ShareEmailModalController.$inject = [
        '$rootScope',
        'formsService',
    ];
    return ShareEmailModalController;
}());
angular
    .module('app')
    .controller('ShareEmailModalController', ShareEmailModalController);
var SiteMapController = /** @class */ (function () {
    function SiteMapController($rootScope, locationData, seoManager) {
        this.settings = window['settings'];
        this.locations = locationData.data.body;
        seoManager.setTitle('Mapa do Site | ' + $rootScope.realestate.name);
        seoManager.setTitleH1('Mapa do Site');
        seoManager.setUrl('/mapa-do-site', true);
    }
    SiteMapController.$inject = [
        '$rootScope',
        'locationData',
        'seoManager',
    ];
    return SiteMapController;
}());
angular
    .module('app')
    .controller('SiteMapController', SiteMapController);
(function () {
    angular.module("app")
        .controller('WhatsappConversation', [
        '$scope',
        '$rootScope',
        function ($scope, $rootScope) {
            var self = this;
            self.close = true;
            self.send_true = false;
            self.send = function () {
                if (self.whatsapp.$invalid) {
                    return;
                }
                if ($rootScope.realestate.id === 1) {
                    self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=' + self.message;
                }
                else {
                    self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                }
                if ($rootScope.realestate.id === 7) {
                    if (self.message) {
                        self.link = 'https://api.whatsapp.com/send?phone=5511956052600&text=Me chamo ' + self.d.name + self.message;
                    }
                    else {
                        self.link = 'https://api.whatsapp.com/send?phone=5511956052600&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                    }
                }
                if ($rootScope.realestate.id === 297) {
                    if (self.message) {
                        self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=Me chamo ' + self.d.name + ' ' + self.message;
                    }
                    else {
                        self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                    }
                    var bn = 2;
                    console.log(bn);
                }
                if ($rootScope.realestate.id === 893) {
                    if (self.message) {
                        self.link = 'https://api.whatsapp.com/send?phone=5511987477000&text=Me chamo ' + self.d.name + ' ' + self.message;
                    }
                    else {
                        self.link = 'https://api.whatsapp.com/send?phone=5511987477000&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                    }
                    var bn = 2;
                    console.log(bn);
                }
                if ($rootScope.realestate.id === 12) {
                    if (self.message) {
                        self.link = 'https://api.whatsapp.com/send?phone=5511993157017&text=' + self.message;
                    }
                    else {
                        self.link = 'https://api.whatsapp.com/send?phone=5511993157017&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                    }
                }
                if ($rootScope.realestate.id === 714) {
                    if (self.message) {
                        self.link = 'https://api.whatsapp.com/send?phone=5511985057761&text=' + self.message;
                    }
                    else {
                        self.link = 'https://api.whatsapp.com/send?phone=5511985057761&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                    }
                }
                if ($rootScope.realestate.id === 798) {
                    if (self.message) {
                        self.link = 'https://api.whatsapp.com/send?phone=5511987854939&text=' + self.message;
                    }
                    else {
                        self.link = 'https://api.whatsapp.com/send?phone=5511987854939&text=Me chamo ' + self.d.name + ', preciso de algumas informações.';
                    }
                }
                // self.link = 'https://api.whatsapp.com/send?phone='+self.whatsphone+'&text=Me chamo'+self.d.name+'. '+self.message;
                window.open(self.link, '_blank');
                // $restfull.post(plataform.url_api+"/forms/moreinfo", {
                // 		params: {
                // 			token: plataform.token
                // 		},
                // 		body: self.d
                // 	},
                // 	function(response){
                // 		self.send_true = true;
                // 	}, function(){
                // 	}
                // );
            };
        }
    ]);
})();
var ZonalesteController = /** @class */ (function () {
    function ZonalesteController($rootScope, seoManager) {
        this.settings = window['settings'];
        seoManager.setTitle('Imobiliária na Zona Leste - Todos os Bairros e Imóveis na Zona Leste.·.');
        seoManager.setTitleH1('Imobiliária na Zona Leste - Todos os Bairros e Imóveis na Zona Leste.·.');
        seoManager.setUrl('/imobiliaria-na-zona-leste', true);
        seoManager.setDescription('Imobiliária na Zona Leste - Guia de Bairros e Imóveis na Zona Leste - SP, Apartamentos, Casas, Sobrados, Comércios, Locação, Venda, Milhares de Imóveis');
    }
    ZonalesteController.$inject = [
        '$rootScope',
        'seoManager',
    ];
    return ZonalesteController;
}());
angular
    .module('app')
    .controller('ZonalesteController', ZonalesteController);
var Properties = /** @class */ (function () {
    function Properties() {
    }
    return Properties;
}());
var ClickOutside = /** @class */ (function () {
    function ClickOutside() {
        this.settings = window['settings'];
        this.scope = {
            'clickOutside': '&'
        };
    }
    ClickOutside.prototype.link = function (scope, element, attrs, controller, transclude) {
        element.bind('click', function (event) {
            event.stopPropagation();
        });
        angular.element(document).on('click', function () {
            scope.isVisible = false;
            scope.$apply(function () {
                scope.$eval(attrs.clickOutside);
            });
        });
        angular.element(document).on('keydown', function (e) {
            if (e.which == 27) { //Esc
                scope.$apply(function () {
                    scope.$eval(attrs.clickOutside);
                });
            }
        });
    };
    ClickOutside.factory = function () {
        return function () { return new ClickOutside(); };
    };
    ClickOutside.$inject = [];
    return ClickOutside;
}());
angular
    .module('app')
    .directive('clickOutside', ClickOutside.factory());
var FavoriteButton = /** @class */ (function () {
    function FavoriteButton($cookies) {
        this.settings = window['settings'];
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            ngModel: '=',
            ngId: '<',
            ngHash: '@'
        };
        this.$cookies = $cookies;
    }
    FavoriteButton.prototype.link = function (scope, element, attrs, ctrl) {
        scope.ngModel = false;
        if (typeof this.$cookies.getObject('fav_' + this.settings['TOKEN']) !== 'undefined') {
            var fv = this.$cookies.getObject('fav_' + this.settings['TOKEN']);
            if (fv.indexOf(Number(scope.ngId)) !== -1) {
                scope.ngModel = true;
            }
        }
        var that = this;
        element.bind('click', function (e) {
            e.preventDefault(); // prevent button default behavior
            e.stopPropagation(); // prevent propagation of your event to the link
            if (typeof that.$cookies.getObject('fav_' + that.settings['TOKEN']) === 'undefined') {
                var fv = [];
                fv.push(scope.ngId);
                scope.ngModel = true;
                that.$cookies.putObject('fav_' + that.settings['TOKEN'], fv);
            }
            else {
                var fv = that.$cookies.getObject('fav_' + that.settings['TOKEN']);
                if (fv.indexOf(scope.ngId) === -1) {
                    fv.push(scope.ngId);
                    scope.ngModel = true;
                }
                else {
                    fv.splice(fv.indexOf(scope.ngId), 1);
                    scope.ngModel = false;
                }
                that.$cookies.putObject('fav_' + that.settings['TOKEN'], fv);
            }
            scope.$apply();
        });
    };
    FavoriteButton.factory = function () {
        return [
            '$cookies',
            function ($cookies) { return new FavoriteButton($cookies); }
        ];
    };
    FavoriteButton.$inject = ['$cookies'];
    return FavoriteButton;
}());
angular
    .module('app')
    .directive('favButton', FavoriteButton.factory());
var FormsManager = /** @class */ (function () {
    function FormsManager($http, modalManager) {
        this.settings = window['settings'];
        this.scope = {};
        this.bindToController = {
            'noModalSuccess': '<?',
            'formPropertyId': '<',
            'actionSuccess': '&?',
            'formName': '@',
            'update': '=?',
            'ngInit': '@?',
        };
        this.transclude = true;
        this.template = '';
        this.controllerAs = 'formsManager';
        this.controller = [
            '$scope',
            '$rootScope',
            '$timeout',
            'formsService',
            'ModalManager',
            this.controllerF
        ];
        this.http = $http;
        this.modalManager = modalManager;
    }
    FormsManager.prototype.link = function (scope, element, attrs, controller, transclude) {
        var boundObj = {};
        var alias = attrs.alias;
        var newScope = scope.$new(scope.controller); // isolate
        newScope[alias] = boundObj;
        transclude(newScope, function (clone) {
            element.append(clone);
        });
        ///
        scope.log = function () {
            console.log(boundObj);
        };
    };
    FormsManager.prototype.controllerF = function ($scope, $rootScope, $timeout, formsService, modalManager) {
        var vm = this;
        vm.processing = false;
        vm.form = {};
        vm.validate = false;
        vm.d = {};
        // vm.modalManager.openFailed();
        console.log(vm.modalManager);
        vm.addPhone = function () {
            vm.d.phones.push({});
        };
        vm.removePhone = function (index) {
            vm.d.phones.splice(index, 1);
        };
        vm.toggleCheckbox = function (key, value) {
            if (!vm.d[key]) {
                vm.d[key] = [];
            }
            if (vm.d[key].indexOf(value) == -1) {
                vm.d[key].push(value);
            }
            else {
                vm.d[key].splice(vm.d[key].indexOf(value), 1);
            }
        };
        vm.submit = function () {
            console.log(vm.form.$invalid);
            if (vm.form.$invalid) {
                vm.validate = true;
                return false;
            }
            vm.validate = false;
            // Não executar a acão diversas vezes
            if (vm.processing)
                return;
            vm.processing = true;
            $rootScope.loadingPage = true;
            var that = this;
            return formsService
                .update(vm.formName, vm.d)
                .then(function (response) {
                console.log(response);
                $rootScope.loadingPage = false;
                vm.processing = false;
                if (response.data.success) {
                    console.log(vm.noModalSuccess);
                    if (!vm.noModalSuccess) {
                        modalManager.openSuccess();
                    }
                    if (!!vm.actionSuccess) {
                        $scope.$eval(vm.actionSuccess);
                    }
                    vm.success = true;
                }
                else {
                    if (!!vm.actionSuccess) {
                        modalManager.openFailed();
                    }
                }
            }, function (err) {
                vm.processing = false;
                $rootScope.loadingPage = false;
                modalManager.openFailed();
            });
        };
        vm.$onInit = function () {
            vm.d.property_id = vm.formPropertyId;
            vm.update = function (data) {
                for (var i in data) {
                    vm[i] = data[i];
                }
            };
            if (vm.ngInit) {
                $timeout(function () {
                    $scope.$apply(function () {
                        $scope.$eval(vm.ngInit);
                    });
                }, 1);
            }
        };
    };
    FormsManager.factory = function () {
        return [
            '$http',
            'ModalManager',
            function ($http, ModalManager) { return new FormsManager($http, ModalManager); }
        ];
    };
    FormsManager.$inject = ['$http', 'ModalManager'];
    return FormsManager;
}());
angular
    .module('app')
    .directive('formsManager', FormsManager.factory());
var InputCounter = /** @class */ (function () {
    function InputCounter($timeout) {
        this.settings = window['settings'];
        this.restrict = 'A';
        this.scope = {
            value: '=value',
        };
        this.template = '<div class="fs-counter input-group" ng-class="addclass" ng-style="width"><span class="input-group-btn" ng-click="minus()"><button type="button" class="btn">-</button></span><input type="text" class="form-control text-center" ng-model="value" ng-blur="blurred()" ng-change="changed()" ng-readonly="readonly"><span class="input-group-btn" ng-click="plus()"><button type="button" class="btn">+</button></span></div>';
        this.replace = true;
        this.$timeout = $timeout;
    }
    InputCounter.prototype.link = function (scope, element, attrs) {
        var that = this;
        var min = (angular.isUndefined(attrs.min) ? void 0 : parseInt(attrs.min)), max = (angular.isUndefined(attrs.max) ? void 0 : parseInt(attrs.max)), step = (angular.isUndefined(attrs.step) || parseInt(attrs.step) === 0 ? 1 : parseInt(attrs.step)), setValue, changeDelay;
        /**
         * Sets the value as an integer. If the value cannot be parsed,
         * i.e. returns NaN, then the min value or 0 will be used instead.
         */
        setValue = function (val) {
            var parsedVal = parseInt(val);
            if (!isNaN(parsedVal)) {
                if (min !== undefined && min > parsedVal) {
                    parsedVal = min;
                    return parsedVal;
                }
                if (max !== undefined && max < parsedVal) {
                    parsedVal = max;
                    return parsedVal;
                }
                return parsedVal;
            }
            else {
                console.log('parsedValue must parse to a number.');
                parsedVal = min || 0;
                return parsedVal;
            }
        };
        /**
         * Confirm the value attribute exists on the element
         */
        if (angular.isUndefined(scope.value)) {
            throw 'Missing the value attribute on the counter directive.';
        }
        /**
         * Set some scope wide properties
         */
        scope.readonly = (angular.isUndefined(attrs.editable) ? true : false);
        scope.addclass = (angular.isUndefined(attrs.addclass) ? null : attrs.addclass);
        scope.width = (angular.isUndefined(attrs.width) ? {} : { width: attrs.width });
        scope.value = setValue(scope.value);
        /**
         * Decrement the value and make sure we stay within the limits, if defined.
         */
        scope.minus = function () {
            scope.value = setValue(scope.value - step);
        };
        /**
         * Increment the value and make sure we stay within the limits, if defined.
         */
        scope.plus = function () {
            scope.value = setValue(scope.value + step);
        };
        /**
         * This is only triggered 1 second after a field is manually edited
         * by the user. Where we can perform some validation and make sure
         * that they enter the correct values from within the restrictions.
         */
        scope.changed = function () {
            changeDelay = that.$timeout(function () {
                scope.value = setValue(scope.value);
            }, 1000, true);
        };
        /**
         * This is only triggered when user leaves a manually edited field.
         * Where we can perform some validation and make sure that they
         * enter the correct values from within the restrictions.
         */
        scope.blurred = function () {
            scope.value = setValue(scope.value);
        };
    };
    InputCounter.factory = function () {
        return [
            '$timeout',
            function ($timeout) { return new InputCounter($timeout); }
        ];
    };
    InputCounter.$inject = ['$timeout'];
    return InputCounter;
}());
angular
    .module('app')
    .directive('fsCounter', InputCounter.factory());
var LazyLoad = /** @class */ (function () {
    function LazyLoad() {
        this.restrict = 'A';
    }
    LazyLoad.prototype.link = function (scope, element, attrs) {
        var observer = new IntersectionObserver(loadImg);
        var img = angular.element(element)[0];
        observer.observe(img);
        var that = this;
        function loadImg(changes) {
            changes.forEach(function (change) {
                if (change.intersectionRatio > 0) {
                    observer.unobserve(change.target);
                    change.target.src = attrs.lazyLoad;
                    change.target.classList.add('lazy-image--handled');
                    change.target.classList.remove('lazy-load');
                    change.target.parentElement.classList.add('lazy-image--handled');
                    change.target.parentElement.classList.remove('lazy-load');
                }
            });
        }
    };
    LazyLoad.factory = function () {
        return function () { return new LazyLoad(); };
    };
    LazyLoad.$inject = [];
    return LazyLoad;
}());
angular
    .module('app')
    .directive('lazyLoad', LazyLoad.factory());
var MaskCEP = /** @class */ (function () {
    function MaskCEP($timeout) {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            ngModel: '='
        };
        this.$timeout = $timeout;
    }
    MaskCEP.prototype.link = function (scope, element, attrs, ctrl) {
        var that = this;
        element.bind('input', function () {
            var value = element.val();
            if (value.length > 8) {
                value = value.replace(/\D/g, "").substr(0, 8);
            }
            var string = value.replace(/^(\d{5})(\d)/g, "$1-$2");
            element.val(string);
            console.log(string);
        });
        ctrl.$parsers.unshift(function (viewValue) {
            var value = viewValue.replace(/\D/g, '');
            if (value.length > 8) {
                value = value.replace(/\D/g, "").substr(0, 8);
            }
            var string = value.replace(/^(\d{5})(\d)/g, "$1-$2");
            element.val(string);
            if (value.length != 0) {
                ctrl.$setValidity("cep", false);
                if (value.length == 8) {
                    ctrl.$setValidity("cep", true);
                    return value;
                }
                return undefined;
            }
            else {
                ctrl.$setValidity("cep", true);
                return undefined;
            }
        });
        // $(element).mask('00000-000');
    };
    MaskCEP.factory = function () {
        return [
            '$timeout',
            function ($timeout) { return new MaskCEP($timeout); }
        ];
    };
    MaskCEP.$inject = [];
    return MaskCEP;
}());
angular
    .module('app')
    .directive('maskCep', MaskCEP.factory());
var MaskEmail = /** @class */ (function () {
    function MaskEmail() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            ngModel: '='
        };
    }
    MaskEmail.prototype.link = function (scope, element, attrs, ctrl) {
        ctrl.$parsers.unshift(function (viewValue) {
            console.log("viewValue", viewValue);
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (viewValue.length != 0) {
                ctrl.$setValidity("email_invalid", false);
                if (re.test(String(viewValue).toLowerCase())) {
                    ctrl.$setValidity("email_invalid", true);
                    return viewValue;
                }
                return undefined;
            }
            else {
                ctrl.$setValidity("email_invalid", true);
                return viewValue;
            }
        });
    };
    MaskEmail.factory = function () {
        return function () { return new MaskEmail(); };
    };
    MaskEmail.$inject = [];
    return MaskEmail;
}());
angular
    .module('app')
    .directive('maskEmail', MaskEmail.factory());
var MaskPhone = /** @class */ (function () {
    function MaskPhone() {
        this.restrict = 'A';
        this.require = 'ngModel';
        this.scope = {
            ngModel: '='
        };
    }
    MaskPhone.prototype.link = function (scope, element, attrs, ctrl) {
        ctrl.$parsers.unshift(function (viewValue) {
            if (viewValue.length > 11) {
                viewValue = viewValue.replace(/\D/g, "").substr(0, 11);
            }
            var string = viewValue.replace(/\D/g, "");
            string = string.replace(/^(\d{2})(\d)/g, "($1) $2");
            string = string.replace(/(\d)(\d{4})$/, "$1-$2");
            element.val(string);
            var value = viewValue.replace(/\D/g, '');
            if (value.length != 0) {
                ctrl.$setValidity("phone_invalid", false);
                if (value.length >= 10) {
                    ctrl.$setValidity("phone_invalid", true);
                    return value;
                }
                return undefined;
            }
            else {
                ctrl.$setValidity("phone_invalid", true);
                return value;
            }
        });
    };
    MaskPhone.factory = function () {
        return function () { return new MaskPhone(); };
    };
    MaskPhone.$inject = [];
    return MaskPhone;
}());
angular
    .module('app')
    .directive('maskPhone', MaskPhone.factory());
var Pagination = /** @class */ (function () {
    function Pagination($http) {
        this.settings = window['settings'];
        this.last = true;
        this.next = true;
        this.scope = {
            total: '=',
            perPage: '=',
            currentPage: '=',
            changePage: '&'
        };
        this.template = "\n        <div class=\"pagination\" ng-if=\"vm.total_pages > 1\">\n            <div\n                ng-click=\"vm.incrementPage(currentPage - 1)\"\n                ng-class=\"{disabled: !vm.last}\"\n                class=\"page\">\n                P\u00E1gina Anterior\n            </div>\n            <div ng-repeat=\"page in vm.pages\"\n                 class=\"page number\"\n                 ng-click=\"vm.setPage(page)\"\n                 ng-class=\"{\n                    disable: page.disable,\n                    active: page.active,\n                 }\">\n                {{page.number}}\n            </div>\n            <div\n                ng-click=\"vm.incrementPage(currentPage + 1)\"\n                ng-class=\"{disabled: !vm.next}\"\n                class=\"page\">\n                Pr\u00F3xima P\u00E1gina\n            </div>\n        </div>\n    ";
        this.controllerAs = 'vm';
        this.http = $http;
    }
    Pagination.prototype.controller = function ($scope, $rootScope, $timeout, formsService) {
        var vm = this;
        vm.incrementPage = function (number) {
            $scope.currentPage = number;
            vm.total_pages = parseInt(String($scope.total / $scope.perPage + 0.9999), 10);
            if ($scope.currentPage < 1) {
                $scope.currentPage = 1;
            }
            if ($scope.currentPage > vm.total_pages) {
                $scope.currentPage = vm.total_pages;
            }
            console.log($scope.currentPage);
            $scope.changePage({ page: $scope.currentPage });
            vm.configurePage();
        };
        vm.setPage = function (page) {
            $scope.currentPage = page.number;
            $scope.changePage({ page: page.number });
            vm.configurePage();
        };
        vm.configurePage = function () {
            vm.total_pages = parseInt(String($scope.total / $scope.perPage + 0.9999), 10);
            if ($scope.total === $scope.perPage) {
                vm.total_pages = 1;
            }
            var start = $scope.currentPage - 3;
            var end = $scope.currentPage + 2;
            if ($scope.currentPage <= 4) {
                start = 0;
                end = 5;
            }
            else {
                if (vm.total_pages - $scope.currentPage <= 3) {
                    start = vm.total_pages - 5;
                    end = vm.total_pages;
                }
            }
            if (0 > start) {
                start = 0;
            }
            if (end > vm.total_pages) {
                end = vm.total_pages;
            }
            vm.pages = [];
            /**
             * Adiciona pagina inicial
             */
            if (start > 1) {
                vm.pages.push({
                    number: 1,
                });
                vm.pages.push({
                    disable: true,
                    number: '...',
                });
            }
            for (var i = start; i < end; i++) {
                vm.pages.push({
                    active: (i + 1 === $scope.currentPage),
                    number: i + 1,
                });
            }
            /**
             * Adiciona pagina final
             */
            if (vm.total_pages - end >= 2) {
                vm.pages.push({
                    disable: true,
                    number: '...',
                });
                vm.pages.push({
                    number: vm.total_pages,
                });
            }
            vm.last = ($scope.currentPage !== 1);
            vm.next = ($scope.currentPage !== vm.total_pages);
        };
        vm.ngOnChanges = function () {
            vm.configurePage();
        };
        vm.configurePage();
    };
    Pagination.factory = function () {
        return [
            '$http',
            function ($http) { return new Pagination($http); }
        ];
    };
    Pagination.$inject = [
        '$http'
    ];
    return Pagination;
}());
angular
    .module('app')
    .directive('pagination', Pagination.factory());
var dngMax = /** @class */ (function () {
    function dngMax() {
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    dngMax.prototype.link = function (scope, elem, attr, ctrl) {
        scope.$watch(attr.dngMax, function () {
            ctrl.$setViewValue(ctrl.$viewValue);
        });
        var maxValidator = function (value) {
            var max = scope.$eval(attr.dngMax) || Infinity;
            if (!Helpers.isEmpty(value) && value > max) {
                ctrl.$setValidity('dngMax', false);
                return undefined;
            }
            else {
                ctrl.$setValidity('dngMax', true);
                return value;
            }
        };
        ctrl.$parsers.push(maxValidator);
        ctrl.$formatters.push(maxValidator);
    };
    dngMax.factory = function () {
        return function () { return new dngMax(); };
    };
    dngMax.$inject = [];
    return dngMax;
}());
angular
    .module('app')
    .directive('dngMax', dngMax.factory());
var dngMin = /** @class */ (function () {
    function dngMin() {
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    dngMin.prototype.link = function (scope, elem, attr, ctrl) {
        scope.$watch(attr.dngMin, function () {
            ctrl.$setViewValue(ctrl.$viewValue);
        });
        var minValidator = function (value) {
            var min = scope.$eval(attr.dngMin) || 0;
            if (!Helpers.isEmpty(value) && value < min) {
                ctrl.$setValidity('dngMin', false);
                return undefined;
            }
            else {
                ctrl.$setValidity('dngMin', true);
                return value;
            }
        };
        ctrl.$parsers.push(minValidator);
        ctrl.$formatters.push(minValidator);
    };
    dngMin.factory = function () {
        return function () { return new dngMin(); };
    };
    dngMin.$inject = [];
    return dngMin;
}());
angular
    .module('app')
    .directive('dngMin', dngMin.factory());
var ngEnter = /** @class */ (function () {
    function ngEnter() {
    }
    ngEnter.prototype.link = function (scope, elem, attrs, ctrl) {
        elem.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter, { 'event': event });
                });
                event.preventDefault();
            }
        });
    };
    ngEnter.factory = function () {
        return function () { return new ngEnter(); };
    };
    ngEnter.$inject = [];
    return ngEnter;
}());
angular
    .module('app')
    .directive('ngEnter', ngEnter.factory());
function Capitalize() {
    return function (input) {
        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    };
}
angular
    .module('app')
    .filter('capitalize', Capitalize);
function ConvertUrl() {
    return function (string) {
        var url = string.split('/');
        for (var i in url) {
            url[i] = Helpers.normalizeString(url[i]).replace(/ /g, '-');
        }
        return url.join('/');
    };
}
angular
    .module('app')
    .filter('convertUrl', ConvertUrl);
function ImageProperty() {
    return function (string, size) {
        if (size === void 0) { size = 'small'; }
        if (size == 'small') {
            string = string.replace('normal', size);
        }
        else {
            string = string.replace('small', size);
        }
        return string;
    };
}
angular
    .module('app')
    .filter('ImageProperty', ImageProperty);
function Normalize() {
    return function (string) {
        return Helpers.normalizeString(string);
    };
}
angular
    .module('app')
    .filter('normalize', Normalize);
function Phone() {
    return function (number) {
        if (isNaN(number)) {
            return number;
        }
        else {
            number = Helpers.getNumbers(number).toString();
            var string = number.replace(/^(\d{2})(\d)/g, "($1) $2");
            string = string.replace(/(\d)(\d{4})$/, "$1-$2");
            return string;
        }
    };
}
angular
    .module('app')
    .filter('phone', Phone);
function Price() {
    return function (number) {
        if (isNaN(number)) {
            return number;
        }
        else {
            number = number / 100.0;
            return Helpers.number_format(number);
        }
    };
}
angular
    .module('app')
    .filter('price', Price);
var CrudManager = /** @class */ (function () {
    function CrudManager($http, $rootScope) {
        this.settings = window['settings'];
        this.path = '';
        this.http = $http;
        this.$rootScope = $rootScope;
        this.API = this.settings.API;
    }
    /**
     * Resgatar recurso
     * @param {number} id
     * @returns {Observable<any>}
     */
    CrudManager.prototype.get = function (id) {
        return this.http
            .get(this.API + this.path + '/' + id)
            .then(function (response) {
            return response.data.body;
        });
    };
    /**
     * Criar recurso
     * @param body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.create = function (body) {
        return this.http({
            url: this.API + this.path,
            method: "POST",
            data: body
        })
            .then(function (response) {
            return response.data.body;
        });
    };
    /**
     * Atualizar recurso
     * @param {number} id
     * @param body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.update = function (id, body) {
        return this.http({
            url: this.API + this.path + '/' + id,
            method: "POST",
            data: body
        });
    };
    /**
     * Buscar recursos
     * @param body
     * @returns {Observable<any>}
     */
    CrudManager.prototype.search = function (body) {
        if (!body) {
            body = {};
        }
        var params = {};
        params['query'] = {};
        params['filters'] = body;
        if (body['sort']) {
            params['query']['sort'] = body.sort;
            params['filters']['sort'] = undefined;
        }
        if (body['limit']) {
            params['query']['limit'] = body.limit;
            params['filters']['limit'] = undefined;
        }
        params['query']['limit'] = 20;
        if (body['offset']) {
            params['query']['offset'] = body.offset;
            params['filters']['offset'] = undefined;
        }
        return this.http({
            url: this.API + this.path + '/search',
            method: "POST",
            data: params
        })
            .then(function (response) {
            return response.data.body;
        });
    };
    CrudManager.$inject = [
        '$http',
        '$rootScope',
    ];
    return CrudManager;
}());
angular
    .module('app')
    .service('CrudManager', __spreadArrays(CrudManager.$inject, [CrudManager]));
var _this = this;
console.error = function (error) {
    if (error.indexOf('Transition Rejection') === -1) {
        $.ajax({
            type: "POST",
            url: _this.settings['API_LOGS'] + '/logs',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                level: 500,
                target: 'realestate-' + window['settings']['TOKEN'],
                message: 'Exception error partners',
                channel: 'partners-website-exception',
                context: {
                    location: window.location.href,
                    body: error
                }
            })
        });
    }
    console.warn(error);
};
angular
    .module('app')
    .factory('$exceptionHandler', function ($log) {
    return function (exception, cause) {
        var body = {};
        body.exception = exception.toString();
        body.caused = cause;
        body.message = exception.message;
        body.fileName = exception.fileName;
        body.lineNumber = exception.lineNumber;
        body.stack = exception.stack;
        $.ajax({
            type: "POST",
            url: this.settings['API_LOGS'] + '/logs',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                level: 400,
                target: 'realestate-' + this.settings['TOKEN'],
                message: 'Exception error partners',
                channel: 'partners-website-exception',
                context: {
                    location: window.location.href,
                    body: body
                }
            })
        });
        $log.warn(exception, cause);
    };
});
function InterceptorRequest($q, $rootScope, $state, $location) {
    this.$inject = [
        '$q',
        '$rootScope',
        '$state',
        '$location'
    ];
    this.settings = window['settings'];
    this.request = function (config) {
        config.headers['Authorization'] = this.settings['TOKEN'];
        return config;
    };
    this.responseError = function (error) {
        if (error && error.data && error.data.code) {
            // $state.go('500', {}, {location: 'replace'})
        }
        if (error.status === 401 || error.status === 403) {
            //
        }
        // $.ajax({
        //     type: "POST",
        //     url: this.settings['API_LOGS'] + '/logs',
        //     contentType: "application/json",
        //     dataType: 'json',
        //     data: JSON.stringify({
        //         level: 400,
        //         target: 'realestate-' + this.settings['TOKEN'],
        //         message: 'HTTP error partners',
        //         channel: 'partners-website-http',
        //         context: {
        //             location: window.location.href,
        //             response: error.data,
        //             page: error.status,
        //             status: error.status,
        //             statusText: error.statusText,
        //             xhrStatus: error.xhrStatus,
        //             url: error.config.url,
        //             headers: error.config.headers,
        //             method: error.config.method,
        //             data_request: error.config.data,
        //         },
        //     })
        // });
        return $q.reject(error);
    };
    return this;
}
angular.module('app')
    .service('InterceptorRequest', InterceptorRequest);
var ModalManager = /** @class */ (function () {
    function ModalManager($rootScope) {
        this.settings = window['settings'];
        this.$rootScope = $rootScope;
    }
    /**
     * Abrir Modal de Erro
     * @param class_name
     */
    ModalManager.prototype.openFailed = function (class_name) {
        if (class_name === undefined || class_name === '') {
            class_name = 'default';
        }
        console.log(this.$rootScope);
        this.$rootScope.openModalFailed = {
            open: true,
            title: 'Ops, algo aconteceu!',
            body: 'Houve um erro ao executar estão ação, tente novamente mais tarde!'
        };
    };
    /**
     * Abrir Modal de Sucesso
     * @param class_name
     */
    ModalManager.prototype.openSuccess = function (class_name) {
        if (class_name === undefined || class_name === '') {
            class_name = 'default';
        }
        console.log(this.$rootScope);
        this.$rootScope.openModalSuccess = {
            open: true,
            title: 'Ops, algo aconteceu!',
            body: 'Houve um erro ao executar estão ação, tente novamente mais tarde!'
        };
    };
    ModalManager.$inject = ['$rootScope'];
    return ModalManager;
}());
angular
    .module('app')
    .service('ModalManager', __spreadArrays(ModalManager.$inject, [ModalManager]));
var SeoManager = /** @class */ (function () {
    function SeoManager(ngMeta, $rootScope) {
        this.settings = window['settings'];
        this.ngMeta = ngMeta;
        this.$rootScope = $rootScope;
    }
    SeoManager.prototype.setTitle = function (title) {
        this.ngMeta.setTitle(title);
    };
    SeoManager.prototype.setDescription = function (description) {
        this.ngMeta.setTag('description', description);
    };
    SeoManager.prototype.setImage = function (image) {
        this.ngMeta.setTag('image', image);
    };
    SeoManager.prototype.setKeywords = function (keywords) {
        this.ngMeta.setTag('keywords', keywords);
    };
    SeoManager.prototype.setUrl = function (url, addDomain) {
        if (addDomain) {
            this.ngMeta.setTag('url', ('https://' + window.location.hostname + url).replace('www.', ''));
        }
        else {
            this.ngMeta.setTag('url', url.replace('www.', ''));
        }
    };
    SeoManager.prototype.setUrl2 = function (url) {
        this.$rootScope.url2 = 'url2';
    };
    SeoManager.prototype.setTitleH1 = function (title) {
        this.$rootScope.breadcrumbs = [
            {
                url: this.$rootScope.URL + '/',
                name: 'Home',
            },
            {
                name: title
            },
        ];
    };
    SeoManager.$inject = [
        'ngMeta',
        '$rootScope',
    ];
    return SeoManager;
}());
angular
    .module('app')
    .service('seoManager', __spreadArrays(SeoManager.$inject, [SeoManager]));
var Helpers = /** @class */ (function () {
    function Helpers() {
    }
    Helpers.removeDiacritics = function (str) {
        return str.replace(/[^\u0000-\u007E]/g, function (a) {
            return diacriticsMap[a] || a;
        });
    };
    Helpers.getNumbers = function (string) {
        if (typeof string === "number")
            return string;
        else if (typeof string !== "string")
            return '0';
        var string_return = "";
        var regex = new RegExp("^[0-9]+$");
        for (var i = 0; i < string.length; i++) {
            if (regex.test(string[i])) {
                string_return += string[i];
            }
        }
        if (string_return.length == 0) {
            return '0';
        }
        return string_return;
        //return parseInt(string_return);
    };
    ;
    Helpers.number_format = function (number, decimals, decPoint, thousandsSep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number;
        var prec = !isFinite(+decimals) ? 2 : Math.abs(decimals);
        var sep = (typeof thousandsSep === 'undefined') ? '.' : thousandsSep;
        var dec = (typeof decPoint === 'undefined') ? ',' : decPoint;
        var s = '';
        var toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    };
    Helpers.filterStringEmptyObject = function (object) {
        var newObject = {};
        for (var i in object) {
            if (object[i] !== "" && object[i] !== null) {
                newObject[i] = object[i];
            }
        }
        return newObject;
    };
    Helpers.isEmpty = function (value) {
        return angular.isUndefined(value) || value === '' || value === null || value !== value;
    };
    /**
     * Verifica se CEP é valido
     * @param string
     * @returns {boolean}
     */
    Helpers.verifyCEP = function (string) {
        return (Helpers.getNumbers(string).length == 8);
    };
    ;
    /**
     * Verificar CPF
     * @param string
     * @returns {boolean}
     */
    Helpers.verifyCPF = function (string) {
        var strCPF = Helpers.getNumbers(string);
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF == "00000000000")
            return false;
        for (var i = 1; i <= 9; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))
            Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)))
            return false;
        Soma = 0;
        for (var i = 1; i <= 10; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if ((Resto == 10) || (Resto == 11))
            Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11)))
            return false;
        return true;
    };
    ;
    Helpers.verifyEmail = function (string) {
        var email = string;
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        var re = /[A-Z0-9._+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        if (email == '' || !re.test(email)) {
            return false;
        }
        return true;
    };
    Helpers.convertPrice = function (string) {
        var price = "";
        var ii = 0;
        if (string.length > 2) {
            price += string[string.length - 1] + string[string.length - 2] + ",";
            for (var i = string.length - 3; i >= 0; i--, ii++) {
                if (ii % 3 == 0 && ii != 0) {
                    price += ".";
                }
                price += string[i];
            }
        }
        else if (string.length == 2) {
            price = string[string.length - 1] + string[string.length - 2] + ",0";
        }
        else if (string.length == 1) {
            price = string[string.length - 1] + "0,0";
        }
        price = price.split("").reverse().join("");
        return price;
    };
    Helpers.verifyPhone = function (string) {
        var phone = Helpers.getNumbers(string);
        if (phone.length >= 8 && phone.length <= 11) {
            return true;
        }
        return false;
    };
    Helpers.convertStringUrl = function (string) {
        string = Helpers.removeDiacritics(string.split(" ").join("-").toLowerCase());
        return string;
    };
    Helpers.convertStringDate = function (string) {
        var pattern = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
        return string.replace(pattern, '$3/$2/$1 $4:$2');
    };
    Helpers.addParamsURL = function (key, value) {
        var url = window.location.pathname;
        url = url.split("/");
        var find = false;
        for (var x in url) {
            var part = url[x].split("-");
            if (part.length == 2) {
                if (key == part[0]) {
                    part[1] = value;
                    find = true;
                }
                url[x] = part.join("-");
            }
        }
        url = url.join("/");
        if (!find) {
            url += "/" + key + "-" + value;
        }
        return url;
    };
    Helpers.removeIndexArray = function (array, id) {
        var new_array = [];
        for (var i = 0; i < array.length; i++) {
            if (array[i] != id) {
                new_array.push(array[i]);
            }
        }
        return new_array;
    };
    Helpers.normalizeString = function (string) {
        return Helpers.removeDiacritics(string).trim().toLowerCase();
    };
    Helpers.colorTextOverColor = function (rgb) {
        //Color text change
        var c = 'rgb(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ')';
        //http://www.w3.org/TR/AERT#color-contrast
        var o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
        if (o > 125) {
            return '#000000';
        }
        else {
            return '#ffffff';
        }
    };
    Helpers.hexToRgb = function (hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    };
    Helpers.defaultDiacriticsRemovalMap = [
        {
            'base': 'A',
            'letters': '\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F'
        },
        { 'base': 'AA', 'letters': '\uA732' },
        { 'base': 'AE', 'letters': '\u00C6\u01FC\u01E2' },
        { 'base': 'AO', 'letters': '\uA734' },
        { 'base': 'AU', 'letters': '\uA736' },
        { 'base': 'AV', 'letters': '\uA738\uA73A' },
        { 'base': 'AY', 'letters': '\uA73C' },
        { 'base': 'B', 'letters': '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181' },
        { 'base': 'C', 'letters': '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E' },
        {
            'base': 'D',
            'letters': '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0'
        },
        { 'base': 'DZ', 'letters': '\u01F1\u01C4' },
        { 'base': 'Dz', 'letters': '\u01F2\u01C5' },
        {
            'base': 'E',
            'letters': '\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E'
        },
        { 'base': 'F', 'letters': '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B' },
        {
            'base': 'G',
            'letters': '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E'
        },
        {
            'base': 'H',
            'letters': '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D'
        },
        {
            'base': 'I',
            'letters': '\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197'
        },
        { 'base': 'J', 'letters': '\u004A\u24BF\uFF2A\u0134\u0248' },
        {
            'base': 'K',
            'letters': '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2'
        },
        {
            'base': 'L',
            'letters': '\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780'
        },
        { 'base': 'LJ', 'letters': '\u01C7' },
        { 'base': 'Lj', 'letters': '\u01C8' },
        { 'base': 'M', 'letters': '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C' },
        {
            'base': 'N',
            'letters': '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4'
        },
        { 'base': 'NJ', 'letters': '\u01CA' },
        { 'base': 'Nj', 'letters': '\u01CB' },
        {
            'base': 'O',
            'letters': '\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C'
        },
        { 'base': 'OI', 'letters': '\u01A2' },
        { 'base': 'OO', 'letters': '\uA74E' },
        { 'base': 'OU', 'letters': '\u0222' },
        { 'base': 'OE', 'letters': '\u008C\u0152' },
        { 'base': 'oe', 'letters': '\u009C\u0153' },
        { 'base': 'P', 'letters': '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754' },
        { 'base': 'Q', 'letters': '\u0051\u24C6\uFF31\uA756\uA758\u024A' },
        {
            'base': 'R',
            'letters': '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782'
        },
        {
            'base': 'S',
            'letters': '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784'
        },
        {
            'base': 'T',
            'letters': '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786'
        },
        { 'base': 'TZ', 'letters': '\uA728' },
        {
            'base': 'U',
            'letters': '\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244'
        },
        { 'base': 'V', 'letters': '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245' },
        { 'base': 'VY', 'letters': '\uA760' },
        { 'base': 'W', 'letters': '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72' },
        { 'base': 'X', 'letters': '\u0058\u24CD\uFF38\u1E8A\u1E8C' },
        {
            'base': 'Y',
            'letters': '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE'
        },
        {
            'base': 'Z',
            'letters': '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762'
        },
        {
            'base': 'a',
            'letters': '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250'
        },
        { 'base': 'aa', 'letters': '\uA733' },
        { 'base': 'ae', 'letters': '\u00E6\u01FD\u01E3' },
        { 'base': 'ao', 'letters': '\uA735' },
        { 'base': 'au', 'letters': '\uA737' },
        { 'base': 'av', 'letters': '\uA739\uA73B' },
        { 'base': 'ay', 'letters': '\uA73D' },
        { 'base': 'b', 'letters': '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253' },
        { 'base': 'c', 'letters': '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184' },
        {
            'base': 'd',
            'letters': '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A'
        },
        { 'base': 'dz', 'letters': '\u01F3\u01C6' },
        {
            'base': 'e',
            'letters': '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD'
        },
        { 'base': 'f', 'letters': '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C' },
        {
            'base': 'g',
            'letters': '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F'
        },
        {
            'base': 'h',
            'letters': '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265'
        },
        { 'base': 'hv', 'letters': '\u0195' },
        {
            'base': 'i',
            'letters': '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131'
        },
        { 'base': 'j', 'letters': '\u006A\u24D9\uFF4A\u0135\u01F0\u0249' },
        {
            'base': 'k',
            'letters': '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3'
        },
        {
            'base': 'l',
            'letters': '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747'
        },
        { 'base': 'lj', 'letters': '\u01C9' },
        { 'base': 'm', 'letters': '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F' },
        {
            'base': 'n',
            'letters': '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5'
        },
        { 'base': 'nj', 'letters': '\u01CC' },
        {
            'base': 'o',
            'letters': '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275'
        },
        { 'base': 'oi', 'letters': '\u01A3' },
        { 'base': 'ou', 'letters': '\u0223' },
        { 'base': 'oo', 'letters': '\uA74F' },
        { 'base': 'p', 'letters': '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755' },
        { 'base': 'q', 'letters': '\u0071\u24E0\uFF51\u024B\uA757\uA759' },
        {
            'base': 'r',
            'letters': '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783'
        },
        {
            'base': 's',
            'letters': '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B'
        },
        {
            'base': 't',
            'letters': '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787'
        },
        { 'base': 'tz', 'letters': '\uA729' },
        {
            'base': 'u',
            'letters': '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289'
        },
        { 'base': 'v', 'letters': '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C' },
        { 'base': 'vy', 'letters': '\uA761' },
        { 'base': 'w', 'letters': '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73' },
        { 'base': 'x', 'letters': '\u0078\u24E7\uFF58\u1E8B\u1E8D' },
        {
            'base': 'y',
            'letters': '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'
        },
        { 'base': 'z', 'letters': '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763' }
    ];
    return Helpers;
}());
var diacriticsMap = {};
for (var i = 0; i < Helpers.defaultDiacriticsRemovalMap.length; i++) {
    var letters = Helpers.defaultDiacriticsRemovalMap[i].letters;
    for (var j = 0; j < letters.length; j++) {
        diacriticsMap[letters[j]] = Helpers.defaultDiacriticsRemovalMap[i].base;
    }
}
var SlideShow = /** @class */ (function () {
    function SlideShow($http) {
        this.settings = window['settings'];
        this.scope = {};
        this.bindToController = {
            'noModalSuccess': '<?',
            'formPropertyId': '<',
            'actionSuccess': '&?',
            'formName': '@',
            'ngInit': '@?',
        };
        this.transclude = true;
        this.controllerAs = 'formsManager';
        this.http = $http;
    }
    SlideShow.prototype.link = function (scope, element, attrs, controller, transclude) {
        var boundObj = {};
        var alias = attrs.alias;
        var newScope = scope.$new(scope.controller); // isolate
        newScope[alias] = boundObj;
        transclude(newScope, function (clone) {
            element.append(clone);
        });
        ///
        scope.log = function () {
            console.log(boundObj);
        };
    };
    SlideShow.prototype.controller = function ($scope, $rootScope, $timeout, formsService) {
        // alert('asdsad');
        var vm = this;
        vm.processing = false;
        vm.form = {};
        vm.validate = true;
        vm.d = {};
        vm.addPhone = function () {
            vm.d.phones.push({});
        };
        vm.removePhone = function (index) {
            vm.d.phones.splice(index, 1);
        };
        vm.toggleCheckbox = function (key, value) {
            if (!vm.d[key]) {
                vm.d[key] = [];
            }
            if (vm.d[key].indexOf(value) == -1) {
                vm.d[key].push(value);
            }
            else {
                vm.d[key].splice(vm.d[key].indexOf(value), 1);
            }
        };
        vm.submit = function () {
            if (vm.form.$invalid) {
                vm.validate = true;
                return false;
            }
            vm.validate = false;
            // Não executar a acão diversas vezes
            if (vm.processing)
                return;
            vm.processing = true;
            $rootScope.loadingPage = true;
            return formsService
                .update(vm.formName, vm.d)
                .then(function (response) {
                console.log(response);
            });
            // return $restfull.post(plataform.url_api+"/realestate/forms/"+vm.formName, {
            //         params: {
            //             token: plataform.token
            //         },
            //         body: vm.d
            //     },
            //     function(response){
            //         $rootScope.loadingPage = false;
            //         vm.processing = false;
            //
            //         if(response.data.success){
            //             console.log(vm.noModalSuccess);
            //             if(!vm.noModalSuccess){
            //                 ModalManager.openSuccess();
            //             }
            //
            //             if(!!vm.actionSuccess){
            //                 $scope.$eval(vm.actionSuccess);
            //             }
            //
            //             vm.success = true;
            //         }else{
            //             ModalManager.openFailed();
            //         }
            //
            //     }, function(){
            //         vm.processing = false;
            //         $rootScope.loadingPage = false;
            //         ModalManager.openFailed();
            //     }
            // );
        };
        vm.$onInit = function () {
            vm.d.property_id = vm.formPropertyId;
            if (vm.ngInit) {
                $timeout(function () {
                    $scope.$apply(function () {
                        $scope.$eval(vm.ngInit);
                    });
                }, 1);
            }
        };
    };
    SlideShow.factory = function () {
        return function ($http) { return new SlideShow($http); };
    };
    SlideShow.$inject = ['$http'];
    return SlideShow;
}());
angular
    .module('app')
    .directive('slideShow', SlideShow.factory());
var PropertiesListsService = /** @class */ (function (_super) {
    __extends(PropertiesListsService, _super);
    function PropertiesListsService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.path = '/realestate/lists';
        return _this;
    }
    return PropertiesListsService;
}(CrudManager));
angular
    .module('app')
    .service('propertiesListsService', __spreadArrays(PropertiesListsService.$inject, [PropertiesListsService]));
var FormsService = /** @class */ (function (_super) {
    __extends(FormsService, _super);
    function FormsService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.path = '/realestate/forms';
        return _this;
    }
    return FormsService;
}(CrudManager));
angular
    .module('app')
    .service('formsService', __spreadArrays(FormsService.$inject, [FormsService]));
var helpersService = /** @class */ (function () {
    function helpersService($http) {
        this.settings = window['settings'];
        this.http = $http;
    }
    helpersService.prototype.realestate = function () {
        return this
            .http
            .get(this.settings['API'] + '/realestate/realestate-info');
    };
    helpersService.prototype.info = function () {
        return this
            .http
            .get(this.settings['API'] + '/realestate/get-info');
    };
    helpersService.$inject = ['$http'];
    return helpersService;
}());
angular
    .module('app')
    .service('helpersService', __spreadArrays(helpersService.$inject, [helpersService]));
var locationService = /** @class */ (function (_super) {
    __extends(locationService, _super);
    function locationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.path = '/realestate/lazy-search';
        return _this;
    }
    return locationService;
}(CrudManager));
angular
    .module('app')
    .service('locationService', __spreadArrays(locationService.$inject, [locationService]));
var pagesService = /** @class */ (function () {
    function pagesService($http) {
        this.settings = window['settings'];
        this.http = $http;
    }
    pagesService.prototype.home = function () {
        var Home = Object.keys(window['home']);
        if (!Home.length) {
            return this
                .http
                .get(this.settings['API'] + '/realestate/page/home');
        }
        else {
            return { data: window['home'] };
        }
    };
    pagesService.prototype.neighborhoodPage = function (body) {
        return this.http({
            url: this.settings['API'] + '/realestate/page/location-page',
            method: "POST",
            data: body
        }).then(function (response) {
            return response.data.body;
        });
    };
    pagesService.prototype.siteMap = function () {
        return this
            .http
            .get(this.settings['API'] + '/realestate/page/site-map');
    };
    pagesService.$inject = ['$http'];
    return pagesService;
}());
angular
    .module('app')
    .service('pagesService', __spreadArrays(pagesService.$inject, [pagesService]));
var propertiesService = /** @class */ (function (_super) {
    __extends(propertiesService, _super);
    function propertiesService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.path = '/realestate/properties';
        return _this;
    }
    propertiesService.prototype.getParams = function (finality, path) {
        var filters = {};
        // /venda
        if (finality == 'venda') {
            filters["finality"] = 'sale';
        }
        else {
            filters["finality"] = 'rent';
        }
        console.log(path);
        if (path) {
            var explode = path.split("/");
            // Reseta Ordem
            filters.sort = '';
            for (var i in explode) {
                var data = explode[i];
                if (data.indexOf("l-") !== -1) {
                    //LIMPA l- da variavel
                    var array = data.split("-");
                    array.splice(0, 1);
                    var locations = array.join("-").split("_");
                    var location_correct = [];
                    for (var x in locations) {
                        location_correct.push(locations[x].replace(/-/g, ' '));
                    }
                    filters["location"] = location_correct.join('_');
                    //Corrigir localizacoes
                }
                else if (data.indexOf("t-") !== -1) {
                    var array = data.split("-");
                    array.splice(0, 1);
                    var element = array.join("-").split(',');
                    filters["type_name"] = [];
                    for (var xx in element) {
                        filters["type_name"].push(element[xx]);
                    }
                    filters["type_name"] = filters["type_name"].join(',');
                }
                else if (data.indexOf("sub-") !== -1) {
                    var subtypes = data.split("-")[1].split(',').map(function (s) { return Number(s); });
                    filters["subtype"] = subtypes;
                }
                else if (data.indexOf("dorms-") !== -1) {
                    filters["bedrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("suites-") !== -1) {
                    filters["suites"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("vagas-") !== -1) {
                    filters["vacancies"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("banheiros-") !== -1) {
                    filters["bathrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }
                else if (data.indexOf("area-min-") !== -1) {
                    filters["area_gte"] = parseInt(data.split("-")[2]);
                }
                else if (data.indexOf("area-max-") !== -1) {
                    filters["area_lte"] = parseInt(data.split("-")[2]);
                }
                else if (data.indexOf("preco-min-") !== -1) {
                    filters["price_gte"] = parseInt(data.split("-")[2]) * 100;
                }
                else if (data.indexOf("preco-max-") !== -1) {
                    filters["price_lte"] = parseInt(data.split("-")[2]) * 100;
                }
                else if (data.indexOf("cond-valor") !== -1) {
                    filters["cond_valor"] = true;
                }
                else if (data.indexOf("ordem-") !== -1) {
                    var _sort = data.split("-")[1];
                    if (_sort == 'price_min') {
                        filters.sort = "price";
                    }
                    else if (_sort == 'price_max') {
                        filters.sort = "-price";
                    }
                }
                else if (data.indexOf("page-") !== -1) {
                    filters.currentPage = parseInt(data.split("-")[1]) - 1;
                    filters.currentPage = filters.currentPage < 0 ? 0 : filters.currentPage;
                    filters.offset = filters.currentPage * 20;
                }
            }
            // if(filters["price_lte"]) {
            //     filters.sort = "-price";
            // }else if(filters["price_gte"]) {
            //     filters.sort = "price";
            // }
        }
        if (filters["finality"] == 'sale') {
            filters["cond_valor"] = false;
        }
        if (!!filters["cond_valor"]) {
            if (filters["price_lte"]) {
                filters["price_townhouse_lte"] = filters["price_lte"];
                filters["price_lte"] = undefined;
            }
            if (filters["price_gte"]) {
                filters["price_townhouse_gte"] = filters["price_gte"];
                filters["price_gte"] = undefined;
            }
        }
        console.log(filters);
        return filters;
    };
    ;
    return propertiesService;
}(CrudManager));
angular
    .module('app')
    .service('propertiesService', __spreadArrays(propertiesService.$inject, [propertiesService]));
