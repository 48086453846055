class helpersService {

    static $inject = ['$http'];

    http: any;
    settings: any = window['settings'];

    constructor($http: any) {
        this.http = $http;
    }


    realestate() {
        return this
            .http
            .get(this.settings['API'] + '/realestate/realestate-info')
    }

    info() {
        return this
            .http
            .get(this.settings['API'] + '/realestate/get-info');
    }
}

angular
    .module('app')
    .service('helpersService', [...helpersService.$inject, helpersService]);
