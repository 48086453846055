class SearchLocationController {

    public static $inject: string[] = [
        '$scope',
        '$timeout',
        '$document',
        '$state',
        '$rootScope',
        'locationService'
    ];

    settings: any = window['settings'];

    location: {
        estate: string,
        city: string,
        neighborhood: string
    };


    processing = false;
    finality = 'venda';
    type = 'apartamento';
    cod_search = false;
    neighborhood = [];
    city = [];
    callSearch = null;
    open = false;
    stay = false;
    selectSearch = 0;
    maxvalues: number;
    locationNotFound = false;
    focus = false;

    // Inject
    locationService: any;
    $rootScope: any;
    $scope: any;
    $timeout: any;
    $document: any;
    $state: any;

    constructor(
        $scope,
        $timeout,
        $document,
        $state,
        $rootScope,
        locationService
    ) {
        this.locationService = locationService;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$document = $document;
        this.$state = $state;
    }

    $onInit() {
        let that = this;
        this.$scope.$watch('$root.text_search', (newvalue, oldvalue) => {
            if (oldvalue == newvalue || !oldvalue || !newvalue) return;

            that.$timeout.cancel(that.callSearch);
            that.callSearch = null;

            that.callSearch = that.$timeout(function () {
                that.locationNotFound = false;
                that.locationService
                    .create({
                        search: newvalue
                    })
                    .then((body) => {

                        that.open = true;

                        if (that.$rootScope.text_search == '') {
                            that.open = false;
                        }

                        that.neighborhood = [];
                        that.city = [];
                        that.selectSearch = 0;
                        that.maxvalues = body.neighborhood.length + body.city.length;

                        var count = 0;

                        for (var i in body.neighborhood) {
                            var location = body.neighborhood[i];

                            // Se localização já estiver sido adicionado, não adiciona novamente
                            for (var x in that.$rootScope.localizacoes) {
                                if (that.$rootScope.localizacoes[x].estate === location.estate &&
                                    that.$rootScope.localizacoes[x].city === location.city &&
                                    that.$rootScope.localizacoes[x].neighborhood === location.neighborhood) {
                                    continue;
                                }
                            }

                            location.index = count++;

                            var pos_neighborhood = Helpers.normalizeString(location.neighborhood).indexOf(Helpers.normalizeString(that.$rootScope.text_search));
                            location.neighborhood_html = location.neighborhood.substring(0, pos_neighborhood) +
                                '<b>' + location.neighborhood.substring(pos_neighborhood, pos_neighborhood + Helpers.normalizeString(that.$rootScope.text_search).length) + '</b>'
                                + location.neighborhood.substring(Helpers.normalizeString(that.$rootScope.text_search).length + pos_neighborhood);

                            that.neighborhood.push(location);
                        }

                        for (var i in body.city) {
                            var location = body.city[i];

                            // Se localização já estiver sido adicionado, não adiciona novamente
                            for (var x in that.$rootScope.localizacoes) {
                                if (that.$rootScope.localizacoes[x].estate === location.estate &&
                                    that.$rootScope.localizacoes[x].city === location.city) {
                                    continue;
                                }
                            }


                            location.index = count++;

                            var pos_city = Helpers.normalizeString(location.city).indexOf(Helpers.normalizeString(that.$rootScope.text_search));

                            location.city_html = location.city.substring(0, pos_city) +
                                '<b>' + location.city.substring(pos_city, pos_city + Helpers.normalizeString(that.$rootScope.text_search).length) + '</b>'
                                + location.city.substring(Helpers.normalizeString(that.$rootScope.text_search).length + pos_city);

                            that.city.push(location);
                        }

                        if(!that.neighborhood.length && !that.city.length){
                            that.locationNotFound = true;
                        }

                    });
            }, 200);
        });

        that.$document.on('keydown', function (e) {
            if (e.which == 40) { //DOWN
                that.selectSearch++;
                if (that.selectSearch >= that.maxvalues)
                    that.selectSearch = 0;
            }

            if (e.which == 38) { //UP
                that.selectSearch--;

                if (0 > that.selectSearch)
                    that.selectSearch = that.maxvalues - 1;
            }

            if (e.which == 13) { //Enter
                // that.search();
            }

            that.$scope.$apply();
        });
    }

    /**
     * Define localização
     * @param location
     */
    setLocation(location) {

        this.location = location;
        this.addLocation(location);
        this.open = false;

        if (this.stay)
            this.$rootScope.text_search = '';

        this.createUrl();
    }

    /**
     * Remover localização das tags
     * @param index
     */
    removeLocation(index) {
        this.$rootScope.localizacoes.splice(index, 1);
        console.log("this.$rootScope.localizacoes", this.$rootScope.localizacoes);
    }

    /**
     * Buscar Localização
     */
    search() {

        for (let i in this.neighborhood) {
            if (this.neighborhood[i].index == this.selectSearch) {
                this.setLocation(this.neighborhood[i]);
                return false;
            }
        }

        for (let i in this.city) {
            if (this.city[i].index == this.selectSearch) {
                this.setLocation(this.city[i]);
                return false;
            }
        }

        this.createUrl();
    }

    delayFocus() {
        this.$timeout(() => {
            this.focus = false;
        }, 100);
    }

    /**
     * Adicionar localização nas tags
     * @param location
     * @returns {boolean}
     */
    addLocation(location) {
        if (!this.$rootScope.localizacoes)
            this.$rootScope.localizacoes = [];

        for (var i in this.$rootScope.localizacoes) {
            if (this.$rootScope.localizacoes[i].estate === location.estate &&
                this.$rootScope.localizacoes[i].city === location.city &&
                this.$rootScope.localizacoes[i].neighborhood === location.neighborhood) {
                return false;
            }
        }
        this.$rootScope.localizacoes.push(location);
    }

    /**
     * Cria URL para busca
     */
    createUrl() {
        var url = this.settings['URL'];
        url += "/" + this.finality;
        url += "/t-" + this.type + "";

        if (this.location) {
            if (!this.location.neighborhood) {
                url += "/l-" + this.location.estate + "," + this.location.city + "/";
            } else {
                url += "/l-" + this.location.estate + "," + this.location.city + "," + this.location.neighborhood + "/";
            }
        } else {
            if (this.$rootScope.text_search) {
                url += "/x-" + this.$rootScope.text_search;
            }
        }

        if (!this.stay) {
            // window.location = Helpers.convertStringUrl(url);
            history.pushState(null, "", Helpers.convertStringUrl(url));
        }
        console.log('asd');
    }

    /**
     * Muda tipo de busca
     * Por código ou textual
     */
    changeSearch() {
        this.cod_search = !this.cod_search;
    }

    /**
     * Buscar pelo Código
     */
    searchCod() {
        this.$state.go('root.propertyViewOld2', {
            cod: this.$rootScope.cod
        });
    }
}

angular
    .module('app')
    .controller('SearchLocationController', SearchLocationController);
