class ContactController {

    public static $inject: string[] = [
        '$rootScope',
        'seoManager',
    ];

    settings: any = window['settings'];

    constructor(
        $rootScope,
        seoManager
        ){
        seoManager.setTitle('Fale Conosco | ' + $rootScope.realestate.name);
        seoManager.setTitleH1('Fale Conosco');
        seoManager.setUrl('/contato', true);
    }
}

angular
    .module('app')
    .controller('ContactController', ContactController);
