class MaskPhone implements ng.IDirective {

    static $inject = [];

    restrict = 'A';
    require = 'ngModel';
    scope = {
        ngModel: '='
    };

    constructor() {}

    link(scope, element, attrs, ctrl) {

        ctrl.$parsers.unshift(function(viewValue) {
            if(viewValue.length > 11){
                viewValue = viewValue.replace(/\D/g,"").substr(0, 11);
            }

            let string = viewValue.replace(/\D/g,"");
            string = string.replace(/^(\d{2})(\d)/g,"($1) $2");
            string = string.replace(/(\d)(\d{4})$/,"$1-$2");

            element.val(string);

            let value = viewValue.replace(/\D/g,'');

            if(value.length != 0){
                ctrl.$setValidity("phone_invalid", false);
                if(value.length >= 10){
                    ctrl.$setValidity("phone_invalid", true);
                    return value;
                }
                return undefined;
            }else{
                ctrl.$setValidity("phone_invalid", true);
                return value;
            }
        });

    }

    static factory(): ng.IDirectiveFactory {
        return () => new MaskPhone()
    }
}

angular
    .module('app')
    .directive('maskPhone', MaskPhone.factory());
