class PropertyRequestController {

    public static $inject: string[] = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        '$location',
        '$timeout',
        'Upload',
        'propertiesService',
        'seoManager',
    ];

    settings: any = window['settings'];
    $rootScope: any;
    $scope: any;
    $location: any;
    $q: any;
    $state: any;
    $timeout: any;
    propertiesService: propertiesService;
    Upload: any;
    d: any = {};
    validate = false;
    tab = 1;
    allTabs = false;
    success: true;

    //tmps
    finality: number = 0;
    rent_price: string;
    sale_price: string;
    subtype = [];
    benefits = [];
    photo_ids = 0;
    photo_uploading = 0;
    photos_count = 0;
    photos = [];
    uploading = false;
    saving = false;
    loadimages = '';
    loadingAddress = false;

    constructor ($rootScope, $scope, $q, $state, $location, $timeout, Upload, propertiesService, seoManager) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.Upload = Upload;
        this.$state = $state;
        this.$location = $location;
        this.$timeout = $timeout;
        this.propertiesService = propertiesService;

        seoManager.setTitle('Encomende seu Imóvel | ' + $rootScope.realestate.name);
        seoManager.setUrl('/encomende-seu-imovel', true);
    }

}

angular
    .module('app')
    .controller('PropertyRequestController', PropertyRequestController);
