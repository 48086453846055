(function(){
	angular.module("app")
		.controller('WhatsappConversation', [
			'$scope',
			'$rootScope',
			function(
				$scope,
				$rootScope
			){
				var self = this;

				self.close = true;
				self.send_true = false;

				self.send = function() {
					if(self.whatsapp.$invalid){
						return;
					}


					if ( $rootScope.realestate.id === 1) {
						self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text='+self.message;	
					} else {
						self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=Me chamo '+self.d.name+', preciso de algumas informações.';
					}

					if ( $rootScope.realestate.id === 7) {
						if(self.message) {
							self.link = 'https://api.whatsapp.com/send?phone=5511956052600&text=Me chamo '+self.d.name+self.message;
						} else {
							self.link = 'https://api.whatsapp.com/send?phone=5511956052600&text=Me chamo '+self.d.name+', preciso de algumas informações.';
						}	
					}
					
					if ( $rootScope.realestate.id === 297) {
						if(self.message) {
							self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=Me chamo '+self.d.name + ' ' +self.message;
						} else {
							self.link = 'https://api.whatsapp.com/send?phone=5511977477000&text=Me chamo '+self.d.name+', preciso de algumas informações.';
						}
						let bn = 2;
						console.log(bn);
					}
					
					if ( $rootScope.realestate.id === 893) {
						if(self.message) {
							self.link = 'https://api.whatsapp.com/send?phone=5511987477000&text=Me chamo '+self.d.name + ' ' +self.message;
						} else {
							self.link = 'https://api.whatsapp.com/send?phone=5511987477000&text=Me chamo '+self.d.name+', preciso de algumas informações.';
						}
						let bn = 2;
						console.log(bn);
					}

					if ( $rootScope.realestate.id === 12) {
						if(self.message) {
							self.link = 'https://api.whatsapp.com/send?phone=5511993157017&text='+self.message;
						} else {
							self.link = 'https://api.whatsapp.com/send?phone=5511993157017&text=Me chamo '+self.d.name+', preciso de algumas informações.';
						}
					}
					
					if ( $rootScope.realestate.id === 714) {
						if(self.message) {
							self.link = 'https://api.whatsapp.com/send?phone=5511985057761&text='+self.message;
						} else {
							self.link = 'https://api.whatsapp.com/send?phone=5511985057761&text=Me chamo '+self.d.name+', preciso de algumas informações.';
						}
					}
					
					if ( $rootScope.realestate.id === 798) {
						if(self.message) {
							self.link = 'https://api.whatsapp.com/send?phone=5511987854939&text='+self.message;
						} else {
							self.link = 'https://api.whatsapp.com/send?phone=5511987854939&text=Me chamo '+self.d.name+', preciso de algumas informações.';
						}
					}
					
					// self.link = 'https://api.whatsapp.com/send?phone='+self.whatsphone+'&text=Me chamo'+self.d.name+'. '+self.message;
					window.open(self.link, '_blank');

					// $restfull.post(plataform.url_api+"/forms/moreinfo", {
					// 		params: {
					// 			token: plataform.token
					// 		},
					// 		body: self.d
					// 	},
					// 	function(response){
					// 		self.send_true = true;
					// 	}, function(){
					// 	}
					// );
				}
			}]);
		})();