
class GoogleMapController {

    public static $inject: string[] = [
        '$scope',
        '$rootScope',
        '$interval',
        '$document'
    ];

    settings: any = window['settings'];

    place = {};
    showMap = false;
    path = [];
    map: any = false;
    markers = [];
    argument_start = false;
    location_finish: any;
    currentLoc: any;
    fitBounds: any;
    boundsPlace: any;
    center: any;
    centerL: any;
    raioCircle: any;
    location: any;

    // Inject
    $rootScope: any;
    $scope: any;
    $interval: any;
    $document: any;

    constructor(
        $scope,
        $rootScope,
        $interval,
        $document
    ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$interval = $interval;
        this.$document = $document;
    }

    $onInit() {
        let that = this;

    }

    init(map) {
        this.map = map;
        this.SetMapAddress(this.location);
    }

    getLocation(address){
        let geocoder = new google.maps.Geocoder();
        let that = this;

        if (geocoder) {
            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    that.currentLoc = results[0].geometry.location;
                    console.log("that.currentLoc", that.currentLoc);
                }

                that.location_finish = true;

                that.$scope.$apply();
            });
        }
    }

    SetMapAddress(address) {
        let geocoder = new google.maps.Geocoder();
        let that = this;

        if (geocoder) {
            geocoder.geocode({ 'address': address }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    that.currentLoc = results[0].geometry.location;
                    that.map.fitBounds(results[0].geometry.viewport);
                    that.fitBounds = results[0].geometry.viewport;

                    that.boundsPlace = new google.maps.LatLngBounds(
                        results[0].geometry.viewport.getSouthWest(),
                        results[0].geometry.viewport.getNorthEast()
                    );

                    that.center = [that.boundsPlace.getCenter().lat(),that.boundsPlace.getCenter().lng()];
                    that.centerL = that.boundsPlace.getCenter();
                    that.raioCircle = that.calcDistance(results[0].geometry.viewport.getSouthWest(), results[0].geometry.viewport.getNorthEast())/2.0*1000;


                    let lat0 = results[0].geometry.viewport.getNorthEast().lat();
                    let lng0 = results[0].geometry.viewport.getNorthEast().lng();
                    let lat1 = results[0].geometry.viewport.getSouthWest().lat();
                    let lng1 = results[0].geometry.viewport.getSouthWest().lng();

                    that.path = [
                        [lat0, lng0],
                        [lat0, lng1],
                        [lat1, lng1],
                        [lat1, lng0]
                    ];
                }

                if(!!that.argument_start){
                    that.searchPlaces(that.argument_start);
                    that.argument_start = false;
                }

                that.location_finish = true;

                that.$scope.$apply();
            });
        }
    }

    searchPlaces(argument) {

        console.log("argument", argument);

        let that = this;

        if(!this.map){
            this.showMap = true;
            this.argument_start = argument;
            return false;
        }

        console.log("this.center", this.center);

        let request = {
            location: this.centerL,
            radius: this.raioCircle,
            types: [
                argument
            ]
        };

        let img = '';
        if(argument == 'shopping_mall'){
            img = 'shop';
        }else if(argument == 'bakery'){
            img = 'padaria';
        }else if(argument == 'grocery_or_supermarket'){
            img = 'mercados';
        }else if(argument == 'pharmacy'){
            img = 'farmacia';
        }else if(argument == 'hospital'){
            img = 'hospital';
        }else if(argument == 'school'){
            img = 'escola';
        }else if(argument == 'gym'){
            img = 'academia';
        }else if(argument == 'bank'){
            img = 'banco';
        }else{
            return false;
        }

        if(!!this.place[argument]){
            let _markers = [];

            for (let i in this.markers) {
                if(this.markers[i].type != argument)
                    _markers.push(this.markers[i]);
            }

            this.markers = _markers;

            this.place[argument] = false;
        }else{
            this.place[argument] = true;

            let service = new google.maps.places.PlacesService(this.map);
            service.nearbySearch(request, function(results, status) {

                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    for (let i = 0; i < results.length; i++) {
                        let place = results[i];
                        // return;

                        that.markers.push({
                            type: argument,
                            img: img,
                            location: place.vicinity,
                            name: place.name,
                            position: [place.geometry.location.lat(), place.geometry.location.lng()]
                        });

                        // this..push(new google.maps.Marker({
                        // 	position: results[i].geometry.location,
                        // 	map: map,
                        // 	icon: icons,
                        // 	title: 'Hello World!'
                        // }));
                    }

                    // 'shopping_mall',
                    // 	'bakery',
                    // 	'grocery_or_supermarket',
                    // 	'pharmacy',
                    // 	'hospital',
                    // 	'school',
                    // 	'gym',
                    // 	'bank'

                    that.$scope.$apply();
                }
            });
        }

        $('html,body').animate({
                scrollTop: $(".google-maps").offset().top},
            'slow');
    }

    //calculates distance between two points in km's
    calcDistance(p1, p2): number {
        return Number((google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2));
    }

}


angular
    .module('app')
    .controller('GoogleMapController', GoogleMapController);
