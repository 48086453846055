let module = angular.module("app", [
    'ui.router',
    'ngMeta',
    'ngSanitize',
    'ngMap',
    'ui.utils.masks',
    'ngYoutubeEmbed',
    'ngCookies',
    'cfp.loadingBar',
    'ngAnimate',
    'ngFileUpload',
    'isteven-omni-bar',
]);

angular.element(document).ready(function() {
    let settings = document.querySelector('#set');
    let home = document.querySelector('#set-home');
    window['settings'] = JSON.parse(settings.attributes['value']['value']);
    window['home'] = JSON.parse(atob(home.attributes['value']['value']));
    console.log(window['home']);

    angular.bootstrap(document, ["app"]);
});
