class Config {
    static $inject = ['ngMetaProvider', '$httpProvider', "$compileProvider"];
    settings: any = window['settings'];

    constructor(ngMetaProvider, $httpProvider, $compileProvider) {

        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|whatsapp|messenger|tel):/);

        /**
         * MetaTags
         */
        // ngMetaProvider.useTitleSuffix(true);
        // ngMetaProvider.setDefaultTitle('Alto Pedroso Imóveis');
        // ngMetaProvider.setDefaultTag('author', 'John Smith');
        // ngMetaProvider.setDefaultTag('description', 'description');
        // ngMetaProvider.setDefaultTag('image', 'asdasd');
        // ngMetaProvider.setDefaultTag('url', 'asdasd');
        // ngMetaProvider.setDefaultTag('name', 'Alto Pedroso');

        $httpProvider.interceptors.push('InterceptorRequest');
    }
}

angular.module("app")
    .config(Config);
