class SiteMapController {

    public static $inject: string[] = [
        '$rootScope',
        'locationData',
        'seoManager',
    ];

    settings: any = window['settings'];
    locations: any;

    constructor($rootScope, locationData, seoManager) {
        this.locations = locationData.data.body;

        seoManager.setTitle('Mapa do Site | ' + $rootScope.realestate.name);
        seoManager.setTitleH1('Mapa do Site');
        seoManager.setUrl('/mapa-do-site', true);
    }
}

angular
    .module('app')
    .controller('SiteMapController', SiteMapController);
