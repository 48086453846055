class MaskEmail implements ng.IDirective {

    static $inject = [];

    restrict = 'A';
    require = 'ngModel';
    scope = {
        ngModel: '='
    };

    constructor() {}

    link(scope, element, attrs, ctrl) {

        ctrl.$parsers.unshift(function(viewValue) {
            console.log("viewValue", viewValue);
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if(viewValue.length != 0){
                ctrl.$setValidity("email_invalid", false);
                if(re.test(String(viewValue).toLowerCase())){
                    ctrl.$setValidity("email_invalid", true);
                    return viewValue;
                }
                return undefined;
            }else{
                ctrl.$setValidity("email_invalid", true);
                return viewValue;
            }
        });

    }

    static factory(): ng.IDirectiveFactory {
        return () => new MaskEmail()
    }
}

angular
    .module('app')
    .directive('maskEmail', MaskEmail.factory());
