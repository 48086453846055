class PropertyRegisterController {

    public static $inject: string[] = [
        '$rootScope',
        '$scope',
        '$q',
        '$state',
        'seoManager',
        '$location',
        '$timeout',
        'Upload',
        'propertiesService',
    ];

    settings: any = window['settings'];
    $rootScope: any;
    $scope: any;
    seoManager: any;
    $location: any;
    $q: any;
    $state: any;
    $timeout: any;
    propertiesService: propertiesService;
    Upload: any;
    d: any = {};
    validate = false;
    tab = 1;
    allTabs = false;
    success: true;

    //tmps
    finality: number = 0;
    rent_price: string;
    sale_price: string;
    subtype = [];
    benefits = [];
    photo_ids = 0;
    photo_uploading = 0;
    photos_count = 0;
    photos = [];
    uploading = false;
    saving = false;
    loadimages = '';
    loadingAddress = false;

    constructor ($rootScope, $scope, $q, $state, seoManager, $location, $timeout, Upload, propertiesService) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$q = $q;
        this.Upload = Upload;
        this.$state = $state;
        this.$location = $location;
        this.$timeout = $timeout;
        this.seoManager = seoManager;
        this.propertiesService = propertiesService;

        this.seoManager.setTitle('Cadastrar Imóvel | ' + $rootScope.realestate.name);
        this.seoManager.setTitleH1('Cadastrar Imóvel');
        this.seoManager.setUrl('/anunciarimovel', true);

        // Atualiza url
        let off = $scope.$on('$stateChangeStart', (e) => {
            e.preventDefault();
            off();
        });
        this.$location.path('/anunciarimovel').replace();

        this.d = {
            contact_phones: [],
            composition: {}
        };

        let that = this;
        this.$rootScope.info.properties.compositions.map(i => {
            that.d.composition[i.id] = {
                id: i.id,
                value: 0,
            };
        });

        this.addPhone();

        this.$scope.$watch('vm.d.CEP', (newValue, oldValue)  => {
            if(newValue != oldValue){
                if(!!newValue){
                    // this.loadingAddress = true;
                    // console.log(this.loadingAddress);
                    // this.viaCep.get(newValue).then((response) => {
                    //     this.loadingAddress = false;
                    //     this.d.address = response.logradouro;
                    //     this.d.city = response.localidade;
                    //     this.d.neighborhood = response.bairro;
                    //     this.d.estate = response.uf.toUpperCase();
                    //     console.log(response);
                    // }).catch(() => {
                    //     this.loadingAddress = false;
                    //     console.log(this.loadingAddress);
                    // });
                }
            }
        });

        // this.initForm();

        window.addEventListener('popstate', () => {
            let step = parseInt(window.location.search.substr(1).replace('step=',''));
            if(!step) step = 1;
            let _step_validate = 7;

            for (var i = 1; i <= 7; i++) {
                if($scope['step'+i].$invalid){
                    _step_validate = i;
                    break;
                }
            }

            console.log("_step_validate", _step_validate);

            //Se a etapa que o usuário quiser ir não estiver valida,
            //vai para a valida mais proxima
            if(step > _step_validate){
                this.tab = _step_validate;
                this.validate = true;
            }else{
                this.tab = step;
            }

            $('html,body').animate({scrollTop: $(".top").offset().top}, 'slow');

            $scope.$apply();
        });

    }

    generateDescription() {
        'Casa a venda no bairro de São Miguel Paulista em São Paulo por R$200.000 com 3 banheiros, 1 sala, 3 cozinhas, Cozinha, Ar condicionado, Sistema de Alarme. Com 22m de área util e 22m de área total. Otima localização'
    }

    addPhone() {
        this.d.contact_phones.push({});
    }

    removePhone(index: number) {
        this.d.contact_phones.splice(index, 1);
    }

    indexOfComposition(id) {
        if(!!this.d.type){
            for (let i in this.$rootScope.info.properties.types){
                let item = this.$rootScope.info.properties.types[i];

                if(item.id == this.d.type) {
                    return item.properties_composition_id.indexOf(Number(id)) !== -1;
                }
            }
        }

        return false;

    }

    /**
     * Volta etapa
     * @returns {boolean}
     */
    lastTab() {
        this.tab -= 1;
    }

    /**
     * Avança para proxima etapa
     * @returns {boolean}
     */
    nextTab() {
    	
        if(this.$scope['step'+this.tab].$invalid){
            this.validate = true;
			setTimeout(() => {
				$('html,body').scrollTop($(".error").offset().top);
			}, 200);
			
            return false;
        }

        this.validate = false;
        this.tab += 1;

        $('html,body').animate({scrollTop: $(".top").offset().top}, 'slow');

        this.$state.go('root.propertyRegister', {
            step: this.tab
        }, {notify: false});

    }

    /**
     * @param type
     */
    selectSubType(type){
        if(!this.d.subtype){
            this.d.subtype = [];
        }

        if(this.d.subtype.indexOf(type) == -1){
            this.d.subtype.push(type);
            this.subtype[type] = true;
        }else{
            this.d.subtype.splice(this.d.subtype.indexOf(type), 1);
            this.subtype[type] = false;
        }
    }

    /**
     * @param value
     */
    selectBenefits(value){
        if(!this.d.benefits){
            this.d.benefits = [];
        }

        if(this.d.benefits.indexOf(value) == -1){
            this.d.benefits.push(value);
            this.benefits[value] = true;
        }else{
            this.d.benefits.splice(this.d.benefits.indexOf(value), 1);
            this.benefits[value] = false;
        }
    }

    uploadFiles(files) {
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                //Adiciona Card
                let current_image = {
                    id: this.photo_ids++, //Id da foto para ser melhor encontrada
                    file: files[i], //base64 da imagem para redução via javascript
                    remove: false, //Foi removido pelo botão fecha no card
                    error: false, //Se ocorreu algum erro ao enviar ela para o servidor
                    load: false, //Se já foi carregada
                    loading: true, // se está carregando a imagem para exbição
                    currentValue: 0, // Porcentagem do loading
                    maxValue: 100 // Valor Máximo da porcentagem
                };

                this.photos.push(current_image);

                this.photo_uploading++;
            }

            this.uploadImages();
        }
    }

    uploadImages(id?) {

        let photo_load_id = -1;

        if(typeof id == 'undefined'){
            for (let i in this.photos) {
                if(!this.photos[i].load && !this.photos[i].remove && !this.photos[i].error){
                    photo_load_id = Number(i);
                    break;
                }
            }
            if (photo_load_id == -1) return false;
        }else{
            photo_load_id = id;
        }

        this.prepareUploadFile(photo_load_id);
    }

    /**
     * Preparar foto para Upload
     * @param id
     */
    prepareUploadFile(id){
        this.photos[id].loading = true;
        this.photos[id].error = false;
        this.photos[id].currentValue = 0;

        if(this.uploading == true){
            return;
        }

        this.photos[id].currentValue = 5;

        this.uploading = true;

        let fileReader = new FileReader();
        let that = this;

        fileReader.onload = function(e) {
            that.resizeImage(e.target['result'])
                .then(data => {
                    that.photos[id].src = data.base64;
                    that.uploadFile(data.blob, id);
                });
        };

        fileReader.readAsDataURL(this.photos[id].file);
    }

    /**
     * Upar Arquivo
     * @param file
     * @param photo_load_id
     */
    uploadFile(file, photo_load_id) {
        let that = this;
        this.Upload.upload({
            url: this.settings['API'] + '/realestate/uploads/realestate_image_property?token='+this.settings['TOKEN'],
            data: {
                file: file,
                id: photo_load_id
            }
        }).then(function(resp) {
            let data = resp.data;
            let id = resp.config.data.id;

            if (data.success) {
                let body = data.body;

                //Em caso de sucesso remove arquivo na memoria
                that.photos[id].file = null;
                that.photos[id].load = true;

                that.photos[id].currentValue = 100;

                //Adiciona a quantidade de fotos enviadas
                that.photos_count++;

                //Remove erro
                that.photos[id].error = false;

                that.photos[id].image = body[0].key_name;

                that.photos[id].loading = false;

                that.photo_uploading--;

                // Se tiver mais itens na fila, faz upload novamente
                that.uploading = false;
                that.uploadImages();
            } else {
                that.photos[id].loading = false;
                that.photos[id].error = true;

                that.photo_uploading--;

                // Se tiver mais itens na fila, faz upload novamente
                that.uploading = false;
                that.uploadImages();
            }

        },
        function(resp) {

            let id = resp.config.data.id;

            that.photos[id].loading = false;
            that.photos[id].error = true;

            that.photo_uploading--;

            // Se tiver mais itens na fila, faz upload novamente
            that.uploading = false;
            that.uploadImages();
        },
        function(evt) {
            let id = evt.config.data.id;

            let progressPercentage = Math.floor(100.0 * evt.loaded / evt.total);
            that.photos[id].currentValue = progressPercentage;
            console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        }
        );

    }

    /**
     * Refazer o Upload da foto
     * @param id
     */
    reUpload(id) {
        let photo_load_id = -1;
        for (let i in this.photos) {
            if(this.photos[i].id == id){
                photo_load_id = Number(i);
                break;
            }
        }

        if(photo_load_id != -1){
            this.uploadImages(photo_load_id);
        }
    }

    /**
     * Remover foto
     * @param id
     */
    removePhoto(id) {

        let index = -1;
        for (let i in this.photos){
            if(id == this.photos[i].id){
                index = Number(i);
            }
        }

        if (index == -1) return;

        // Remove quantidade de fotos enviadas
        // Apenas se tiver erro
        if (!this.photos[index].error) {
            this.photos_count--;
        }

        //Remove Foto
        // this.photos.splice(index, 1);
        this.photos[index].remove = true;
    }

    /**
     * Quantidade de imagens upadas
     * @returns {number}
     */
    countImages() {
        let count_images = 0;
        for (let i in this.photos) {
            if(this.photos[i].load && !this.photos[i].remove && !this.photos[i].error){
                count_images++;
            }
        }

        return count_images;
    }

    /**
     * Se existe imagens sendo feitas upload
     * @returns {number}
     */
    imagesUploading() {
        let count_images = 0;
        for (let i in this.photos) {
            if(!this.photos[i].load && !this.photos[i].remove && !this.photos[i].error){
                count_images++;
            }
        }

        return count_images;
    }

    /**
     * Rotacionar imagem
     * @param id
     */
    rotDec(id) {
        if (!this.photos[id].rot) this.photos[id].rot = 0;

        this.photos[id].rot -= 90;
        this.photos[id].rot += 360;
        this.photos[id].rot = this.photos[id].rot % 360;
    }

    /**
     * Rotacionar imagem
     * @param id
     */
    rotIn(id) {
        if (!this.photos[id].rot) this.photos[id].rot = 0;

        this.photos[id].rot += 90;
        this.photos[id].rot = this.photos[id].rot % 360;
    }

    /**
     * Redimensiona a imagem para enviar poucos dados
     * @param base64
     * @returns {any}
     */
    resizeImage(base64) {

        const that = this;

        return this.$q((resolve, reject) => {

            let canvas = document.createElement('canvas'),
                context = null;

            context = canvas.getContext('2d');

            let imageObj = new Image();

            imageObj.onload = function () {
                // Check for empty images
                if (this['width'] === 0 || this['height'] === 0) {
                } else {
                    if (this['width'] > this['height']) {
                        canvas.width = 900;
                        canvas.height = this['height'] * (900 / this['width']);
                    } else {
                        canvas.height = 900;
                        canvas.width = this['width'] * (900 / this['height']);
                    }

                    // context.clearRect(0, 0, canvas.width, canvas.height);
                    context.drawImage(imageObj, 0, 0, this['width'], this['height'], 0, 0, canvas.width, canvas.height);

                    let blob = that.dataURLtoBlob(canvas.toDataURL('image/png'));

                    let data = {
                        blob: blob,
                        base64: canvas.toDataURL('image/png'),
                    };

                    resolve(data);
                }
            };

            imageObj.onerror = () => {
                reject();
            };

            imageObj.src = base64;
        });
    }

    /**
     * Converte base64 em Blob
     * @param dataurl
     * @returns {Blob}
     */
    dataURLtoBlob(dataurl: any) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {
            type: mime
        });
    }

	resetForm() {
    	window.location.reload();	
	}
	
    /**
     * Salva Imóvel
     * @returns {any}
     */
    save() {

        if(this.saving)
            return false;

        this.saving = true;

        if (!this.countImages()) {
            this.loadimages = "É preciso enviar ao menos 1 foto.";
            this.saving = false;
            return false;
        }

        if (this.imagesUploading()) {
            this.loadimages = "Aguarde um momento. Suas imagens estão sendo enviadas para o servidor.";
            this.saving = false;
            return false;
        }

        let data = angular.copy(this.d);
        data.status = -1;
        data.finality = [];

        /**
         * Finalidade
         */
        if(this.finality === 0){
            data.finality.push({
                id: 0,
                value: Number(this.sale_price) * 100
            });
        }else if(this.finality === 1){
            data.finality.push({
                id: 1,
                value: Number(this.rent_price) * 100
            });
        }else if(this.finality === 2){
            data.finality.push({
                id: 0,
                value: Number(this.sale_price) * 100
            });

            data.finality.push({
                id: 1,
                value: Number(this.rent_price) * 100
            });
        }

        if(!!data.IPTU){
            data.IPTU = data.IPTU*100;
        }

        if(!!data.townhouse_price){
            data.townhouse_price = data.townhouse_price*100;
        }

        /**
         * Composição
         */
        let composition = [];
        for (let i in data.composition) {
            if(data.composition[i].value != 0){
                composition.push(data.composition[i]);
            }
        }
        data.composition = composition;

        /**
         * Images
         */
        data.images = [];
        for (let i in this.photos) {

            if(!this.photos[i].error && !this.photos[i].remove) {
                if(!this.photos[i].rot) this.photos[i].rot = 0;

                let image = {
                    image: this.photos[i].image,
                    rotate: this.photos[i].rot
                };

                data.images.push(image);
            }
        }

        this.$rootScope.loadingPage = true;

        this.propertiesService
            .create(data)
            .then(response => {
                this.$rootScope.loadingPage = false;
                this.saving = false;
                this.success = true;
            }, err => {
                this.$rootScope.loadingPage = false;
                this.saving = false;
                alert('Houve um erro ao adicionar seu imóvel! Entre em contato conosco.');
            });
    }

    /**
     * Only Dev
     */
    initForm(){
        this.d.address = "";
        this.d.contact_phones = [{
            "type":"",
            "number":"",
            "operator":""}
        ];
        this.d.contact_name = "";
        this.d.contact_email = "";
        this.d.IPTU = 100;
        this.d.townhouse_price = 120;
        this.d.type = 4;
        this.d.subtype = [1,5,3,8];
        this.d.benefits = [1,2,8,9,12,13,21,22];
        this.d.townhouse_name = "Condominio";
        this.d.financing = 1;
        this.d.area_useful = 2133;
        this.d.area_total = 12323;
        this.d.description = "asdassda";
        this.d.CEP = "";
        this.d.city = "";
        this.d.neighborhood = "";
        this.d.estate = "SP";
        this.d.number = "21";
        this.d.zone = "leste";
        this.d.finality = [
            {
                "id":0,
                "value":1211200
            }
        ];

        this.sale_price = '200000';
        this.photos = [
            {
                "id":0,
                "file":null,
                "remove":false,
                "error":false,
                "load":true,
                "loading":false,
                "currentValue":100,
                "maxValue":100,
                "loading_web":true,
                "src": "https://s3-sa-east-1.amazonaws.com/bdi-image-dev/properties/1/f3faa03015406506609245.small.jpg",
                "image": "https://s3-sa-east-1.amazonaws.com/bdi-image-dev/properties/1/f3faa03015406506609245.small.jpg"
            }
        ];

    };
}

angular
    .module('app')
    .controller('PropertyRegisterController', PropertyRegisterController);
