class Routes {
    static $inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

    routes: Array<angular.ui.IState> = [
        {
            name: 'root',
            abstract: true,
            resolve: {
                // realestate: [
                //     'helpersService',
                //     function (helpersService) {
                //         return helpersService.realestate();
                //     }],
                // info: [
                //     'helpersService',
                //     function (helpersService) {
                //         return helpersService.info();
                //     }],
            },
            controller: 'RootController',
            data : {
                meta : {
                    disableUpdate :  true
                }
            }
        },

        // index.php
        {
            name: 'root.index',
            url: '/index.php',
            template: '',
            redirectTo: 'root.home',
        },
        // Home
        {
            name: 'root.home',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/home.php',
            url: '/',
            controller: 'HomeController',
            controllerAs: 'vm',
            resolve: {
                homeData: [
                    'pagesService',
                    function (pagesService) {
                        return pagesService.home();
                    }],
            }
        },
		{
			name: 'root.zona-leste',
			url: '/imoveis-na-zona-leste',
			templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/zona-leste.php',
		},
        {
            name: 'root.home2',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/home.php',
            url: '/casas-sobrados-apartamentos',
            controller: 'HomeController',
            controllerAs: 'vm',
            data: {
                finality: 'sale',
                meta: {
                    'title': 'Imóveis em São Miguel Paulista, casas, sobrados, apartamentos e comércios. Página especializada no bairro.',
                    'image': 'themes/altopedroso/images/shared-image2.png',
                }
            },
            resolve: {
                homeData: [
                    'pagesService',
                    function (pagesService) {
                        return pagesService.home();
                    }],
            }
        },

        // PropertyView
        {
            name: 'root.propertyView',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-view.php',
            url: '/imoveis-{all:.+}-id-{cod:.+}',
            controller: 'PropertiesViewController',
            controllerAs: 'vm',
            resolve: {
                property: [
                    'propertiesService',
                    '$stateParams',
                    function (propertiesService, $stateParams) {
                        return propertiesService.get($stateParams.cod);
                    }],
            }
        },
        {
            name: 'root.propertyViewOld',
            url: '/imovel/{all:.+}id-{cod:.+}',
            controller: ($stateParams, $scope, $location, $injector) => {

                let state = $injector.get('$state');
                state.go('root.propertyView', {
                    all: '/',
                    cod: $stateParams.cod
                });
                return $location.path();
            },
        },
        {
            name: 'root.propertyViewOld2',
            url: '/imovel/id-{cod:.+}',
            controller: ($stateParams, $scope, $location, $injector) => {

                let state = $injector.get('$state');
                state.go('root.propertyView', {
                    all: '/',
                    cod: $stateParams.cod
                });
                return $location.path();
            },
        },


        // Search
        {
            name: 'root.searchProperties',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
            url: '/{finality:venda|locacao}{all:.*}',
            controller: 'PropertiesSearchController',
            controllerAs: 'vm',
            data: {
                finality: 'sale'
            },
            resolve: {
                properties: [
                    'propertiesService',
                    '$stateParams',
                    function (propertiesService, $stateParams) {
                        let params = propertiesService.getParams($stateParams.finality, $stateParams.all);
                        return propertiesService.search(params);
                    }],
            }
        },

        // Casa
        {
            name: 'root.searchPropertiesCasa',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
            url: '/casas-em-sao-miguel-paulista',
            controller: 'PropertiesSearchController',
            controllerAs: 'vm',
            data: {
                finality: 'sale',
				title: 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP.',
				description: 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP. Casas com Piscina, do Popular ao Alto Padrão, é na Alto Pedroso Imóveis. Acesse:',
                meta: {
                    'title': 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP.',
                    'description': 'Casas à venda em São Miguel Paulista – na Zona Leste - São Paulo - SP. Casas com Piscina, do Popular ao Alto Padrão, é na Alto Pedroso Imóveis. Acesse:',
                    'keywords': 'Comprar,Casas,São Miguel Paulista,Sobrado em Condomínio para Venda no bairro Vila Nova Curuçá, 3 dorm, 1 suíte, 2 vagas, 90 m,Sobrado com 2 dormitórios à venda, 65 m² por R$ 230.000,00 - São Miguel Paulista - São Paulo/SP,Alto Pedroso,Propriedades,Imóveis'
                }
            },
            resolve: {
                properties: [
                    'propertiesService',
                    '$stateParams',
                    function (propertiesService, $stateParams) {

                        let params = propertiesService.getParams('venda', '/t-casa/l-sp,sao-paulo,sao-miguel-paulista/');
                        return propertiesService.search(params);
                    }],
            }
        },

        // Apartamento
        {
            name: 'root.searchPropertiesApartamento',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
            url: '/apartamentos-em-sao-miguel-paulista',
            controller: 'PropertiesSearchController',
            controllerAs: 'vm',
            data: {
                finality: 'sale',
				title: 'Apartamentos à venda em São Miguel Paulista, os melhores Apartamentos em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                meta: {
                    'title': 'Apartamentos à venda em São Miguel Paulista, São Paulo - Alto Pedroso',
                    'description': 'Apartamentos à venda em São Miguel Paulista, os melhores Apartamentos em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                    'keywords': 'Comprar,Apartamentos,São Miguel Paulista,Apartamento para venda com 47 m², 2 quartos, 1 vaga,Apartamento 40mts a Venda Com 2 Dormitórios Com Vaga de Garagem,Alto Pedroso,Propriedades,Imóveis',
                }
            },
            resolve: {
                properties: [
                    'propertiesService',
                    '$stateParams',
                    function (propertiesService, $stateParams) {

                        let params = propertiesService.getParams('venda', '/t-apartamento/l-sp,sao-paulo,sao-miguel-paulista/');
                        return propertiesService.search(params);
                    }],
            }
        },

        // comercios
        {
            name: 'root.searchPropertiesComercio',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
            url: '/comercios-em-sao-miguel-paulista',
            controller: 'PropertiesSearchController',
            controllerAs: 'vm',
            data: {
                finality: 'sale',
				title: 'Comerciais,Loja de Shopping/Centro Comercial à venda em São Miguel Paulista, é na Alto Pedroso Imóveis, imobiliária especializada, use seu FGTS, financiamento rápido.',
                meta: {
                    'title': 'Comércios à venda em São Miguel Paulista, os melhores Comércios em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                    'description': 'Comerciais,Loja de Shopping/Centro Comercial à venda em São Miguel Paulista, é na Alto Pedroso Imóveis, imobiliária especializada, use seu FGTS, financiamento rápido.',
                    'keywords': 'Temos 23 anúncios de loja de shopping/centro comercial em São Miguel Paulista , São Paulo na Alto Pedroso. Também temos outros tipos de imóveis que podem interessá-lo. Utilize nossos filtros de pesquisa e encontre o imóvel do seus sonhos!',
                }
            },
            resolve: {
                properties: [
                    'propertiesService',
                    '$stateParams',
                    function (propertiesService, $stateParams) {

                        let params = propertiesService.getParams('venda', '/t-comercial/l-sp,sao-paulo,sao-miguel-paulista/');
                        return propertiesService.search(params);
                    }],
            }
        },

        // sobrados
        {
            name: 'root.searchPropertiesSobrado',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-search.php',
            url: '/sobrados-em-sao-miguel-paulista',
            controller: 'PropertiesSearchController',
            controllerAs: 'vm',
            data: {
                finality: 'sale',
				title: 'Sobrados à venda em São Miguel Paulista, os melhores Sobrados em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
                meta: {
					'title': 'Sobrados à venda em São Miguel Paulista, São Paulo - Alto Pedroso',
					'description': 'Sobrados à venda em São Miguel Paulista, os melhores Sobrados em São Miguel Paulista para venda e locação, use seu FGTS na Alto Pedroso',
					'keywords': 'Na Alto Pedroso temos 1300 sobrado : venda em São Miguel Paulista , São Paulo . Utilize nossos filtros de pesquisa e encontre os melhores imóveis de São Miguel Paulista!',
                }
            },
            resolve: {
                properties: [
                    'propertiesService',
                    '$stateParams',
                    function (propertiesService, $stateParams) {

                        let params = propertiesService.getParams('venda', '/t-sobrado/l-sp,sao-paulo,sao-miguel-paulista/');
                        return propertiesService.search(params);
                    }],
            }
        },

        // Search
        {
            name: 'root.searchAdvanced',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/advanced-search.php',
            url: '/avancado',
            controller: 'SearchAdvancedController',
            controllerAs: 'vm'
        },

        // Favorites
        {
            name: 'root.favorite',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/favorite.php',
            url: '/favoritos',
            controller: 'FavoriteController',
            controllerAs: 'vm',
            resolve: {
                properties: [
                    'propertiesService',
                    '$cookies',
                    function (propertiesService, $cookies) {
                        let fv: any = $cookies.getObject('fav_'+this.settings['TOKEN']);

                        if(!fv) {
                            fv = [];
                        }

                        let params = {
                            id_in: fv
                        };

                        console.log(params);

                        return propertiesService.search(params);
                    }],
            }
        },

        // Financiamento
        {
            name: 'root.financing',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/financing.php',
            url: '/financiamentos',
            controller: (seoManager, $rootScope) => {
                seoManager.setTitle('Financiamento | ' + $rootScope.realestate.name);
                seoManager.setTitleH1('Financiamento');
            }
        },
		{
            name: 'root.partnerss',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/partnership.php',
            url: '/partnership-link-bulding-real-state-bank-big-data-infinity',
            controller: (seoManager, $rootScope) => {
                seoManager.setTitle('Financiamento | ' + $rootScope.realestate.name);
                seoManager.setTitleH1('Financiamento');
            }
        },

        // Neighborhood
        {
            name: 'root.neighborhoodPage',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/neighborhood-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g,' ');
			},
            url: '/imoveis-{neighborhood:[a-zA-Z0-9-]+}',
            controller: 'NeighborhoodPageController',
            controllerAs: 'vm',
            resolve: {
                data: [
                    'pagesService',
                    '$stateParams',
                    function (pagesService, $stateParams) {
                        let params = {
                            neighborhood: $stateParams.neighborhood.replace(/-/g,' '),
                            city: 'sao paulo',
                            estate: 'sp',
                        };

                        return pagesService.neighborhoodPage(params);
                    }],
            }
        },
		{
			name: 'root.neighborhoodApartamentosPage',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/apartamento-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g,' ');
			},
			url: '/apartamentos-{neighborhood:[a-zA-Z0-9-]+}',
			controller: 'NeighborhoodApartamentosPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: $stateParams.neighborhood.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return pagesService.neighborhoodPage(params);
					}],
			}
		},
		{
			name: 'root.neighborhoodCasasPage23',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/casa-singular-page.php?estate=sp&city=sao paulo&neighborhood=sao miguel paulista';
			},
			url: '/casa-a-venda-em-sao-miguel-paulista',
			controller: 'NeighborhoodCasasPageController2',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'sao miguel paulista',
							city: 'sao paulo',
							estate: 'sp',
						};

						return pagesService.neighborhoodPage(params);
					}],
			}
		},
		
		{
			name: 'root.neighborhoodCasasPage',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/casa-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g,' ');
			},
			url: '/casas-{neighborhood:[a-zA-Z0-9-]+}',
			controller: 'NeighborhoodCasasPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: $stateParams.neighborhood.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return pagesService.neighborhoodPage(params);
					}],
			}
		},
		{
			name: 'root.neighborhoodSobradosPage',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/sobrado-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g,' ');
			},
			url: '/sobrados-{neighborhood:[a-zA-Z0-9-]+}',
			controller: 'NeighborhoodSobradosPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: $stateParams.neighborhood.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return pagesService.neighborhoodPage(params);
					}],
			}
		},
		{
			name: 'root.neighborhoodJardimHelena',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/jardim-helena-page.php?estate=sp&city=sao paulo&neighborhood=' + 'jardim-helena'.replace(/-/g,' ');
			},
			url: '/imobiliaria-jardim-helena',
			controller: 'RealEstateNeighborhoodPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'jardim-helena'.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return [];
					}],
			}
		},
		{
			name: 'root.neighborhoodErmelinoMatarazzo',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/ermelino-matarazzo-page.php?estate=sp&city=sao paulo&neighborhood=' + 'ermelino-matarazzo'.replace(/-/g,' ');
			},
			url: '/imobiliaria-ermelino-matarazzo',
			controller: 'RealEstateNeighborhoodPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'ermelino-matarazzo'.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return [];
					}],
			}
		},
		{
			name: 'root.neighborhoodSaoMiguelPaulista',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/sao-miguel-paulista-page.php?estate=sp&city=sao paulo&neighborhood=' + 'sao-miguel-paulista'.replace(/-/g,' ');
			},
			url: '/imobiliaria-sao-miguel-paulista',
			controller: 'RealEstateNeighborhoodPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'sao-miguel-paulista'.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return [];
					}],
			}
		},
		{
			name: 'root.neighborhoodSaoMiguelPaulista2',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/sao-miguel-paulista-page.php?estate=sp&city=sao paulo&neighborhood=' + 'sao-miguel-paulista'.replace(/-/g,' ');
			},
			url: '/imobiliaria-saomiguel',
			controller: 'RealEstateNeighborhoodPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'sao-miguel-paulista'.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return [];
					}],
			}
		},
		{
			name: 'root.neighborhoodItaquera',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/itaquera-page.php?estate=sp&city=sao paulo&neighborhood=' + 'itaquera'.replace(/-/g,' ');
			},
			url: '/imobiliaria-itaquera',
			controller: 'RealEstateNeighborhoodPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'itaquera'.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return [];
					}],
			}
		},
		{
			name: 'root.neighborhoodVilaCuruca',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/vila-curuca-page.php?estate=sp&city=sao paulo&neighborhood=' + 'vila-curuca'.replace(/-/g,' ');
			},
			url: '/imobiliaria-vila-curuca',
			controller: 'RealEstateNeighborhoodPageController',
			controllerAs: 'vm',
			resolve: {
				data: [
					'pagesService',
					'$stateParams',
					function (pagesService, $stateParams) {
						let params = {
							neighborhood: 'vila-jacui'.replace(/-/g,' '),
							city: 'sao paulo',
							estate: 'sp',
						};

						return [];
					}],
			}
		},
        {
            name: 'root.neighborhoodPage3',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/realestate-page.php?estate=sp&city=sao paulo&neighborhood=' + $stateParams.neighborhood.replace(/-/g,' ');
			},
            url: '/imobiliaria-{neighborhood:[a-zA-Z0-9-]+}',
            controller: 'RealEstateNeighborhoodPageController',
            controllerAs: 'vm',
            resolve: {
                data: [
                    'pagesService',
                    '$stateParams',
                    function (pagesService, $stateParams) {
                        let params = {
                            neighborhood: $stateParams.neighborhood.replace(/-/g,' '),
                            city: 'sao paulo',
                            estate: 'sp',
                        };

                        return [];
                    }],
            }
        },

        {
            name: 'root.neighborhoodPage2',
			templateUrl: function($stateParams) {
				return /*!*/ '/themes/' + window['settings']['THEME'] + '/neighborhood-page.php?estate=' + $stateParams.estate + '&city=' + $stateParams.city + '&neighborhood=' + $stateParams.neighborhood;
			},
            url: '/imoveis/{estate}/{city}/{neighborhood}',
            controller: 'NeighborhoodPageController',
            controllerAs: 'vm',
            resolve: {
                data: [
                    'pagesService',
                    '$stateParams',
                    function (pagesService, $stateParams) {

                        let params = {
                            neighborhood: $stateParams.neighborhood.replace(/-/g,' '),
                            city: $stateParams.city.replace(/-/g,' '),
                            estate: $stateParams.estate,
                        };

                        return pagesService.neighborhoodPage(params);
                    }],
            }
        },
        {
            name: 'root.cityPage',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/neighborhood-page.php',
            url: '/imoveis/{estate}/{city}',
            controller: 'NeighborhoodPageController',
            controllerAs: 'vm',
            resolve: {
                data: [
                    'pagesService',
                    '$stateParams',
                    function (pagesService, $stateParams) {

                        let params = {
                            city: $stateParams.city.replace(/-/g,' '),
                            estate: $stateParams.estate,
                        };

                        return pagesService.neighborhoodPage(params);
                    }],
            }
        },

        // Property Register
        {
            name: 'root.propertyRegister',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-register.php',
            url: '/{page:anunciarimovel|anunciarimoveis|anunciar-imovel|cadastrar-imovel}?step',
            reloadOnSearch: false, // <--- it's a property of the state, not a transition option
            controller: 'PropertyRegisterController',
            controllerAs: 'vm',
        },

        // Property Register
        {
            name: 'root.propertyRequest',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-request.php',
            url: '/encomende-seu-imovel',
            reloadOnSearch: false, // <--- it's a property of the state, not a transition option
            controller: 'PropertyRequestController',
            controllerAs: 'vm',
        },

        // Contact
        {
            name: 'root.contact',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/contact.php',
            url: '/{page:contato|faleconosco}',
            controller: 'ContactController',
            controllerAs: 'vm',
        },
		
		// Contact
        {
            name: 'root.imobiLeste',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/imobiliaria-na-zona-leste.php',
            url: '/imobiliaria-na-zona-leste',
            controller: 'ZonalesteController',
            controllerAs: 'vm',
        },

		// Contact
		{
			name: 'root.policy',
			templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/policy.php',
			url: '/politica-de-privacidade',
			controller: (seoManager, $rootScope) => {
				seoManager.setTitle('Política de Privacidade e Termos de Uso | ' + $rootScope.realestate.name);
				seoManager.setTitleH1('Política de Privacidade e Termos de Uso');
			},
		},

        // SiteMap
        {
            name: 'root.sitemap',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/sitemap.php',
            url: '/mapa-do-site',
            controller: 'SiteMapController',
            controllerAs: 'vm',
            resolve: {
                locationData: [
                        'pagesService',
                        function (pagesService) {
                            return pagesService.siteMap();
                        }],
            }
        },

        // Pages
        {
            name: 'root.pages',
            templateProvider: function($templateFactory, $stateParams) {
                return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/' + $stateParams.page + '.php');
            },
            url: '/page/{page}',
            controller: 'PageController',
            controllerAs: 'vm',
        },

        // Compartilhar Lista
        {
            name: 'root.listsShared',
            url: '/lista/share/{target}/{properties}',
            controller: function (list, $stateParams) {

                switch ($stateParams.target){
                    case 'facebook':
                        window.location.href = 'https://www.facebook.com/share.php?u=' + 'https://' + window.location.hostname + '/lista/' + list;
                        break;
                    case 'twitter':
                        window.location.href = 'https://twitter.com/intent/tweet?status=' + 'https://' + window.location.hostname + '/lista/' + list;
                        break;
                    case 'googleplus':
                        window.location.href = 'https://plus.google.com/share?url=' + 'https://' + window.location.hostname + '/lista/' + list;
                        break;
                    case 'whatsapp_mobile':
                        window.location.href = 'whatsapp://send?text=' + 'https://' + window.location.hostname + '/lista/' + list;
                        break;
                    case 'whatsapp':
                        window.location.href = 'https://api.whatsapp.com/send?text=' + 'https://' + window.location.hostname + '/lista/' + list;
                        break;
                    case 'messenger':
                        window.location.href = 'fb-messenger://share/?link=' + 'https://' + window.location.hostname + '/lista/' + list;
                        break;
                }
            },
            resolve: {
                list: [
                    'propertiesListsService',
                    '$stateParams',
                    function (propertiesListsService, $stateParams) {
                        // $stateParams.target
                        let properties = $stateParams.properties.split(',');
                        return propertiesListsService
                            .create(properties)
                    }],
            }
        },
        // Lista
        {
            name: 'root.listsGet',
            url: '/lista/{hash}',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/lists.php',
            controller: 'ListPageController',
            controllerAs: 'vm',
            resolve: {
                list: [
                    'propertiesListsService',
                    '$stateParams',
                    function (propertiesListsService, $stateParams) {
                        return propertiesListsService.get($stateParams.hash);
                    }],
            }
        },

        // NotFound
        {
            parent: 'root',
            name: '404',
            templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/404.php',
            controller: (seoManager, $rootScope) => {
                seoManager.setTitle('Página não encontrada | ' + $rootScope.realestate.name);
                seoManager.setTitleH1('Página não encontrada');
            }
        },

        // Vantagens
        {
            name: 'root.vantagens',
            templateProvider: function($templateFactory, $stateParams) {
                return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/vantagens.php');
            },
            url: '/vantagens',
            controller: 'PageController',
            controllerAs: 'vm',
        },

		// Vantagens
		{
			name: 'root.parcerias',
			templateProvider: function($templateFactory, $stateParams) {
				return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/parcerias.php');
			},
			url: '/parcerias',
			controller: 'PageController',
			controllerAs: 'vm',
		},

        // Vantagens
        {
            name: 'root.comofunciona',
            templateProvider: function($templateFactory, $stateParams) {
                return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/comofunciona.php');
            },
            url: '/comofunciona',
            controller: 'PageController',
            controllerAs: 'vm',
        },

        // Vantagens
        {
            name: 'root.seuimovelemvarioslugares',
            templateProvider: function($templateFactory, $stateParams) {
                return $templateFactory.fromUrl('/themes/' + window['settings']['THEME'] + '/pages/seuimovelemvarioslugares.php');
            },
            url: '/seuimovelemvarioslugares',
            controller: 'PageController',
            controllerAs: 'vm',
        },

        // NotFound
        {
            name: '500',
            templateUrl: /*!*/ '/error-500.php',
            controller: (seoManager, $rootScope) => {
                seoManager.setTitle('Página não encontrada | ' + $rootScope.realestate.name);
                seoManager.setTitleH1('Página não encontrada');
            }
        },
    ];

    constructor($stateProvider: angular.ui.IStateProvider, $urlProvider: angular.ui.IUrlRouterProvider, $locationProvider) {
		if(window['settings']['realestate']['id'] == 319)
		{
			$stateProvider.state(this.routes[0])
	
			$stateProvider.state({
				name: 'root.propertyRegister',
				templateUrl: /*!*/ '/themes/' + window['settings']['THEME'] + '/properties-register.php',
				url: '/',
				reloadOnSearch: false, // <--- it's a property of the state, not a transition option
				controller: 'PropertyRegisterController',
				controllerAs: 'vm',
			});
	
			$urlProvider.otherwise(function ($injector, $location) {
				let state = $injector.get('$state');
				state.go('root.propertyRegister');
				return $location.path();
			});
		} else {
			for (let i in this.routes){
			    $stateProvider.state(this.routes[i]);
			}

			$urlProvider.otherwise(function($injector, $location){
			    let state = $injector.get('$state');
			    state.go('404');
			    return $location.path();
			});
		}

        // $urlProvider.otherwise('/');

        $locationProvider.html5Mode(true);
    }
}

angular.module("app").config(Routes);
