function Phone() {
    return function (number) {
        if(isNaN(number)) {
            return number;
        }else {
            number = Helpers.getNumbers(number).toString();

            let string = number.replace(/^(\d{2})(\d)/g,"($1) $2");
            string = string.replace(/(\d)(\d{4})$/,"$1-$2");

            return string;
        }
    };
}

angular
    .module('app')
    .filter('phone', Phone);
