class ngEnter implements ng.IDirective {

    static $inject = [];

    constructor() {}

    link(scope, elem, attrs, ctrl) {
        elem.bind("keydown keypress", function(event) {
            if(event.which === 13) {
                scope.$apply(function(){
                    scope.$eval(attrs.ngEnter, {'event': event});
                });

                event.preventDefault();
            }
        });
    }

    static factory(): ng.IDirectiveFactory {
        return () => new ngEnter()
    }
}

angular
    .module('app')
    .directive('ngEnter', ngEnter.factory());
