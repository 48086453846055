class NeighborhoodPageController {

    public static $inject: string[] = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];

    settings: any = window['settings'];
    title: any;
    description: any;

    data: any;
    $rootScope: any;
    $stateParams: any;
    $filter: any;

    location: any;
    properties: any;
    aggrs: any;
    url_sale: string;
    url_rent: string;
    pageCity: boolean;

	isCustom = false;
	
    constructor(data, $filter, $rootScope, seoManager, $stateParams) {
        this.$rootScope = $rootScope;
		
        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
		
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);

        let description = '';

		if (this.pageCity) {
			this.title = 'Imóveis ' + this.location.city + ', Apartamentos, Casas e Sobrados.';
			this.$rootScope.title_page = 'Imóveis ' + this.location.city;
			description = "Imóveis " + this.location.city + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.city + ".";
		} else {
			this.title = 'Imóveis ' + this.location.neighborhood + ', Apartamentos, Casas e Sobrados.';
			this.$rootScope.title_page = 'Imóveis ' + this.location.neighborhood;
			description = "Imóveis " + this.location.neighborhood + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.neighborhood + ".";
		}
		
		if (this.location.neighborhood === 'Itaim Paulista') {
			this.title = 'Imobiliária no Itaim Paulista, ZL, SP, Imóveis em Toda Zona Leste de SP';
			description = 'Imobiliária no Itaim Paulista | Alto Pedroso, ZL, SP, Contamos com Milhares de Imóveis em Toda Zona Leste Apartamentos, Casas, Sobrados. Use o seu FGTS.';
			this.isCustom = true;
		}
		
		if (this.location.neighborhood === 'Guaianases') {
			this.title = 'Imobiliária em Guaianases, Lista de Imóveis em Guaianases.';
			description = 'Imobiliária em Guaianases - Guia de Imóveis em Guaianases, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis?';
			this.isCustom = true;
		}


		if (this.location.neighborhood === 'Vila Jacuí') {
			this.title = 'Imobiliária na Vila Jacuí, Lista de Imóveis na Vila Jacuí.';
			description = 'Imobiliária na Vila Jacuí - Guia de Imóveis na Vila Jacuí, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis?';
			this.isCustom = true;
		}
		
		if (this.location.neighborhood === 'Parque Paulistano ') {
			this.title = 'Imóveis no Parque Paulistano, São Miguel Paulista ZL - Alto Pedroso';
			description = 'Procurando Imóveis no Parque Paulistano, São Miguel Paulista ZL? Vem para a Alto Pedroso Imóveis, empresa melhor avaliada do bairro, sede própria.·.';
			this.isCustom = true;
		}
		
		if (this.location.neighborhood === 'Ponte Rasa') {
			this.title = 'Imobiliária na Ponte Rasa, São Paulo, Zona Leste, SP - Alto Pedroso.·.';
			description = 'Imobiliária na Ponte Rasa, São Paulo, Zona Leste, SP - Apartamentos, Casas, Sobrados e Imóveis Comerciais. Alto Pedroso Imóveis, Especialista no Bairro.';
			this.isCustom = true;
		}
		
		if (this.location.neighborhood === 'Ermelino Matarazzo') {
			this.title = 'Imobiliária em Ermelino Matarazzo Guia Imóveis em Ermelino Matarazzo.';
			description = 'Imobiliária em Ermelino Matarazzo - Lista de Imóveis em Ermelino Matarazzo, ofertas de imóveis de corretores e Imobiliárias em Ermelino Matarazzo - SP';
			this.isCustom = true;
		}
		
		this.description = description;
		

        let url = $rootScope.URL + '/' + data.url;

        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }

    /**
     * Compartilhar por Email
     * @returns {any}
     */
    sharedEmail() {
        let location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);

        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    }
}

angular
    .module('app')
    .controller('NeighborhoodPageController', NeighborhoodPageController);
