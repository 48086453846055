class NeighborhoodCasasPageController2 {

    public static $inject: string[] = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];

    settings: any = window['settings'];
    title: any;

    data: any;
    $rootScope: any;
    $stateParams: any;
    $filter: any;

    location: any;
    properties: any;
    aggrs: any;
    url_sale: string;
    url_rent: string;
    pageCity: boolean;

    constructor(data, $filter, $rootScope, seoManager, $stateParams) {
        this.$rootScope = $rootScope;

        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);

        let description = '';

		this.title = 'Casa a Venda em São Miguel Paulista. ZL, São Paulo - SP, Alto Pedroso.';
		this.$rootScope.title_page = 'Casa ' + this.location.city;
		description = "Casa a venda em São Miguel Paulista. ZL, São Paulo - SP, compre da imobiliária melhor avaliada em São Miguel Paulista e da Zona Leste, SP. Alto Pedroso.";
		

        let url = $rootScope.URL + '/' + data.url;

        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }

    /**
     * Compartilhar por Email
     * @returns {any}
     */
    sharedEmail() {
        let location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);

        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    }
}

angular
    .module('app')
    .controller('NeighborhoodCasasPageController2', NeighborhoodCasasPageController2);
