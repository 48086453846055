class ZonalesteController {

    public static $inject: string[] = [
        '$rootScope',
        'seoManager',
    ];

    settings: any = window['settings'];

    constructor(
        $rootScope,
        seoManager
        ){
        seoManager.setTitle('Imobiliária na Zona Leste - Todos os Bairros e Imóveis na Zona Leste.·.');
        seoManager.setTitleH1('Imobiliária na Zona Leste - Todos os Bairros e Imóveis na Zona Leste.·.');
        seoManager.setUrl('/imobiliaria-na-zona-leste', true);

		seoManager.setDescription('Imobiliária na Zona Leste - Guia de Bairros e Imóveis na Zona Leste - SP, Apartamentos, Casas, Sobrados, Comércios, Locação, Venda, Milhares de Imóveis');
    }
}

angular
    .module('app')
    .controller('ZonalesteController', ZonalesteController);
