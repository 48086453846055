class FavoriteButton implements ng.IDirective {

    static $inject = ['$cookies'];

    $cookies: any;
    settings: any = window['settings'];

    restrict = 'A';
    require = 'ngModel';
    scope = {
        ngModel: '=',
        ngId: '<',
        ngHash: '@'
    };

    constructor($cookies: any) {
        this.$cookies = $cookies;
    }

    link(scope, element, attrs, ctrl) {

        scope.ngModel = false;
        if(typeof this.$cookies.getObject('fav_'+this.settings['TOKEN']) !== 'undefined'){
            let fv = this.$cookies.getObject('fav_'+this.settings['TOKEN']);

            if(fv.indexOf(Number(scope.ngId)) !== -1){
                scope.ngModel = true;
            }
        }

        let that = this;

        element.bind('click', function(e) {
            e.preventDefault(); // prevent button default behavior
            e.stopPropagation(); // prevent propagation of your event to the link

            if(typeof that.$cookies.getObject('fav_'+that.settings['TOKEN']) === 'undefined'){
                let fv = [];
                fv.push(scope.ngId);
                scope.ngModel = true;
                that.$cookies.putObject('fav_'+that.settings['TOKEN'], fv);
            }else{
                let fv = that.$cookies.getObject('fav_'+that.settings['TOKEN']);

                if(fv.indexOf(scope.ngId) === -1){
                    fv.push(scope.ngId);
                    scope.ngModel = true;
                }else{
                    fv.splice(fv.indexOf(scope.ngId), 1);
                    scope.ngModel = false;
                }

                that.$cookies.putObject('fav_'+that.settings['TOKEN'], fv);
            }
            scope.$apply();
        });

    }

    static factory() {
        return [
            '$cookies',
            ($cookies) => new FavoriteButton($cookies)
        ];
    }
}

angular
    .module('app')
    .directive('favButton', FavoriteButton.factory());
