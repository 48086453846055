class FavoriteController {

    public static $inject: string[] = [
        'properties',
        '$cookies',
        'seoManager',
        'propertiesListsService',
        '$rootScope',
    ];

    properties: any;
    $cookies: any;
    $rootScope: any;
    seoManager: any;
    propertiesListsService: any;
    settings: any = window['settings'];
    selected: Array<any> = [];
    list: string;

    constructor(properties, $cookies, seoManager, propertiesListsService, $rootScope) {
        this.properties = properties.results;
        this.$cookies = $cookies;
        this.seoManager = seoManager;
        this.$rootScope = $rootScope;
        this.propertiesListsService = propertiesListsService;

        this.seoManager.setTitle('Meus favoritos | ' + $rootScope.realestate.name);
        this.seoManager.setTitleH1('Meus favoritos');
        seoManager.setUrl('/favoritos', true);

        this.deselectAll();
    }

    sharedEmail() {
        this.$rootScope.loading = true;

        return this.propertiesListsService
            .create(this.list.split(','))
            .then(list => {
                    this.$rootScope.loading = false;
                    this.$rootScope.target_email = list;
                    this.$rootScope.type_email = 'properties_lists';
                    this.$rootScope.shareEmailModal = true;
                },
                () => {
                    this.$rootScope.loading = false;
                });
    }

    change(id, checked) {

        if (this.selected.indexOf(id) === -1) {
            this.selected.push(id);
        } else {
            this.selected.splice(this.selected.indexOf(id), 1);
        }

        this.list = this.selected.join(',');

        if (this.selected.length == 0) {
            let selected_t = [];
            for (let i in this.properties) {
                selected_t.push(this.properties[i].id);
            }
            this.list = selected_t.join(',');
        }

    }

    selectAll() {

        this.selected = [];

        for (let i in this.properties) {
            this.selected.push(this.properties[i].id);
            this.properties[i].checked = true;
        }

        this.list = this.selected.join(',');
    }

    deselectAll() {

        this.selected = [];
        let selected_t = [];

        for (let i in this.properties) {
            selected_t.push(this.properties[i].id);
            this.properties[i].checked = false;
        }

        this.list = selected_t.join(',');
    }

    removeAll() {
        this.$cookies.putObject('fav_' + this.settings['TOKEN'], []);
        this.properties = [];
    }

}

angular
    .module('app')
    .controller('FavoriteController', FavoriteController);
