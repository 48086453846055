class pagesService {

    static $inject = ['$http'];

    http: any;
    settings: any = window['settings'];

    constructor($http: any) {
        this.http = $http;
    }

    home() {
    	let Home = Object.keys(window['home']);
    	
    	if(!Home.length) {
			return this
				.http
				.get(this.settings['API'] + '/realestate/page/home');	
		}else {
			return {data: window['home']};	
		}
		
    }

    neighborhoodPage(body) {
        return this.http({
            url: this.settings['API'] + '/realestate/page/location-page',
            method: "POST",
            data: body
        }).then((response) => {
            return response.data.body;
        });
    }

    siteMap() {
        return this
            .http
            .get(this.settings['API'] + '/realestate/page/site-map');
    }
}

angular
    .module('app')
    .service('pagesService', [...pagesService.$inject, pagesService]);
