class FormsManager implements ng.IDirective {

    static $inject = ['$http', 'ModalManager'];

    http: any;
    modalManager: any;
    settings: any = window['settings'];

    scope = {};
    bindToController = {
        'noModalSuccess': '<?',
        'formPropertyId': '<',
        'actionSuccess': '&?',
        'formName': '@',
        'update': '=?',
        'ngInit': '@?',
    };
    transclude = true;
    template = '';
    controllerAs = 'formsManager';

    constructor($http: any, modalManager: ModalManager) {
        this.http = $http;
        this.modalManager = modalManager;
    }

    link(scope, element, attrs, controller, transclude){

        let boundObj = {};
        let alias = attrs.alias;

        let newScope = scope.$new(scope.controller); // isolate
        newScope[alias] = boundObj;

        transclude(newScope, function(clone){
            element.append(clone);
        });

        ///
        scope.log = function(){
            console.log(boundObj);
        };
    }

    controller = [
        '$scope',
        '$rootScope',
        '$timeout',
        'formsService',
        'ModalManager',
        this.controllerF
    ];

    controllerF($scope, $rootScope, $timeout, formsService, modalManager) {

        let vm: any = this;

        vm.processing = false;
        vm.form = {};
        vm.validate = false;
        vm.d = {};

        // vm.modalManager.openFailed();
        console.log(vm.modalManager);

        vm.addPhone = function() {
            vm.d.phones.push({});
        };

        vm.removePhone = function(index){
            vm.d.phones.splice(index, 1);
        };

        vm.toggleCheckbox = function(key, value) {
            if(!vm.d[key]){
                vm.d[key] = [];
            }

            if(vm.d[key].indexOf(value) == -1){
                vm.d[key].push(value);
            }else{
                vm.d[key].splice(vm.d[key].indexOf(value), 1);
            }
        };

        vm.submit = function () {
            console.log(vm.form.$invalid);

            if(vm.form.$invalid){
                vm.validate = true;
                return false;
            }

            vm.validate = false;

            // Não executar a acão diversas vezes
            if(vm.processing) return;
            vm.processing = true;

            $rootScope.loadingPage = true;
            var that = this;

            return formsService
                .update(vm.formName, vm.d)
                .then((response) => {
                    console.log(response);

                    $rootScope.loadingPage = false;
                    vm.processing = false;

                    if(response.data.success){
                        console.log(vm.noModalSuccess);
                        if(!vm.noModalSuccess){
                            modalManager.openSuccess();
                        }

                        if(!!vm.actionSuccess){
                            $scope.$eval(vm.actionSuccess);
                        }

                        vm.success = true;
                    }else{
                        if(!!vm.actionSuccess) {
                            modalManager.openFailed();
                        }
                    }
                }, (err) => {
                    vm.processing = false;
                    $rootScope.loadingPage = false;
                    modalManager.openFailed();
                });

        };

        vm.$onInit = function () {

            vm.d.property_id = vm.formPropertyId;

            vm.update = function(data) {
                for(let i in data){
                    vm[i] = data[i];
                }
            };

            if(vm.ngInit){
                $timeout(function () {
                    $scope.$apply(function () {
                        $scope.$eval(vm.ngInit);
                    });
                }, 1);
            }
        };
    }

    static factory() {
        return [
            '$http',
            'ModalManager',
            ($http, ModalManager) => new FormsManager($http, ModalManager)
        ];
    }
}

angular
    .module('app')
    .directive('formsManager', FormsManager.factory());
