class RootController {

    public static $inject: string[] = [
        '$rootScope',
        '$window',
        /*'realestate',
        'info',*/
        'seoManager'
    ];

    settings: any = window['settings'];

    constructor(
        $rootScope,
		$window,
        // realestate,
        // info,
        seoManager
        ){
        // $rootScope.realestate = realestate.data.body;
        // $rootScope.info = info.data.body;
		
        $rootScope.realestate.full_address = '';

        if ($rootScope.realestate.address) $rootScope.realestate.full_address += $rootScope.realestate.address + ' ';
        if ($rootScope.realestate.number) $rootScope.realestate.full_address += $rootScope.realestate.number + ' - ';
        if ($rootScope.realestate.neighborhood) $rootScope.realestate.full_address += $rootScope.realestate.neighborhood + ',';
        if ($rootScope.realestate.city) $rootScope.realestate.full_address += $rootScope.realestate.city + ' - ';
        if ($rootScope.realestate.estate) $rootScope.realestate.full_address += $rootScope.realestate.estate + ' - ';
        if ($rootScope.realestate.cep) $rootScope.realestate.full_address += $rootScope.realestate.cep + '';

		if($rootScope.realestate.id == 319) {
			$rootScope.anunciar = true;
		}
		
        if($rootScope.realestate.id == 73) {
            $rootScope.realestate.manyUnits =  [
                {
                    name: 'Unidade 1',
                    full_address: 'Rua Severina Leopoldina de Souza, 220 - CEP 08010-310 (Atrás do Mercado Municipal de São Miguel Paulista)'
                },
                {
                    name: 'Unidade 2',
                    full_address: 'Rua Serra de Botucatú, 1940 - CEP 03317-001 (Próx. Quinta do Espeto)'
                }
            ];
        }

        $rootScope.realestate.settings.COLOR_PRIMARY = '';
        $rootScope.realestate.settings.COLOR_SECUNDARY = '';

        if ($rootScope.realestate.settings.COLOR_PRIMARY == '' || !$rootScope.realestate.settings.COLOR_PRIMARY) {
            $rootScope.realestate.settings.COLOR_PRIMARY = '#000000';
        }

        if ($rootScope.realestate.settings.COLOR_SECUNDARY == '' || !$rootScope.realestate.settings.COLOR_SECUNDARY) {
            $rootScope.realestate.settings.COLOR_SECUNDARY = '#074a96';
        }

		if ($rootScope.realestate.id == '893') {
			$rootScope.realestate.settings.COLOR_PRIMARY = '#eeeeee';
			$rootScope.realestate.settings.COLOR_SECUNDARY = '#F12858';
		}

        $rootScope.realestate.settings.FONT_COLOR_PRIMARY = Helpers.colorTextOverColor(Helpers.hexToRgb($rootScope.realestate.settings.COLOR_PRIMARY));
        $rootScope.realestate.settings.FONT_COLOR_SECUNDARY = Helpers.colorTextOverColor(Helpers.hexToRgb($rootScope.realestate.settings.COLOR_SECUNDARY));

        if($rootScope.realestate.settings.COLOR_PRIMARY.indexOf('#') === -1){
            $rootScope.realestate.settings.COLOR_PRIMARY = '#' + $rootScope.realestate.settings.COLOR_PRIMARY;
        }
        if($rootScope.realestate.settings.COLOR_SECUNDARY.indexOf('#') === -1){
            $rootScope.realestate.settings.COLOR_SECUNDARY = '#' + $rootScope.realestate.settings.COLOR_SECUNDARY;
        }

        let typesSearch = [];

        for (let i in $rootScope.info.properties.types) {
            let item = $rootScope.info.properties.types[i];

            typesSearch.push(
                {
                    id: item.id,
                    value: item.name.toLowerCase(),
                    name: item.name
                });

            if(item.name == 'Sobrado'){
                typesSearch.push(
                    {
                        id: item.id,
                        value: 'casa,sobrado',
                        name: 'Casa/Sobrado'
                    });
            }
        }

        $rootScope.info.properties.typesSearch = typesSearch;

        seoManager.setTitle($rootScope.realestate.settings.SEO_TITLE);
        seoManager.setDescription($rootScope.realestate.settings.SEO_DESCRIPTION);
        seoManager.setKeywords($rootScope.realestate.settings.SEO_KEYWORDS);
		seoManager.setUrl(window.location.href);

		var link = angular.element('<link>');
		link.attr('rel', 'canonical');
		link.attr('href', window.location.href.replace('www.', ''));

		angular.element('head').append(link);
    }
}

angular
    .module('app')
    .controller('RootController', RootController);
