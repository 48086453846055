class CrudManager {

    static $inject = [
        '$http',
        '$rootScope',
    ];

    http: any;
    $rootScope: any;
    settings: any = window['settings'];
    API: string;
    path: string = '';

    constructor($http: any, $rootScope: any) {
        this.http = $http;
        this.$rootScope = $rootScope;
        this.API = this.settings.API;
    }

    /**
     * Resgatar recurso
     * @param {number} id
     * @returns {Observable<any>}
     */
    get(id) {
        return this.http
            .get(this.API + this.path + '/' + id)
            .then((response) => {
                return response.data.body;
            });
    }

    /**
     * Criar recurso
     * @param body
     * @returns {Observable<any>}
     */
    create(body: any) {
        return this.http({
            url: this.API + this.path,
            method: "POST",
            data: body
        })
        .then((response) => {
            return response.data.body;
        });
    }

    /**
     * Atualizar recurso
     * @param {number} id
     * @param body
     * @returns {Observable<any>}
     */
    update(id: number, body: any) {
        return this.http({
            url: this.API + this.path + '/' + id,
            method: "POST",
            data: body
        })
    }

    /**
     * Buscar recursos
     * @param body
     * @returns {Observable<any>}
     */
    search(body: any) {
        if(!body){
            body = {};
        }

        let params = {};
        params['query'] = {};
        params['filters'] = body;

        if (body['sort']) {
            params['query']['sort'] = body.sort;
            params['filters']['sort'] = undefined;
        }

        if (body['limit']) {
            params['query']['limit'] = body.limit;
            params['filters']['limit'] = undefined;
        }

        params['query']['limit'] = 20;

        if (body['offset']) {
            params['query']['offset'] = body.offset;
            params['filters']['offset'] = undefined;
        }

        return this.http({
            url: this.API + this.path + '/search',
            method: "POST",
            data: params
        })
        .then((response) => {
            return response.data.body;
        });
    }
}

angular
    .module('app')
    .service('CrudManager', [...CrudManager.$inject, CrudManager]);

