class ListPageController {

    public static $inject: string[] = [
        'list',
        'seoManager',
        '$rootScope',
        '$stateParams',
    ];

    settings: any = window['settings'];

    properties: any;
    hash: any;

    constructor(list, seoManager, $rootScope, $stateParams) {
        this.properties = list.properties.results;
		this.hash = $stateParams.hash; 
        seoManager.setTitle('Lista de Imóveis | ' + $rootScope.realestate.name);
        seoManager.setUrl('/lista/' + $stateParams.hash, true);
        seoManager.setTitleH1('Lista de Imóveis');
    }

}

angular
    .module('app')
    .controller('ListPageController', ListPageController);
