function Price() {
    return function (number) {
        if(isNaN(number)) {
            return number;
        }else {
            number = number/100.0;
            return Helpers.number_format(number);
        }
    };
}

angular
    .module('app')
    .filter('price', Price);
