class NeighborhoodSobradosPageController {

    public static $inject: string[] = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];

    settings: any = window['settings'];
    title: any;

    data: any;
    $rootScope: any;
    $stateParams: any;
    $filter: any;

    location: any;
    properties: any;
    aggrs: any;
    url_sale: string;
    url_rent: string;
    pageCity: boolean;

    constructor(data, $filter, $rootScope, seoManager, $stateParams) {
        this.$rootScope = $rootScope;

        this.data = data;
        this.location = data.location;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.properties = data.properties.results;
        this.aggrs = data.properties.aggrs;
        this.pageCity = !this.location.neighborhood;
        this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_sale = $filter('convertUrl')(this.url_sale);
        this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        this.url_rent = $filter('convertUrl')(this.url_rent);

        let description = '';

		if (this.pageCity) {
			this.title = 'Sobrados ' + this.location.city + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
			this.$rootScope.title_page = 'Sobrados ' + this.location.city;
			description = "Procurando sobrados " + this.location.city + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
		} else {
			this.title = 'Sobrados ' + this.location.neighborhood + ' à venda e locação São Paulo SP Alto Pedroso Imóveis.';
			this.$rootScope.title_page = 'Sobrados ' + this.location.neighborhood;
			description = "Procurando sobrados " + this.location.neighborhood + ", para comprar, vender ou alugar? A Alto Pedroso Imóveis, imobiliária melhor avaliada, pode te ajudar. ";
		}
		

        let url = $rootScope.URL + '/' + data.url;

        seoManager.setTitle(this.title);
        seoManager.setUrl(url);
        seoManager.setDescription(description);
    }

    /**
     * Compartilhar por Email
     * @returns {any}
     */
    sharedEmail() {
        let location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);

        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    }
}

angular
    .module('app')
    .controller('NeighborhoodSobradosPageController', NeighborhoodSobradosPageController);
