class ModalManager {

    static $inject = ['$rootScope'];

    $rootScope: any;
    settings: any = window['settings'];

    constructor($rootScope: any) {
        this.$rootScope = $rootScope;
    }

    /**
     * Abrir Modal de Erro
     * @param class_name
     */
    openFailed(class_name?: string) {
        if(class_name === undefined || class_name === ''){
            class_name = 'default';
        }

        console.log(this.$rootScope);

        this.$rootScope.openModalFailed = {
            open: true,
            title: 'Ops, algo aconteceu!',
            body: 'Houve um erro ao executar estão ação, tente novamente mais tarde!'
        };
    }

    /**
     * Abrir Modal de Sucesso
     * @param class_name
     */
    openSuccess(class_name?: string) {
        if(class_name === undefined || class_name === ''){
            class_name = 'default';
        }

        console.log(this.$rootScope);

        this.$rootScope.openModalSuccess = {
            open: true,
            title: 'Ops, algo aconteceu!',
            body: 'Houve um erro ao executar estão ação, tente novamente mais tarde!'
        };
    }

}

angular
    .module('app')
    .service('ModalManager', [...ModalManager.$inject, ModalManager]);

