class PageController {

    public static $inject: string[] = [
        'seoManager',
        '$rootScope',
        '$stateParams',
        '$anchorScroll',
    ];

    settings: any = window['settings'];
    seoManager: any;
    $rootScope: any;
    $stateParams: any;
	$anchorScroll: any;

    title: string;
    title_h1: string;

    constructor(seoManager, $rootScope, $stateParams, $anchorScroll) {
        this.seoManager = seoManager;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$anchorScroll = $anchorScroll;
    }

    init() {
        this.seoManager.setTitle(this.title + ' | ' + this.$rootScope.realestate.name);
        this.seoManager.setTitleH1(this.title_h1);
        this.seoManager.setUrl('/page/'+this.$stateParams.page, true);
		this.$anchorScroll();
		
		setTimeout(()=> {
			this.$anchorScroll();	
		}, 2000);
    }
}

angular
    .module('app')
    .controller('PageController', PageController);
