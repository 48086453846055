class ClickOutside implements ng.IDirective {

    static $inject = [];

    settings: any = window['settings'];

    scope = {
        'clickOutside': '&'
    };

    constructor() {
    }

    link(scope, element, attrs, controller, transclude){

        element.bind('click', function(event) {
            event.stopPropagation();
        });

        angular.element(document).on('click', function(){
            scope.isVisible = false;
            scope.$apply(function () {
                scope.$eval(attrs.clickOutside);
            });
        });

        angular.element(document).on('keydown', function(e){
            if (e.which == 27) { //Esc
                scope.$apply(function () {
                    scope.$eval(attrs.clickOutside);
                });
            }
        });
    }

    static factory(): ng.IDirectiveFactory {
        return () => new ClickOutside()
    }
}

angular
    .module('app')
    .directive('clickOutside', ClickOutside.factory());
