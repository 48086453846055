class dngMin implements ng.IDirective {

    static $inject = [];

    restrict = 'A';
    require = 'ngModel';

    constructor() {}

    link(scope, elem, attr, ctrl) {
        scope.$watch(attr.dngMin, function () {
            ctrl.$setViewValue(ctrl.$viewValue);
        });
        var minValidator = function (value) {
            var min = scope.$eval(attr.dngMin) || 0;
            if (!Helpers.isEmpty(value) && value < min) {
                ctrl.$setValidity('dngMin', false);
                return undefined;
            } else {
                ctrl.$setValidity('dngMin', true);
                return value;
            }
        };

        ctrl.$parsers.push(minValidator);
        ctrl.$formatters.push(minValidator);
    }

    static factory(): ng.IDirectiveFactory {
        return () => new dngMin()
    }
}

angular
    .module('app')
    .directive('dngMin', dngMin.factory());
