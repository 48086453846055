class Pagination implements ng.IDirective {

    static $inject = [
        '$http'
    ];

    http: any;
    modalManager: any;
    settings: any = window['settings'];

    pages: Array<any>;
    last = true;
    next = true;
    total_pages: number;

    scope = {
        total: '=',
        perPage: '=',
        currentPage: '=',
        changePage: '&'
    };

    template = `
        <div class="pagination" ng-if="vm.total_pages > 1">
            <div
                ng-click="vm.incrementPage(currentPage - 1)"
                ng-class="{disabled: !vm.last}"
                class="page">
                Página Anterior
            </div>
            <div ng-repeat="page in vm.pages"
                 class="page number"
                 ng-click="vm.setPage(page)"
                 ng-class="{
                    disable: page.disable,
                    active: page.active,
                 }">
                {{page.number}}
            </div>
            <div
                ng-click="vm.incrementPage(currentPage + 1)"
                ng-class="{disabled: !vm.next}"
                class="page">
                Próxima Página
            </div>
        </div>
    `;
    controllerAs = 'vm';

    constructor($http: any) {
        this.http = $http;
    }

    controller($scope, $rootScope, $timeout, formsService) {
        let vm: any = this;

        vm.incrementPage = (number) => {
            $scope.currentPage = number;

            vm.total_pages = parseInt(String($scope.total / $scope.perPage + 0.9999), 10);

            if($scope.currentPage < 1){
                $scope.currentPage = 1;
            }

            if($scope.currentPage > vm.total_pages){
                $scope.currentPage = vm.total_pages;
            }

            console.log($scope.currentPage);

            $scope.changePage({page: $scope.currentPage });
            vm.configurePage();
        };

        vm.setPage = (page) => {
            $scope.currentPage = page.number;
            $scope.changePage({page: page.number });
            vm.configurePage();
        };

        vm.configurePage = () => {
            vm.total_pages = parseInt(String($scope.total / $scope.perPage + 0.9999), 10);

            if ($scope.total === $scope.perPage) {
                vm.total_pages = 1;
            }

            let start = $scope.currentPage - 3;
            let end = $scope.currentPage + 2;

            if ($scope.currentPage <= 4) {
                start = 0;
                end = 5;
            } else {
                if (vm.total_pages - $scope.currentPage <= 3) {
                    start = vm.total_pages - 5;
                    end = vm.total_pages;
                }
            }

            if (0 > start) {
                start = 0;
            }

            if (end > vm.total_pages) {
                end = vm.total_pages;
            }

            vm.pages = [];

            /**
             * Adiciona pagina inicial
             */
            if (start > 1) {
                vm.pages.push({
                    number: 1,
                });
                vm.pages.push({
                    disable: true,
                    number: '...',
                });
            }

            for (let i = start; i < end; i++) {
                vm.pages.push({
                    active: (i + 1 === $scope.currentPage),
                    number: i + 1,
                });
            }

            /**
             * Adiciona pagina final
             */
            if (vm.total_pages - end >= 2) {
                vm.pages.push({
                    disable: true,
                    number: '...',
                });

                vm.pages.push({
                    number: vm.total_pages,
                });
            }

            vm.last = ($scope.currentPage !== 1);
            vm.next = ($scope.currentPage !== vm.total_pages);
        };

        vm.ngOnChanges = ()  => {
            vm.configurePage();
        };

        vm.configurePage();
    }

    static factory() {
        return [
            '$http',
            ($http) => new Pagination($http)
        ];
    }
}

angular
    .module('app')
    .directive('pagination', Pagination.factory());
