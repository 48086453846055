class HomeController {

    public static $inject: string[] = [
        'homeData',
        'seoManager',
        '$rootScope',
    ];

    settings: any = window['settings'];

    data: any;
    neigborhoods: any;
    // Imóveis Destaque
    featuredProperties: any;

    constructor(homeData, seoManager, $rootScope) {
    	
        this.featuredProperties = homeData.data.body.featured_properties.results;

        seoManager.setTitle($rootScope.realestate.settings.SEO_TITLE || $rootScope.realestate.name);
        seoManager.setUrl('/', true);
        
        if($rootScope.realestate.id === 1) {
			seoManager.setImage('https://bancodosimoveisfiles.s3.amazonaws.com/shared/altopedroso.png');
		} else if($rootScope.realestate.id === -1) {
        	seoManager.setImage('https://bancodosimoveisfiles.s3.amazonaws.com/shared/bancodosimoveis.png');
		} else {
			seoManager.setImage($rootScope.realestate.website + '/themes/' + window['settings']['THEME'] + '/images/shared-image.jpg');	
		}
        

        // Bairros
        this.neigborhood();
    }

    /**
     * Bairros da página inicial
     */
    neigborhood() {
        this.neigborhoods = [
            {
                url: this.settings['URL'] + "/imoveis/sp/sao-paulo/tatuape",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-tatuape.jpg'
                ],
                title: "Imóveis no Tatuapé, <br> São Paulo - SP"
            },
            {
                url: this.settings['URL'] + "/imoveis-sao-miguel-paulista",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-sao-miguel-paulista.jpg'
                ],
                title: "Imóveis em São Miguel Paulista, São Paulo - SP"
            },

            {
                url: this.settings['URL'] + "/imoveis/sp/sao-paulo/ermelino-matarazzo",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-ermelino-matarazzo.jpg'
                ],
                title: "Imóveis em Ermelino Matarazzo, São Paulo - SP"
            },

            {
                url: this.settings['URL'] + "/imoveis/sp/sao-paulo/itaim-paulista",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-itaim-paulista.jpg'
                ],
                title: "Imóveis no Itaim Paulista, São Paulo - SP"
            },

            {
                url: this.settings['URL'] + "/imoveis-parque-cruzeiro-do-sul",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-parque-cruzeiro-do-sul.jpg'
                ],
                title: "Imóveis em São Miguel Paulista - Parque Cruzeiro, São Paulo - SP"
            },

            {
                url: this.settings['URL'] + "/imoveis-vila-curuca-velha",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-curuca-velha.jpg'
                ],
                title: "Imóveis em São Miguel Paulista - Curuçá Velha, São Paulo - SP"
            },

            {
                url: this.settings['URL'] + "/imoveis-penha",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-penha.jpg'
                ],
                title: "Imóveis na Penha,<br>São Paulo - SP"
            },

            {
                url: this.settings['URL'] + "/imoveis-vila-jacui",
                image: [
                    this.settings['URL_THEME'] + '/images/tumb-sp-vila-jacui.jpg'
                ],
                title: "Imóveis em São Miguel Paulista - Vila Jacui, São Paulo - SP"
            },
        ];
    }
}

angular
    .module('app')
    .controller('HomeController', HomeController);
