class SlideShow implements ng.IDirective {

    static $inject = ['$http'];

    http: any;
    settings: any = window['settings'];

    restrict: 'AE';
    scope = {};
    bindToController = {
        'noModalSuccess': '<?',
        'formPropertyId': '<',
        'actionSuccess': '&?',
        'formName': '@',
        'ngInit': '@?',
    };
    transclude = true;
    controllerAs = 'formsManager';

    constructor($http: any) {
        this.http = $http;
    }

    link(scope, element, attrs, controller, transclude){

        let boundObj = {};
        let alias = attrs.alias;

        let newScope = scope.$new(scope.controller); // isolate
        newScope[alias] = boundObj;

        transclude(newScope, function(clone){
            element.append(clone);
        });

        ///
        scope.log = function(){
            console.log(boundObj);
        };
    }

    controller($scope, $rootScope, $timeout, formsService) {

        // alert('asdsad');
        let vm: any = this;

        vm.processing = false;
        vm.form = {};
        vm.validate = true;
        vm.d = {};

        vm.addPhone = function() {
            vm.d.phones.push({});
        };

        vm.removePhone = function(index){
            vm.d.phones.splice(index, 1);
        };

        vm.toggleCheckbox = function(key, value) {
            if(!vm.d[key]){
                vm.d[key] = [];
            }

            if(vm.d[key].indexOf(value) == -1){
                vm.d[key].push(value);
            }else{
                vm.d[key].splice(vm.d[key].indexOf(value), 1);
            }
        };

        vm.submit = function () {

            if(vm.form.$invalid){
                vm.validate = true;
                return false;
            }

            vm.validate = false;

            // Não executar a acão diversas vezes
            if(vm.processing) return;
            vm.processing = true;

            $rootScope.loadingPage = true;

            return formsService
                .update(vm.formName, vm.d)
                .then((response) => {
                    console.log(response)
                });

            // return $restfull.post(plataform.url_api+"/realestate/forms/"+vm.formName, {
            //         params: {
            //             token: plataform.token
            //         },
            //         body: vm.d
            //     },
            //     function(response){
            //         $rootScope.loadingPage = false;
            //         vm.processing = false;
            //
            //         if(response.data.success){
            //             console.log(vm.noModalSuccess);
            //             if(!vm.noModalSuccess){
            //                 ModalManager.openSuccess();
            //             }
            //
            //             if(!!vm.actionSuccess){
            //                 $scope.$eval(vm.actionSuccess);
            //             }
            //
            //             vm.success = true;
            //         }else{
            //             ModalManager.openFailed();
            //         }
            //
            //     }, function(){
            //         vm.processing = false;
            //         $rootScope.loadingPage = false;
            //         ModalManager.openFailed();
            //     }
            // );
        };

        vm.$onInit = function () {

            vm.d.property_id = vm.formPropertyId;

            if(vm.ngInit){
                $timeout(function () {
                    $scope.$apply(function () {
                        $scope.$eval(vm.ngInit);
                    });
                }, 1);
            }
        };
    }

    static factory(): ng.IDirectiveFactory {
        return ($http) => new SlideShow($http)
    }
}

angular
    .module('app')
    .directive('slideShow', SlideShow.factory());
