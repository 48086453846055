class PropertiesSearchController {

    public static $inject: string[] = [
        'properties',
        'propertiesService',
        '$state',
        '$scope',
        '$rootScope',
        '$document',
        '$window',
        'seoManager',
    ];

    settings: any = window['settings'];

	openFilters = false;
    finality = {};
    filters: any = {};
    last_filters = {};
    loading = false;
    result_count = 0;
    maxSize = 10;
    numPages = 10;
    pageDisabled = false;
    mobile = false;
    error = false;
    correct_url = '';
    subtypes = [];
    types = [];
    updateForm: any;

    title_search = '';
    //
    currentPage = 1;
    limit = 20;
    sort = '';
    offset = 0;

    properties: any;
	description = '';
	description_seo = '';
	title_seo = '';

    $state: any;
    $scope: any;
    $window: any;
    seoManager: any;
    $rootScope: any;
    $document: any;
    propertiesService: any;


    //Events
    eventPopState: any;
    eventMobileCheck: any;
    
    sortPrice = '';

    constructor(
        properties,
        propertiesService,
        $state,
        $scope,
        $rootScope,
        $document,
        $window,
        seoManager) {

		if ($state.current.data.description) {
			this.description = $state.current.data.description;
			this.description_seo = $state.current.data.description;
		}

		if ($state.current.data.title) {
			this.title_seo = $state.current.data.title;
		}
		
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.$window = $window;
        this.seoManager = seoManager;
        this.propertiesService = propertiesService;

        this.properties = this.processProperties(properties.results);
        this.title_search = properties.title_search;
        this.result_count = properties.count;
        this.finality = $state.current.data.finality;
        this.$rootScope.localizacoes = properties.locations;

        this.getParams();

        seoManager.setTitle(this.title_search + ' | ' + $rootScope.realestate.name);
        seoManager.setUrl(this.generateURL());
        seoManager.setTitleH1(properties.title_search);

    }

    $onInit() {
        this.correct_url = this.generateURL();

        var that = this;

        this.eventPopState = function(){
            that.lock();
            that.$scope.$apply();
        };

        this.eventMobileCheck = function() {
            if($( window ).width() > 1000){
                that.mobile = false;
            }else{
                that.mobile = true;
            }

            console.log(that.mobile);

            that.$scope.$apply();
        };

        // Evento de modificação de URL
        window.addEventListener('popstate', this.eventPopState, true);
        window.addEventListener('resize', this.eventMobileCheck, true);

        /**
         * Se não for mobile, mover scroll
         */
        $('html,body').animate({
                scrollTop: $(".properties-search").offset().top},
            'slow');

        if($( window ).width() > 1000){
            that.mobile = false;
        }else{
            that.mobile = true;
        }

        //Localizações
        this.$scope.$watch(function() { return that.$rootScope.localizacoes.length }, (newvalue, oldvalue) => {

            if(newvalue != oldvalue){
                var location = [];

                for (var i in that.$rootScope.localizacoes) {

                    if(that.$rootScope.localizacoes[i].neighborhood) {
                        location.push(that.$rootScope.localizacoes[i].estate + ',' + that.$rootScope.localizacoes[i].city + ',' + that.$rootScope.localizacoes[i].neighborhood);
                    }

                    if(!that.$rootScope.localizacoes[i].neighborhood) {
                        location.push(that.$rootScope.localizacoes[i].estate + ',' + that.$rootScope.localizacoes[i].city);
                    }
                }

                that.filters.location = Helpers.convertStringUrl(location.join('_'));

                that.reset();
                that.loadProperties();
            }
        });

        this.$scope.$on("$destroy", () => {
            this.destroy();
        });
    }

    destroy() {
        var that = this;
        // Evento de modificação de URL
        window.removeEventListener('popstate', this.eventPopState, true);
        window.removeEventListener('resize', this.eventMobileCheck, true);
    }

    /**
     * Resgata parametros da URL
     */
    getParams() {
        if(window.location.pathname){
            this.filters = {};
            var explode = window.location.pathname.split("/");

            if(window.location.pathname === '/casas-em-sao-miguel-paulista') {
                explode = '/t-casa/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }

            if(window.location.pathname === '/apartamentos-em-sao-miguel-paulista') {
                explode = '/t-apartamento/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }

            if(window.location.pathname === '/comercios-em-sao-miguel-paulista') {
                explode = '/t-comercial/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }

            if(window.location.pathname === '/sobrados-em-sao-miguel-paulista') {
                explode = '/t-sobrado/l-sp,sao-paulo,sao-miguel-paulista/'.split("/");
            }

            // Reseta Ordem
            this.sort = '';

            // /venda
            if(explode[1] == 'venda'){
                this.finality = 'sale';
                this.filters["finality"] = 'sale';
            }else{
            	if(!this.finality || explode[1] == 'locacao') {
					this.finality = 'rent';
					this.filters["finality"] = 'rent';	
				}
            }
            
            for (var i in explode) {

                var data = explode[i];

                if(data.indexOf("l-") !== -1){
                    //LIMPA l- da variavel
                    var array = data.split("-");
                    array.splice(0,1);

                    var locations = array.join("-").split("_");

                    var location_correct = [];

                    for (var x in locations) {
                        location_correct.push(locations[x].replace(/-/g,' '));
                    }

                    this.filters["location"] = location_correct.join('_');
                    //Corrigir localizacoes


                }else if(data.indexOf("t-") !== -1){

                    var array = data.split("-");
                    array.splice(0,1);
                    var element = array.join("-").split(',');

                    this.filters["type_name"] = [];

                    for (var xx in element) {
                        this.filters["type_name"].push(element[xx]);

                        // AltoPedroso e BDI
                        this[element[xx].toLocaleLowerCase()] = true;
                        this.types.push(element[xx].toLocaleLowerCase());
                    }

                    this.filters["type_name"] = this.filters["type_name"].join(',');
                }else if(data.indexOf("sub-") !== -1){
                    this.filters["subtype"] = data.split("-")[1];

                    // AltoPedroso e BDI
                    this.subtypes = this.filters["subtype"].split(',').map(s => Number(s));
                    for (let xx in this.subtypes) {
                        this['subtype' + this.subtypes[xx]] = true;
                    }

                }else if(data.indexOf("dorms-") !== -1){
                    this.filters["bedrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }else if(data.indexOf("suites-") !== -1){
                    this.filters["suites"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }else if(data.indexOf("vagas-") !== -1){
                    this.filters["vacancies"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }else if(data.indexOf("banheiros-") !== -1){
                    this.filters["bathrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                }else if(data.indexOf("area-min-") !== -1){
                    this.filters["area_gte"] = parseInt(data.split("-")[2]);
                }else if(data.indexOf("area-max-") !== -1){
                    this.filters["area_lte"] = parseInt(data.split("-")[2]);
                }else if(data.indexOf("preco-max-") !== -1){
                    this.filters["price_lte"] = parseInt(data.split("-")[2]);
					// this.sort = "-price";
                }else if(data.indexOf("preco-min-") !== -1){
					this.filters["price_gte"] = parseInt(data.split("-")[2]);
					// this.sort = "price";
				}else if(data.indexOf("cond-valor") !== -1){
                    this.filters["cond_valor"] = true;
                }else if(data.indexOf("ordem-") !== -1){
                    var _sort = data.split("-")[1];
                    console.log(_sort);
                    if (_sort == 'price_min') {
                        this.sort = "price";
                    } else if (_sort == 'price_max') {
                        this.sort = "-price";
                    }
					console.log(this.sort);
                }else if(data.indexOf("page-") !== -1){
                    this.currentPage = parseInt(data.split("-")[1]);
                }
            }

            // if(this.filters["price_lte"]) {
            //     this.sort = "-price";
            // }else if(this.filters["price_gte"]) {
            //     this.sort = "price";
            // }
        }

		console.log(this.sort);
    }

    /**
     * Carrega mais imóveis
     * Mobile não deve fazer uma pesquisa a cada click
     */
    loadProperties() {
        if(!this.mobile) {
            this.lock();
            this.updateURL();
        }
    }

    /**
     * Carrega mais imóveis
     * Apenas quando apertar pesquisar
     */
    loadPropertiesMobile() {
    	this.openFilters = false;
        this.lock();
        this.updateURL();
    }

    /**
     * Gerar Url
     */
    generateURL() {
        this.filters['finality'] = this.finality;

        let correct_url = "";
        correct_url += (this.filters["finality"] == 'sale' ? 'venda' : 'locacao') + "/";
        correct_url += (this.filters["location"]) ? "l-" + this.filters["location"] + "/" : "";
        correct_url += (this.filters["type_name"]) ? "t-" + this.filters["type_name"] + "/" : "";
        correct_url += (this.filters["subtype"]) ? "sub-" + this.filters["subtype"] + "/" : "";
        correct_url += (this.filters["bedrooms"]) ? "dorms-" + this.filters["bedrooms"] + "/" : "";
        correct_url += (this.filters["suites"]) ? "suites-" + this.filters["suites"] + "/" : "";
        correct_url += (this.filters["vacancies"]) ? "vagas-" + this.filters["vacancies"] + "/" : "";
        correct_url += (this.filters["bathrooms"]) ? "banheiros-" + this.filters["bathrooms"] + "/" : "";
        correct_url += (this.filters["area_gte"]) ? "area-min-" + this.filters["area_gte"] + "/" : "";
        correct_url += (this.filters["area_lte"]) ? "area-max-" + this.filters["area_lte"] + "/" : "";
        correct_url += (this.filters["price_gte"]) ? "preco-min-" + this.filters["price_gte"] + "/" : "";
        correct_url += (this.filters["price_lte"]) ? "preco-max-" + this.filters["price_lte"] + "/" : "";
        correct_url += (this.filters["cond_valor"]) ? "cond-valor" : "";

        if (this.sort) {
            if (this.sort == 'price') {
                var _sort = 'price_min';
                correct_url += "ordem-" + _sort + "/";
            } else if (this.sort == '-price') {
                var _sort = 'price_max';
                correct_url += "ordem-" + _sort + "/";
            }
        }

        correct_url += (this.currentPage && this.currentPage !== 1) ? "page-" + this.currentPage + "/" : "";
        correct_url = this.settings.URL + '/' + correct_url;

        correct_url = correct_url.replace(/ /g, '-');
        if (correct_url.substring(correct_url.length - 1) == '/') {
            correct_url = correct_url.substring(0, correct_url.length - 1)
        }

        return correct_url;
    }

    /**
     * Atualiza URL
     */
    updateURL() {

		if(this.sortPrice !== '') {
			if(this.sortPrice == 'price_gte') {
				this.sort = 'price';
			}else if(this.sortPrice == 'price_lte') {
				this.sort = '-price';
			}
			this.sortPrice = '';
		}
		
        var correct_url = this.generateURL();

        if(this.correct_url !== correct_url){
            this.correct_url = correct_url;
            history.pushState(null, "", correct_url);
        }else{
            this.unlock();
        }
    }

    /**
     * Tratamento dos imóveis
     * @param properties
     * @returns {any}
     */
    processProperties(properties: any) {
        for (let i in properties) {
            properties[i].image = properties[i].images[0];
            properties[i].positionSlide = properties[i].videoYT ? -1 : 0;
        }

        return properties;
    }

    /**
     * Verifica se existe modificações nos formulários
     */
    updateProperties(input?: string) {
        if (this.last_filters['area_gte'] !== this.filters['area_gte'] ||
            this.last_filters['area_lte'] !== this.filters['area_lte'] ||
            this.last_filters['price_gte'] !== this.filters['price_gte'] ||
            this.last_filters['price_lte'] !== this.filters['price_lte']) {
            this.last_filters = angular.copy(this.filters);
            
            if(input === 'price_gte') {
				this.sortPrice = 'price_gte';
			}else if(input === 'price_lte') {
            	if(this.sortPrice !== 'price_gte') {
					this.sortPrice = 'price_lte';
				}
			}
            
            console.log(this.sortPrice);
            
            
            this.reset();
            this.loadProperties();
        }
    }

    updatePage(page){
        this.currentPage = page;
        this.loadPropertiesMobile();
    }

    /**
     * Reseta filtros para primeira página
     */
    reset() {
        this.limit = 20;
        this.offset = 0;
        this.currentPage = 1;
    }

    /**
     * Set Composition
     * @param comp
     * @param value
     */
    setComp(comp, value) {
        if (this.loading) return;

        if (this.filters[comp] == value) {
            this.filters[comp] = null;
        } else {
            this.filters[comp] = value;
        }

        this.reset();
        this.loadProperties();
    }

    addType(type) {

        type = type.name.toLowerCase();

        if(!this.types){
            this.types = [];
        }

        if(this.types.indexOf(type) == -1){
            this.types.push(type);
        }else{
            this.types.splice(this.types.indexOf(type), 1);
        }

        this.filters["type_name"] = this.types.join(",");

        this.loadProperties();
    }

    addSubType(subtype) {

        subtype = subtype.id;

        if(!this.subtypes){
            this.subtypes = [];
        }

        if(this.subtypes.indexOf(subtype) == -1){
            this.subtypes.push(subtype);
        }else{
            this.subtypes.splice(this.subtypes.indexOf(subtype), 1);
        }

        this.filters["subtype"] = this.subtypes.join(",");
        this.loadProperties();
    }

    /**
     * Trava Busca
     */
    lock() {
        // $('input').prop('disabled', true);
        // $('select').prop('disabled', true);
        this.pageDisabled = true;
        this.loading = true;
    }

    /**
     * Destrava Busca
     */
    unlock() {
        // $('input').prop('disabled', false);
        // $('select').prop('disabled', false);
        this.pageDisabled = false;
        this.loading = false;
    }

    nextPhoto(id, e) {
        console.log('nextPhoto');
        e.preventDefault(); // prevent button default behavior
        e.stopPropagation(); // prevent propagation of your event to the link

        let index: any = -1;
        for (var i in this.properties) {
            if(this.properties[i].id == id)
                index = i;
        }

        if(this.properties[index].loadingimage) return;

        this.properties[index].loadingimage = false;
        this.properties[index].positionSlide++;

        if(this.properties[index].images.length <= this.properties[index].positionSlide){
            // this.properties[index].loadingimage = true;
            this.properties[index].positionSlide = this.properties[index].images.length-1;
        }

        console.log(this.properties[index].images[this.properties[index].positionSlide].image);

        this.properties[index].image = this.properties[index].images[this.properties[index].positionSlide];
    }

    lastPhoto(id, e) {
        console.log('lastPhoto');
        // console.log('ss');
        e.preventDefault(); // prevent button default behavior
        e.stopPropagation(); // prevent propagation of your event to the link

        let index: any = -1;
        for (var i in this.properties) {
            if(this.properties[i].id == id)
                index = i;
        }

        if(this.properties[index].loadingimage) return;

        this.properties[index].loadingimage = false;

        this.properties[index].positionSlide--;

        if(this.properties[index].videoYT){
            if(this.properties[index].positionSlide < -1){
                // this.properties[index].loadingimage = true;
                this.properties[index].positionSlide = -1;
            }
        }else{
            if(this.properties[index].positionSlide < 0){
                // this.properties[index].loadingimage = true;
                this.properties[index].positionSlide = 0;
            }
        }


        this.properties[index].image = this.properties[index].images[this.properties[index].positionSlide];
    };
}

angular
    .module('app')
    .controller('PropertiesSearchController', PropertiesSearchController);
