class MaskCEP implements ng.IDirective {

    static $inject = [];

    restrict = 'A';
    require = 'ngModel';
    $timeout: any;
    scope = {
        ngModel: '='
    };

    constructor($timeout) {
        this.$timeout = $timeout;
    }

    link(scope, element, attrs, ctrl) {

        let that = this;


        element.bind('input', function() {

            let value = element.val();

            if(value.length > 8){
                value = value.replace(/\D/g,"").substr(0, 8);
            }

            let string = value.replace(/^(\d{5})(\d)/g,"$1-$2");

            element.val(string);
            console.log(string);

        });

        ctrl.$parsers.unshift(function(viewValue) {

            let value = viewValue.replace(/\D/g,'');

            if(value.length > 8){
                value = value.replace(/\D/g,"").substr(0, 8);
            }

            let string = value.replace(/^(\d{5})(\d)/g,"$1-$2");

            element.val(string);

            if(value.length != 0){
                ctrl.$setValidity("cep", false);
                if(value.length == 8){
                    ctrl.$setValidity("cep", true);
                    return value;
                }
                return undefined;
            }else{
                ctrl.$setValidity("cep", true);
                return undefined;
            }
        });



        // $(element).mask('00000-000');

    }

    static factory() {
        return [
            '$timeout',
            ($timeout) => new MaskCEP($timeout)
        ];
    }
}

angular
    .module('app')
    .directive('maskCep', MaskCEP.factory());
