class dngMax implements ng.IDirective {

    static $inject = [];

    restrict = 'A';
    require = 'ngModel';

    constructor() {}

    link(scope, elem, attr, ctrl) {
        scope.$watch(attr.dngMax, function () {
            ctrl.$setViewValue(ctrl.$viewValue);
        });
        var maxValidator = function (value) {
            var max = scope.$eval(attr.dngMax) || Infinity;
            if (!Helpers.isEmpty(value) && value > max) {
                ctrl.$setValidity('dngMax', false);
                return undefined;
            } else {
                ctrl.$setValidity('dngMax', true);
                return value;
            }
        };

        ctrl.$parsers.push(maxValidator);
        ctrl.$formatters.push(maxValidator);
    }

    static factory(): ng.IDirectiveFactory {
        return () => new dngMax()
    }
}

angular
    .module('app')
    .directive('dngMax', dngMax.factory());
