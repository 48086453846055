class Properties {
    id: number;
    cod: string;
    IPTU: number;
    area: number;
    area_total: number;
    area_useful: number;
    bathrooms: number;
    bedrooms: number;
    benefits: [number];
    breadcrumb: any;
    benefits_names: [{id: number, name: string, title: number}];
    city: string;
    composition: [any];
    details: any;
    description: string;
    estate: string;
    featured_home: boolean;
    featured_neighborhood_page: boolean;
    finality: [{id: number, value: number}];
    finality_id: number;
    financing: number;
    FGTS: number;
    credit_card: number;
    MCMV: number;
    exchange: number;
    image_count: number;
    image_countYT: number;
    images: [{image: string, url: string, rotate: number}];
    neighborhood: string;
    sale_price: number;
    sale_townhouse_price: number;
    rent_price: number;
    rent_townhouse_price: number;
    status: number;
    subtype: [number];
    suites: number;
    title: string;
    townhouse_price: number;
    type: number;
    type_name: string;
    url: string;
    vacancies: number;
    videoYT: string;
    zone: string;
}
