console.error = error => {
    if(error.indexOf('Transition Rejection') === -1){
        $.ajax({
            type: "POST",
            url: this.settings['API_LOGS'] + '/logs',
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify({
                level: 500,
                target: 'realestate-' + window['settings']['TOKEN'],
                message: 'Exception error partners',
                channel: 'partners-website-exception',
                context: {
                    location: window.location.href,
                    body: error
                }
            })
        });
    }

    console.warn(error);
};


angular
    .module('app')
    .factory('$exceptionHandler',
        function($log) {
            return function(exception, cause) {

                let body: any = {};
                body.exception = exception.toString();
                body.caused = cause;
                body.message = exception.message;
                body.fileName = exception.fileName;
                body.lineNumber = exception.lineNumber;
                body.stack = exception.stack;

                $.ajax({
                    type: "POST",
                    url: this.settings['API_LOGS'] + '/logs',
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify({
                        level: 400,
                        target: 'realestate-' + this.settings['TOKEN'],
                        message: 'Exception error partners',
                        channel: 'partners-website-exception',
                        context: {
                            location: window.location.href,
                            body: body
                        }
                    })
                });

                $log.warn(exception, cause);
            };
        });
