function ImageProperty() {
    return function (string: string, size = 'small') {
        if(size == 'small'){
            string = string.replace('normal', size);
        }else{
            string = string.replace('small', size);
        }

        return string;
    }
}

angular
    .module('app')
    .filter('ImageProperty', ImageProperty);
