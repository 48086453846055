class SearchAdvancedController {

    public static $inject: string[] = [
        '$state',
        '$scope',
        '$rootScope',
        '$document',
        'seoManager',
    ];

    settings: any = window['settings'];

    finality = {};
    filters: any = {};
    last_filters = {};
    loading = false;
    result_count = 0;
    maxSize = 10;
    numPages = 10;
    pageDisabled = false;
    mobile = false;
    error = false;
    correct_url = '';
    subtypes = [];
    types = [];

    $state: any;
    $scope: any;
    seoManager: any;
    $rootScope: any;
    $document: any;
    propertiesService: any;


    //Events
    eventPopState: any;

    constructor(
        $state,
        $scope,
        $rootScope,
        $document,
        seoManager) {

        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$document = $document;
        this.seoManager = seoManager;

        seoManager.setTitle('Busca Avançado | ' + $rootScope.realestate.name);
        seoManager.setTitleH1('Busca Avançado');
        seoManager.setUrl('/avancado', true);

        this.finality = 'sale';

        $rootScope.localizacoes = [];

        //Localizações
        $scope.$watch(() => { return $rootScope.localizacoes.length }, (newvalue, oldvalue) => {
            if(newvalue != oldvalue){
                let location = [];

                for (let i in $rootScope.localizacoes) {

                    if($rootScope.localizacoes[i].neighborhood) {
                        location.push($rootScope.localizacoes[i].estate + ',' + $rootScope.localizacoes[i].city + ',' + $rootScope.localizacoes[i].neighborhood);
                    }

                    if(!$rootScope.localizacoes[i].neighborhood) {
                        location.push($rootScope.localizacoes[i].estate + ',' + $rootScope.localizacoes[i].city);
                    }
                }

                this.filters.location = Helpers.convertStringUrl(location.join('_'));
            }
        });
    }

    addType(type) {

        type = type.name.toLowerCase();

        if(!this.types){
            this.types = [];
        }

        if(this.types.indexOf(type) == -1){
            this.types.push(type);
        }else{
            this.types.splice(this.types.indexOf(type), 1);
        }

        this.filters["type_name"] = this.types.join(",");
    }

    setComp(comp, value) {
        console.log(comp);
        if(this.filters[comp] == value){
            this.filters[comp] = null;
        }else{
            this.filters[comp] = value;
        }
    }

    createURL() {
        this.filters['finality'] = this.finality;

        let correct_url = "";
        correct_url += (this.finality == 'sale' ? 'venda' : 'locacao') + "/";
        correct_url += (this.filters["location"]) ? "l-" + this.filters["location"] + "/" : "";
        correct_url += (this.filters["type_name"]) ? "t-" + this.filters["type_name"] + "/" : "";
        correct_url += (this.filters["subtype"]) ? "sub-" + this.filters["subtype"] + "/" : "";
        correct_url += (this.filters["bedrooms"]) ? "dorms-" + this.filters["bedrooms"] + "/" : "";
        correct_url += (this.filters["suites"]) ? "suites-" + this.filters["suites"] + "/" : "";
        correct_url += (this.filters["vacancies"]) ? "vagas-" + this.filters["vacancies"] + "/" : "";
        correct_url += (this.filters["bathrooms"]) ? "banheiros-" + this.filters["bathrooms"] + "/" : "";
        correct_url += (this.filters["area_gte"]) ? "area-min-" + this.filters["area_gte"] + "/" : "";
        correct_url += (this.filters["area_lte"]) ? "area-max-" + this.filters["area_lte"] + "/" : "";
        correct_url += (this.filters["price_gte"]) ? "preco-min-" + this.filters["price_gte"] + "/" : "";
        correct_url += (this.filters["price_lte"]) ? "preco-max-" + this.filters["price_lte"] + "/" : "";

        correct_url = this.settings.URL + '/' + correct_url;

        correct_url = correct_url.replace(/ /g, '-');
        if (correct_url.substring(correct_url.length - 1) == '/') {
            correct_url = correct_url.substring(0, correct_url.length - 1)
        }

        history.pushState(null, "", correct_url);

    }

}

angular
    .module('app')
    .controller('SearchAdvancedController', SearchAdvancedController);
