class RealEstateNeighborhoodPageController {

    public static $inject: string[] = [
        'data',
        '$filter',
        '$rootScope',
        'seoManager',
        '$stateParams',
    ];

    settings: any = window['settings'];
    title: any;

    data: any;
	seoManager: any;
    $rootScope: any;
    $stateParams: any;
    $filter: any;

    location: any;
    properties: any;
    aggrs: any;
    url_sale: string;
    url_rent: string;
    pageCity: boolean;

	description = '';

	started = false;

	isCustom = false;

    constructor(data, $filter, $rootScope, seoManager, $stateParams) {
        // this.$rootScope = $rootScope;
		//
        // this.data = data;
        // this.location = data.location;
        // this.$stateParams = $stateParams;
        // this.$filter = $filter;
        // this.properties = data.properties.results;
        // this.aggrs = data.properties.aggrs;
        // this.pageCity = !this.location.neighborhood;
        // this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        // this.url_sale = $filter('convertUrl')(this.url_sale);
        // this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
        // this.url_rent = $filter('convertUrl')(this.url_rent);
		//
        // let description = '';
		//
        // if(this.pageCity){
        //     this.title = 'Imobiliária ' + this.location.city + ', Apartamentos, Casas e Sobrados.';
        //     this.$rootScope.title_page = 'Imobiliárias ' + this.location.city;
        //     description = "Imobiliária " + this.location.city + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.city + ".";
        // }else{
		// 	this.title = 'Imobiliária ' + this.location.neighborhood + ', Apartamentos, Casas e Sobrados.';
        //     this.$rootScope.title_page = 'Imobiliárias ' + this.location.neighborhood;
		// 	description = "Imobiliária " + this.location.neighborhood + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.neighborhood + ".";
        // }
		//
        // let url = $rootScope.URL + '/' + data.url;
		//
        // this.seoManager = seoManager;


		this.location = {
			city: "",
			estate: "",
			neighborhood: "",
			original_neighborhood: "",
			original_subneighborhood: "",
		};
		this.$rootScope = $rootScope;
		this.$stateParams = $stateParams;
		this.$filter = $filter;
		this.seoManager = seoManager;
    }

	myFunction() {

		let settings = document.querySelector('#realestate-location');
		console.log(settings.attributes['value']['value']);
		const data = JSON.parse(settings.attributes['value']['value']).body;
		
		this.data = data;
		this.location = data.location;
		this.properties = [];
		this.aggrs = [];
		this.pageCity = !this.location.neighborhood;
		this.url_sale = this.settings['URL'] + '/venda/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
		this.url_sale = this.$filter('convertUrl')(this.url_sale);
		this.url_rent = this.settings['URL'] + '/locacao/l-' + this.location.estate + ',' + this.location.city + (this.pageCity ? '' : ',' + this.location.neighborhood);
		this.url_rent = this.$filter('convertUrl')(this.url_rent);

		let description = '';

		if(this.pageCity){
			this.title = 'Imobiliária ' + this.location.city + ', Apartamentos, Casas e Sobrados.';
			this.$rootScope.title_page = 'Imobiliárias ' + this.location.city;
			description = "Imobiliária " + this.location.city + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.city + ".";
		}else{
			this.title = 'Imobiliária ' + this.location.neighborhood + ', Apartamentos, Casas e Sobrados.';
			this.$rootScope.title_page = 'Imobiliárias ' + this.location.neighborhood;
			description = "Imobiliária " + this.location.neighborhood + ", Os melhores Apartamentos, Casas, Sobrados e Imóveis Comerciais, página especializada " + this.location.neighborhood + ".";
		}

		if (this.location.neighborhood === 'Ermelino Matarazzo') {
			this.title = 'Imobiliária em Ermelino Matarazzo, Zona Leste, São Paulo - Alto Pedroso';
			description = 'Imobiliária em Ermelino Matarazzo | Alto Pedroso. Milhares de Imóveis, na Zona Leste de São Paulo. Casas, Sobrados, Apartamentos, Departamento Jurídico.';
			this.isCustom = true;
		}
		
		if (this.location.neighborhood === 'Vila Matilde') {
			this.title = 'Imobiliária na Vila Matilde - Guia de Imóveis na Vila Matilde, ZL - SP.';
			description = 'Imobiliária na Vila Matilde - Zona Leste - SP.  apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar? Alto Pedroso Imóveis.';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Penha') {
			this.title = 'Imobiliária na Penha, São Paulo - Zona Leste Alto Pedroso Imóveis ZL.·.';
			description = 'Imobiliária na Penha, São Paulo - Zona Leste Alto Pedroso Imóveis na ZL, Apartamentos, Casas, Sobrados, Imóveis Comerciais os Melhores Imóveis na Penha.';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Mooca') {
			this.title = 'Imobiliária na Mooca - Lista de Imóveis na Mooca Zona Leste - São Paulo';
			description = 'Imobiliária na Mooca - Lista de Imóveis na Mooca, Apartamentos, Casas, Sobrados Imóveis Comerciais. Compra, Venda e Locação, Anuncie seu Imóvel Conosco.';
			this.isCustom = true;
		}
		
		if (this.location.neighborhood === 'Vila Curuça') {
			this.title = 'Imobiliária na Vila Curuçá - Lista de Imóveis na Vila Curuçá - ZL - SP.';
			description = 'Imobiliária na Vila Curuçá - Guia de Imóveis na Vila Curuçá, apartamentos, casas, sobrados, Quer Comprar, Alugar, ou Anunciar Imóveis na Vila Curuçá.·.?';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Parque Paulistano' || this.location.neighborhood === 'Parque Paulistano ') {
			this.title = 'Imobiliária no Parque Paulistano, São Miguel Paulista ZL - Alto Pedroso';
			description = 'Procurando Imobiliária no Parque Paulistano, São Miguel Paulista ZL? Vem para a Alto Pedroso Imóveis, empresa melhor avaliada do bairro, sede própria.·.';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Jardim Helena') {
			this.title = 'Imobiliária no Jardim Helena Guia Imóveis no Jardim Helena Alto Pedroso';
			description = 'Imobiliária no Jardim Helena - Guia de Imóveis no Jardim Helena, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis ZL.';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Jardim Paulista') {
			this.title = 'Imobiliária no Jardim Paulista Guia Imóveis no Jardim Paulista.';
			description = 'Imobiliária no Jardim Paulista - Lista de Imóveis no Jardim Paulista, ofertas de imóveis de corretores e Imobiliárias no Jardim Paulista - SP';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Santa Cecilia') {
			this.title = 'Imobiliária na Santa Cecilia Guia Imóveis na Santa Cecilia.';
			description = 'Imobiliária na Santa Cecilia - Lista de Imóveis na Santa Cecilia, ofertas de imóveis de corretores e Imobiliárias na Santa Cecilia - SP';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Cidade Nova São Miguel') {
			this.title = 'Imobiliária na Cidade Nova São Miguel, Lista de Imóveis na Cidade Nova São Miguel.';
			description = 'Imobiliária na Cidade Nova São Miguel - Guia de Imóveis na Cidade Nova São Miguel, apartamentos, casas, sobrados e comércios. Quer Comprar, Alugar ou Anunciar imóveis?';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Guaianases') {
			this.title = 'Imobiliária em Guaianases - Lista de Imóveis em Guaianases Zona Leste.';
			description = 'Imobiliária em Guaianases - Listagem de Imóveis em Guaianases, apartamentos, casas, sobrados, imóveis comerciais, é na Alto Pedroso, entrada facilitada.';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Vila Rosária ') {
			this.title = 'Imobiliária na Vila Rosária Guia Imóveis na Vila Rosária.';
			description = 'Imobiliária na Vila Rosária - Lista de Imóveis na Vila Rosária, ofertas de imóveis de corretores e Imobiliárias na Vila Rosária - SP';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Tatuapé') {
			this.title = 'Imobiliária no Tatuapé Guia Imóveis no Tatuapé.';
			description = 'Imobiliária no Tatuapé - Lista de Imóveis no Tatuapé, ofertas de imóveis de corretores e Imobiliárias no Tatuapé - SP';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'Itaquera') {
			this.title = 'Imobiliária em Itaquera, na Zona Leste de, SP - Alto Pedroso Imóveis.·.';
			description = 'Imobiliária em Itaquera, na Zona Leste de, SP - Alto Pedroso Imóveis - Apartamentos, Casas, Sobrados e Comércio. Quer Comprar, Alugar ou Anunciar?.·.';
			this.isCustom = true;
		}

		if (this.location.neighborhood === 'São Miguel Paulista') {
			this.title = 'Imobiliária em São Miguel Paulista, São Paulo, Zona Leste, SP - Acesse:';
			description = 'Imobiliária em São Miguel Paulista, São Paulo, Zona Leste, SP - Apartamentos, Casas, Sobrados e Imóveis Comerciais. Empresa melhor avaliada do bairro.·.';
			this.isCustom = true;
		}
		
		this.description = description;

		let url = this.$rootScope.URL + '/' + data.url;

		this.seoManager.setTitle(this.title);
		this.seoManager.setUrl(url);
		this.seoManager.setDescription(description);
		
		this.started = true;
	}
	
    /**
     * Compartilhar por Email
     * @returns {any}
     */
    sharedEmail() {
        let location = this.location.estate + ',' + this.location.city + ',' + this.location.neighborhood;
        location = this.$filter('convertUrl')(location);

        this.$rootScope.target_email = location;
        this.$rootScope.type_email = 'shared';
        this.$rootScope.shareEmailModal = true;
    }
}

angular
    .module('app')
    .controller('RealEstateNeighborhoodPageController', RealEstateNeighborhoodPageController);
