class ShareEmailModalController {

    public static $inject: string[] = [
        '$rootScope',
        'formsService',
    ];

    settings: any = window['settings'];
    formsService: any;
    $rootScope: any;

    validate = true;
    success = false;
    failed = false;
    processing = false;
    d: any = {};

    constructor($rootScope, formsService) {
        this.formsService = formsService;
        this.$rootScope = $rootScope;
    }

    save() {

        let that = this;

        that.$rootScope.loadingPage = true;
        this.d.target = that.$rootScope.target_email;
        this.d.type = that.$rootScope.type_email;

        return this.formsService
            .update('shared_email', this.d)
            .then((response) => {
                that.$rootScope.loadingPage = false;
                that.processing = false;
                that.success = true;
            }, (err) => {
                that.processing = false;
                that.failed = true;
                that.$rootScope.loadingPage = false;
            });
    }
}

angular
    .module('app')
    .controller( 'ShareEmailModalController', ShareEmailModalController);
