(function(){
	angular.module("app")
		.controller('LGPDController', [
			'$scope',
			'$rootScope',
			'$cookies',
			function(
				$scope,
				$rootScope,
				$cookies
			){
				var self = this;
				self.view = true;
				console.log($cookies.get('LGDP'), $cookies.get('LGDP') == "1");
				
				if ($cookies.get('LGDP') == "1") {
					self.view = false;
				}

				var expiresValue = new Date();
				expiresValue.setFullYear(expiresValue.getFullYear() + 2);
				
				self.accept = function() {
					$cookies.putObject('LGDP', 1, {'expires' : expiresValue});
					self.view = false;
				}
				
			}]);
		})();