class Run {
    static $inject = [
        'ngMeta',
        '$rootScope',
        '$transitions',
        '$injector',
        '$location',
        'cfpLoadingBar',
        'seoManager',
    ];

    constructor(ngMeta, $rootScope, $transitions, $injector, $location, cfpLoadingBar, seoManager) {
        ngMeta.init();

        // Variaveis Globais
        $rootScope.THEME = window['settings'].THEME;
        $rootScope.URL_THEME = window['settings'].URL_THEME;
        $rootScope.URL = window['settings'].URL;
        $rootScope.API = window['settings'].API;
        $rootScope.API_LOGS = window['settings'].API_LOGS;
        $rootScope.TOKEN = window['settings'].TOKEN;
        $rootScope.GOOGLE_MAPS_KEY = window['settings'].GOOGLE_MAPS_KEY;
        $rootScope.realestate = window['settings'].realestate;
        $rootScope.info = window['settings'].info;
        $rootScope.googleMapsUrl = 'https://maps.googleapis.com/maps/api/js?key=' + $rootScope.GOOGLE_MAPS_KEY + '&libraries=places';

        /**
         * transitions
         */
        $transitions.onSuccess({}, function(transition) {
            $rootScope.route_name = transition.to().name;
            // theme_test
        });

        $transitions.onStart({}, function(transition) {
            // $rootScope.loadingPage = true;
            cfpLoadingBar.start();
        });

        $transitions.onFinish({}, function(transition) {
            $rootScope.text_search = '';
            $('html,body').scrollTop(0);
            cfpLoadingBar.complete();

            $rootScope.localizacoes = [];
            $rootScope.openMenuSide = false;
            $rootScope.loadingPage = false;
            $rootScope.openContact = false;
            $rootScope.openSimiliarPropertiesSuccess = false;
            $rootScope.shareEmailModal = false;
            $rootScope.openModalFailed = {
                open: false
            };
        });

        $transitions.onError({}, function(transition) {
            $rootScope.loadingPage = false;
            cfpLoadingBar.complete();
            let rejection = transition.error();

            if (rejection.type !== 5 && rejection.type !== 2) { // === 6
                let state = $injector.get('$state');
                state.go('404');
                return $location.path();
            }
        });
    }
}

angular.module("app")
    .run(Run);
