class LazyLoad implements ng.IDirective {

    static $inject = [];

    restrict = 'A';

    constructor() {
    }

    link(scope, element, attrs) {

        let observer = new IntersectionObserver(loadImg);
        let img = angular.element(element)[0];

        observer.observe(img);
        let that = this;
        function loadImg(changes) {
            changes.forEach(function (change) {
                if (change.intersectionRatio > 0) {
                    observer.unobserve(change.target);
                    change.target.src = attrs.lazyLoad;

                    change.target.classList.add('lazy-image--handled');
                    change.target.classList.remove('lazy-load');
                    change.target.parentElement.classList.add('lazy-image--handled');
                    change.target.parentElement.classList.remove('lazy-load');
                }
            });
        }

    }

    static factory(): ng.IDirectiveFactory {
        return () => new LazyLoad()
    }
}

angular
    .module('app')
    .directive('lazyLoad', LazyLoad.factory());
