class propertiesService extends CrudManager {
    path = '/realestate/properties';

    getParams(finality: string, path: string) {
        let filters: any = {};

        // /venda
        if (finality == 'venda') {
            filters["finality"] = 'sale';
        } else {
            filters["finality"] = 'rent';
        }

        console.log(path);
        if (path) {
            let explode = path.split("/");

            // Reseta Ordem
            filters.sort = '';

            for (let i in explode) {

                let data = explode[i];

                if (data.indexOf("l-") !== -1) {
                    //LIMPA l- da variavel
                    let array = data.split("-");
                    array.splice(0, 1);

                    let locations = array.join("-").split("_");

                    let location_correct = [];

                    for (let x in locations) {
                        location_correct.push(locations[x].replace(/-/g, ' '));
                    }

                    filters["location"] = location_correct.join('_');
                    //Corrigir localizacoes


                } else if (data.indexOf("t-") !== -1) {

                    let array = data.split("-");
                    array.splice(0, 1);
                    let element = array.join("-").split(',');

                    filters["type_name"] = [];

                    for (let xx in element) {
                        filters["type_name"].push(element[xx]);
                    }

                    filters["type_name"] = filters["type_name"].join(',');
                } else if (data.indexOf("sub-") !== -1) {
                    let subtypes = data.split("-")[1].split(',').map(s => Number(s));
                    filters["subtype"] = subtypes;
                } else if (data.indexOf("dorms-") !== -1) {
                    filters["bedrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                } else if (data.indexOf("suites-") !== -1) {
                    filters["suites"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                } else if (data.indexOf("vagas-") !== -1) {
                    filters["vacancies"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                } else if (data.indexOf("banheiros-") !== -1) {
                    filters["bathrooms"] = (parseInt(data.split("-")[1]) > 5) ? 5 : parseInt(data.split("-")[1]);
                } else if (data.indexOf("area-min-") !== -1) {
                    filters["area_gte"] = parseInt(data.split("-")[2]);
                } else if (data.indexOf("area-max-") !== -1) {
                    filters["area_lte"] = parseInt(data.split("-")[2]);
                } else if (data.indexOf("preco-min-") !== -1) {
                    filters["price_gte"] = parseInt(data.split("-")[2]) * 100;
                } else if (data.indexOf("preco-max-") !== -1) {
                    filters["price_lte"] = parseInt(data.split("-")[2]) * 100;
                } else if (data.indexOf("cond-valor") !== -1) {
                    filters["cond_valor"] = true;
                } else if (data.indexOf("ordem-") !== -1) {
                    let _sort = data.split("-")[1];
                    if (_sort == 'price_min') {
                        filters.sort = "price";
                    } else if (_sort == 'price_max') {
                        filters.sort = "-price";
                    }
                } else if (data.indexOf("page-") !== -1) {
                    filters.currentPage = parseInt(data.split("-")[1])-1;
                    filters.currentPage = filters.currentPage < 0 ? 0 : filters.currentPage;
                    filters.offset = filters.currentPage * 20;
                }
            }

            // if(filters["price_lte"]) {
            //     filters.sort = "-price";
            // }else if(filters["price_gte"]) {
            //     filters.sort = "price";
            // }
        }

        if (filters["finality"] == 'sale') {
            filters["cond_valor"] = false;
        }

        if (!!filters["cond_valor"]) {
            if (filters["price_lte"]) {
                filters["price_townhouse_lte"] = filters["price_lte"];
                filters["price_lte"] = undefined;
            }

            if (filters["price_gte"]) {
                filters["price_townhouse_gte"] = filters["price_gte"];
                filters["price_gte"] = undefined;
            }
        }
        
        console.log(filters);

        return filters;
    };

}

angular
    .module('app')
    .service('propertiesService', [...propertiesService.$inject, propertiesService]);
