class SeoManager {

    static $inject = [
        'ngMeta',
        '$rootScope',
    ];

    http: any;
    ngMeta: any;
    $rootScope: any;
    settings: any = window['settings'];

    constructor(ngMeta, $rootScope) {
        this.ngMeta = ngMeta;
        this.$rootScope = $rootScope;
    }

    setTitle(title) {
        this.ngMeta.setTitle(title);
    }

    setDescription(description) {
        this.ngMeta.setTag('description', description);
    }

    setImage(image) {
        this.ngMeta.setTag('image', image);
    }

    setKeywords(keywords) {
        this.ngMeta.setTag('keywords', keywords);
    }

    setUrl(url, addDomain?) {
        if(addDomain){
            this.ngMeta.setTag('url', ('https://' + window.location.hostname + url).replace('www.', ''));
        }else{
            this.ngMeta.setTag('url', url.replace('www.', ''));
        }
    }

	setUrl2(url) {
		this.$rootScope.url2 = 'url2';
	}

    setTitleH1(title) {
        this.$rootScope.breadcrumbs = [
            {
                url: this.$rootScope.URL + '/',
                name: 'Home',
            },
            {
                name: title
            },
        ];
    }
}

angular
    .module('app')
    .service('seoManager', [...SeoManager.$inject, SeoManager]);

